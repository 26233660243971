import React, { useState, useEffect } from "react";
import styles from "./BlockbotCard.module.scss";
import classNames from "classnames";
import StatCard from "../StatCard";
import axios from "axios";
import { API_BASE_URL } from "../../constant";

const BlockbotCard = ({
  item,
  index,
  selected,
  setSelected,
  selectedPowerups,
}) => {
  const [basePower, setBasePower] = useState(item.basePower);

  useEffect(() => {
    const blockbotTokenId = item.tokenId;
    const powerupIds = selectedPowerups.map((powerup) => powerup.tokenId);
    try {
      if (selectedPowerups) {
        axios
          .get(
            `${API_BASE_URL}/action-powers?tokenId=${blockbotTokenId}&powerups=[${powerupIds}]`
          )
          .then((response) => {
            if (response.data.basePowers && response.data.basePowers.length) {
              setBasePower(response.data.basePowers);
            }
          });
      }
      if (!selectedPowerups) {
        setBasePower(item.basePower);
      }
    } catch (error) {
      setBasePower(item.basePower);
    }
  }, [item.tokenId, item.basePower, selectedPowerups]);

  const clanAttribute = item?.attributes?.filter(
    (attribute) => attribute.trait_type === "Clan"
  )[0];
  return (
    <div
      className={classNames(styles.blockbotCardContainer)}
      onClick={() => setSelected(item)}
    >
      <div
        className={classNames(styles.blockbotCardInnerContainer)}
        style={
          selected
            ? { backgroundColor: "#311E86" }
            : { backgroundColor: "white" }
        }
      >
        <p
          className={classNames(styles.blockbotCardName)}
          style={selected ? { color: "white" } : { color: "black" }}
        >
          {item.name}
        </p>
        <div className={styles.blockbotCardImageContainer}>
          <img src={item.image} alt={item.title} />
        </div>
        {selected ? (
          <div className={styles.clanContainer}>
            <p className={styles.selectedClanLabel}>CLAN</p>
            <p className={styles.selectedClanValue}>{clanAttribute?.value}</p>
          </div>
        ) : (
          <div className={styles.clanContainer}>
            <p className={styles.clanLabel}>CLAN</p>
            <p className={styles.clanValue}>{clanAttribute?.value}</p>
          </div>
        )}
        <div>
          {basePower?.map((item, index) => {
            const label =
              item.powerType === "HEALTH_POINTS"
                ? "HEALTH POINTS"
                : item.powerType;
            return <StatCard label={label} value={item.value} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default BlockbotCard;
