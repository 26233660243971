import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import clx from "classnames";
import { useSelector } from "react-redux";
import { APP_LABELS } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { LEADERBOARD_URI } from "../../../constant";
import { BookerModal } from "./BookerModal";

export const PostMatchStatus = ({
  isOpen,
  winner,
  rounds,
  botOne,
  botTwo,
  account,
  participant1EthAddress,
  participant2EthAddress,
  gamesPlayed,
  matchType,
  playedWithBot,
  player1Name,
  player2Name,
}) => {
  const { isOwner } = useSelector((state) => state.wizard);
  const [bookerModalOpen, setBookerModalOpen] = useState(false);
  // console.log(gamesPlayed);
  useEffect(() => {
    if (!isOwner && gamesPlayed && gamesPlayed >= 25) setBookerModalOpen(true);
  }, [gamesPlayed, isOwner]);
  const renderWinner = useMemo(() => {
    if (account) {
      if (
        account.toLowerCase() === participant1EthAddress &&
        rounds[rounds.length - 1]?.winner === "Participant1"
      ) {
        return `You Win!`;
      }
      if (
        account.toLowerCase() === participant1EthAddress &&
        rounds[rounds.length - 1]?.winner === "Participant2"
      ) {
        return `You Lose!`;
      }
      if (
        account.toLowerCase() === participant2EthAddress &&
        rounds[rounds.length - 1]?.winner === "Participant1"
      ) {
        return `You Lose!`;
      }
      if (
        account.toLowerCase() === participant2EthAddress &&
        rounds[rounds.length - 1]?.winner === "Participant2"
      ) {
        return `You Win!`;
      }
    }
    if (winner) {
      return `${winner} Wins!`;
    }
    return "Draw";
  }, [account, participant1EthAddress, participant2EthAddress, rounds, winner]);

  const playedRounds = rounds.filter((elm) => elm.action1);
  const navigate = useNavigate();

  const onPlayAgainClick = () => {
    navigate("/");
  };
  const onCheckLeaderboard = () => {
    window.open(LEADERBOARD_URI, "_blank");
  };

  const onViewWallet = () => {
    navigate("/wallet");
  };
  const renderWinnings = (renderWinner) => {
    if (renderWinner === `You Win!` && !playedWithBot) {
      return (
        <div>
          <p className={clx("winnings-description")}>
            Your winnings will be transferred to your game wallet
          </p>
        </div>
      );
    }
  };

  return (
    <Modal className="post-match-modal" centered isOpen={isOpen}>
      <ModalHeader className="post-match-modal-header">
        <div className="modal-header-container">
          <div className="arena-img">
            <img src="/images/post-match-header.png" alt="" />
          </div>{" "}
          {renderWinner}
          <div className="dummy-bot-img">
            <img src="/images/post-match-header-bot.png" alt="" />
          </div>
        </div>
        {renderWinnings(renderWinner)}
      </ModalHeader>
      <ModalBody className="post-match-modal-body">
        <div className="post-match-stats-container">
          <div className="post-match-stats-title">Stats</div>
        </div>
        <div className="post-match-stats-table">
          <div className="post-match-table-header">
            <div className={clx("header-bot-name", "yellow-grad")}>
              <div>
                <p
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {player1Name}{" "}
                  {account && account.toLowerCase() === participant1EthAddress
                    ? " (you)"
                    : null}
                </p>
                <p
                  style={{
                    fontSize: "17px",
                    margin: 0,
                  }}
                >
                  {botOne.name}{" "}
                </p>
              </div>
            </div>
            <div className={clx("header-bot-name", "blue-grad")}>
              <div>
                <p
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {player2Name}{" "}
                  {account && account.toLowerCase() === participant2EthAddress
                    ? " (you)"
                    : null}
                </p>
                <p
                  style={{
                    fontSize: "17px",
                    margin: 0,
                  }}
                >
                  {botTwo.name}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="round-stats">
            {playedRounds.map((elm, idx) => {
              const { health1, health2, action1, action2, round } = elm;
              return (
                <div key={idx} className="round-stats-row">
                  <div className="stats-value">
                    <div className="health-value">{parseInt(health1)}</div>{" "}
                    <div>{APP_LABELS[action1]}</div>
                  </div>
                  <div className="rounds-value">Round {round}</div>
                  <div className={clx("stats-value", "reverse-flex")}>
                    <div className={clx("health-value", "text-right")}>
                      {parseInt(health2)}
                    </div>{" "}
                    <div>{APP_LABELS[action2]}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <BookerModal
          isOpen={bookerModalOpen}
          onClose={() => {
            setBookerModalOpen(false);
          }}
        />
      </ModalBody>
      <ModalFooter>
        {account && (
          <Button
            onClick={onPlayAgainClick}
            className={clx("primary-blue-btn", "transparent-btn", "white-brd")}
          >
            Play again
          </Button>
        )}
        <Button
          onClick={onCheckLeaderboard}
          className={clx("primary-blue-btn", "light-blue-btn")}
        >
          Check leaderboard
        </Button>
        {account && (
          <Button
            onClick={onViewWallet}
            className={clx("primary-blue-btn", "light-blue-btn")}
          >
            View wallet
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
