import { Web3Provider } from "@ethersproject/providers";

///import Web3 from "web3";
// import { NetworkConnector } from "@web3-react/network-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export function getLibrary(provider) {
  provider.on("accountsChanged", () => {
    if (typeof window !== undefined) {
      window.localStorage.removeItem("token");
    }
  });
  return new Web3Provider(provider);
}
