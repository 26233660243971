export const BasicLoader = ({ style, loaderText }) => {
  return (
    <div style={style} className={"ellipsis-loader"}>
      {loaderText}
      <span className={"dotOne"}>.</span>
      <span className={"dotTwo"}>.</span>
      <span className={"dotThree"}>.</span>
    </div>
  );
};
