import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import SelectBlockbot from "./SelectBlockbot";
import SelectPowerups from "./SelectPowerups";
import SelectActions from "./SelectActions";
import WithWeb3 from "../../HOC/withWeb3";

import {
  fetchBlockbotDetails,
  fetchPowerupDetails,
  fetchActionDetails,
} from "../../actions";
import { selectBlockbot } from "../../actions";

const FightWizard = (props) => {
  const dispatch = useDispatch();
  const { loading, account } = props;
  const params = new URLSearchParams(window.location.search);
  const fightType = Object.fromEntries(params.entries()).fightType;
  const wizard = useSelector((state) => state.wizard);
  const { currentState, blockbots } = wizard;
  const [blockbotDetailsCurrentPage, setBlockbotDetailsCurrentPage] =
    useState(1);
  const [totalNumberOfBlockbots, setTotalNumberOfBlockbots] = useState(null);

  const onPageChange = ({ selected }) => {
    setBlockbotDetailsCurrentPage(selected + 1);
  };

  useEffect(() => {
    if (!account) return;
    batch(async () => {
      dispatch(selectBlockbot({}));
      dispatch(
        fetchBlockbotDetails({
          address: account,
          pagePosition: blockbotDetailsCurrentPage,
        })
      );
      dispatch(
        fetchPowerupDetails({
          address: account,
          fightType,
        })
      );
      dispatch(fetchActionDetails());
    });
  }, [account, blockbotDetailsCurrentPage, dispatch, fightType]);

  useEffect(() => {
    if (blockbots.total > 0) setTotalNumberOfBlockbots(blockbots.total);
  }, [blockbots.total]);

  const selectedComps = {
    SELECT_BLOCKBOT: (
      <SelectBlockbot
        blockbots={wizard.blockbots}
        triedConnect={loading}
        account={account}
        blockbotDetailsCurrentPage={blockbotDetailsCurrentPage}
        setBlockbotDetailsCurrentPage={onPageChange}
        totalNumberOfBlockbots={totalNumberOfBlockbots}
      />
    ),
    SELECT_POWERUPS: <SelectPowerups account={account} />,
    SELECT_ACTIONS: <SelectActions />,
  };
  return (
    <div className="wizard-content-container">
      {selectedComps[currentState]}
    </div>
  );
};

export default WithWeb3(FightWizard);
