import React, { useEffect, useState, useMemo, useCallback } from "react";
import Blockbot from "./index";
import { useSpring } from "@react-spring/three";
import { degToRad } from "three/src/math/MathUtils";
import { Sphere } from "@react-three/drei";
// import { useThree } from "@react-three/fiber";
import {
  AttackSpriteAnimation,
  FastAttackSpriteAnimation,
} from "../ActionAnimations";

//isOnRight is the param to identify if the bot is to be placed on left or right

export const AnimatedBot = (props) => {
  const {
    botAction,
    botNextAction,
    isOnRight = false,
    blockbot,
    setLoaded,
    botLoaderKey,
  } = props;
  const [isAttacking, setAttackingState] = useState(false);
  const [isFastAttacking, setFastAtkState] = useState(false);
  const [isBoosted, setBoosted] = useState(false);

  const DEFAULT_POSITION = useMemo(
    () => (isOnRight ? [4, 2, 0] : [-4, 2, 0]),
    [isOnRight]
  );

  const SIDE_STEP_POSITION = useMemo(
    () => (isOnRight ? [2, 2, -2] : [-3, 2, 2]),
    [isOnRight]
  );
  //FIXED ROTATIONS
  const DEFAULT_ROTATION = useMemo(
    () =>
      isOnRight
        ? [degToRad(0), degToRad(30), degToRad(0)]
        : [degToRad(0), degToRad(160), degToRad(0)],
    [isOnRight]
  );

  //FIXED SCALES
  const DEFAULT_SCALE = 1;
  const TWOXSCALE = 2;

  const INTERVAL = 1500;

  const handleAsyncTo = useCallback(
    async (next, cancel) => {
      if (botAction === "BOOST") {
        await next({ scale: TWOXSCALE });
        // await next({ scale: DEFAULT_SCALE });
        // await next({ scale: TWOXSCALE });
        // await next({ scale: DEFAULT_SCALE });
      }
      // SELECTED BOT EVADES, SIDE STEPS THE OPPONENT BOT HORIZONTALLY
      if (botAction === "EVADE") {
        await next({ position: SIDE_STEP_POSITION });
        await next({ position: DEFAULT_POSITION });
      }

      await next({
        position: DEFAULT_POSITION,
        scale: DEFAULT_SCALE,
        rotation: DEFAULT_ROTATION,
      });
    },
    [DEFAULT_POSITION, DEFAULT_ROTATION, SIDE_STEP_POSITION, botAction]
  );

  const { position, scale, rotation } = useSpring({
    to: handleAsyncTo,
    from: {
      position: DEFAULT_POSITION,
      rotation: DEFAULT_ROTATION,
      scale: DEFAULT_SCALE,
    },
    config: { duration: INTERVAL },
  });

  // const [api] = useSpring();
  // console.log(api);

  useEffect(() => {
    if (botAction === "ATTACK") setAttackingState(true);
    if (botAction === "FAST_ATTACK") setFastAtkState(true);
    if (
      botAction === "BOOST" &&
      botNextAction &&
      (botNextAction === "ATTACK" || botNextAction === "FAST_ATTACK")
    ) {
      setBoosted(true);
    }
  }, [botAction, botNextAction]);

  const onAttackComplete = () => {
    setAttackingState(false);
    setBoosted(false);
  };

  const onFastATKComplete = () => {
    setFastAtkState(false);
    setBoosted(false);
  };

  const memoizedBB = useMemo(
    () => (
      <Blockbot
        blockbot={blockbot}
        rotation={rotation}
        scale={scale}
        position={position}
        setLoaded={setLoaded}
        botLoaderKey={botLoaderKey}
      />
    ),
    [blockbot, botLoaderKey, position, rotation, scale, setLoaded]
  );

  const memoizedAttackSprite = useMemo(
    () => (
      <AttackSpriteAnimation
        isBoosted={isBoosted}
        onAttackComplete={onAttackComplete}
        isRTL={isOnRight}
        isActive={isAttacking}
      />
    ),
    [isAttacking, isBoosted, isOnRight]
  );

  const memoizedFstAttackSprite = useMemo(
    () => (
      <FastAttackSpriteAnimation
        isBoosted={isBoosted}
        onAttackComplete={onFastATKComplete}
        isRTL={isOnRight}
        isActive={isFastAttacking}
      />
    ),
    [isBoosted, isFastAttacking, isOnRight]
  );

  return (
    <instancedMesh>
      {/* <directionalLight color="red" intensity={1} /> */}
      {memoizedBB}
      {memoizedAttackSprite}
      {memoizedFstAttackSprite}
      {botAction === "BLOCK" && (
        <Sphere position={isOnRight ? [4, 3.7, 0] : [-4, 3.7, 0]} scale={2}>
          <meshStandardMaterial
            attach="material"
            color="white"
            transparent
            opacity={0.6}
            roughness={1}
            metalness={0}
            castShadow
          />
        </Sphere>
      )}
    </instancedMesh>
  );
};
