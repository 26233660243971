import axios, { Axios } from "axios";
import { API_BASE_URL, BLOCKBOTS_METADATA_BASEURL } from "../../constant";
import { getToken } from "../../auth";

function apiProvider() {
  const api = new Axios({
    baseURL: API_BASE_URL,
    transformRequest: [...axios.defaults.transformRequest],
  });

  return {
    api,
    getMetaDataOfBlockBot: async (id) => {
      const { data } = await api.get(`/${id}`, {
        baseURL: BLOCKBOTS_METADATA_BASEURL,
      });
      return JSON.parse(data);
    },
    getPowerUpsDetails: async ({ account, includeSpendingWallet = false }) => {
      const { data } = await api.get(
        `powerups/${account}?incSpending=${includeSpendingWallet}`
      );
      return JSON.parse(data);
    },
    getBlockBotArray: async (ids) => {
      const { data } = await api.get(`/blockbots/tokenIds`, {
        params: {
          ids,
        },
      });
      return JSON.parse(data);
    },
    getBlockBots: async (id) => {
      const { data } = await api.get(`/blockbot/${id}`);
      return JSON.parse(data);
    },
    getActions: async () => {
      const { data } = await api.get(`/get-actions`);
      return JSON.parse(data);
    },
    getPowerModifiers: async (id) => {
      const { data } = await api.get(`/powerup-modifiers`, {
        params: {
          tokenIds: id,
        },
      });
      return JSON.parse(data);
    },
    getBasePowerOfBB: async (id) => {
      const { data } = await api.get(`/base-powerups/${id}`);

      return JSON.parse(data);
    },
    getGameDetails: async (gameID) => {
      const { data } = await api.get(`/game-details/${gameID}`);
      return JSON.parse(data);
    },

    updateLoadoutFrequency: async (actionId, accountAddress) => {
      const token = await getToken();
      const { data } = await api.post(
        "/loadout-actions/frequency",
        {
          actionId,
          playerEthAddress: accountAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return JSON.parse(data);
    },

    updatePUFrequency: async (puId, accountAddress) => {
      const token = await getToken();
      const { data } = await api.post(
        "/loadout-powerups/frequency",
        {
          puLoadoutId: puId,
          playerEthAddress: accountAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return JSON.parse(data);
    },

    getBlockbotDetails: async (tokenID) => {
      const { data } = await api.get(`/blockbot/${tokenID}`);

      return JSON.parse(data);
    },

    getOldSpenderWalletBalance: async (accountAddress) => {
      const { data } = await api.get(
        `/previous/spending/balance/${accountAddress}`
      );
      return JSON.parse(data);
    },

    getFreeBlockBots: async () => {
      const { data } = await api.get("/bookers/free-blockbots");
      return JSON.parse(data);
    },
    getFreePowerUps: async () => {
      const { data } = await api.get("/bookers/free-powerups");
      return JSON.parse(data);
    },
    fetchProfileDetails: async (ethAddress) => {
      const token = await getToken();
      const { data } = await api.get(`/user/profile/${ethAddress}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return JSON.parse(data);
    },
    setProfileDetails: async (profileObject) => {
      const token = await getToken();
      const { data } = await api.post(`/user/profile`, profileObject, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return JSON.parse(data);
    },
    getPlayerStats: async (ethAddress) => {
      const { data } = await api.get(`/participant-stats/${ethAddress}`);
      return JSON.parse(data);
      // console.log(data);
    },
    getSpecialBot: async () => {
      const { data } = await api.get("/free-melon");
      return JSON.parse(data);
    },
    savePowerupsLoadout: async (loadoutFields) => {
      const token = await getToken();
      return await api.post(`/loadout-powerups`, loadoutFields, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(data);
    },
    getPowerupsLoadout: async (ethAddress) => {
      const { data } = await api.get(`/loadout-powerups/${ethAddress}`);
      return JSON.parse(data);
    },
    getActionsLoadout: async (ethAddress) => {
      const { data } = await api.get(`/loadout-actions/${ethAddress}`);
      return JSON.parse(data);
    },
    saveActionsLoadout: async (loadoutFields) => {
      const token = await getToken();
      return await api.post(`/loadout-actions`, loadoutFields, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    verifyRecaptcha: async (token) => {
      try {
        const response = await api.post("/captcha/verify", {
          token: token,
        });
        const res = JSON.parse(response.data);
        return res.google_response;
      } catch (err) {
        console.log(err);
      }
    },
    getBlockBotsByAddress: async (address, pagePosition) => {
      const { data } = await api.get(
        `/blockbots/${address}?pageNo=${pagePosition}&pageSize=10`
      );
      return JSON.parse(data);
    },
    getSpendingBalance: async (ethAddress) => {
      const { data } = await api.get(`/spending/balance/${ethAddress}`);
      return JSON.parse(data);
    },

    getMessageSignatureHash: async (ethAddress) => {
      const token = await getToken();
      const { data, status } = await api.get(
        `/spending/withdraw/message/${ethAddress}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { data: JSON.parse(data), status };
    },
    getSpenderWalletPowerups: async (account) => {
      const token = await getToken();
      const { data } = await api.get(`/spending/powerups/${account}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      return JSON.parse(data);
    },
    deletePowerupLoadout: async (id) => {
      const token = await getToken();
      return await api.post(`/loadout-powerups/delete/${id}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    deleteActionLoadout: async (id) => {
      const token = await getToken();
      return await api.post(`/loadout-actions/delete/${id}`, null, {
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    getParticipantId: async (body) => {
      const token = await getToken();
      const { data, status } = await api.post(
        `${API_BASE_URL}/add-participant`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      return { data: JSON.parse(data), status };
    },

    getInvite: async (body) => {
      const token = await getToken();
      const { data, status } = await api.post(`${API_BASE_URL}/invite`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return { data: JSON.parse(data), status };
    },

    validateInvite: async (inviteId) => {
      const token = await getToken();
      const { data, status } = await api.get(
        `${API_BASE_URL}/invite/${inviteId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return { data: JSON.parse(data), status };
    },

    acceptInvite: async (body) => {
      const token = await getToken();
      const { data, status } = await api.post(
        `${API_BASE_URL}/acceptInvite`,
        body,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return { data: JSON.parse(data), status };
    },

    checkInviteStatus: async (address) => {
      const token = await getToken();
      const { data, status } = await api.get(
        `${API_BASE_URL}/checkActiveInvite/${address}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return { data: JSON.parse(data), status };
    },

    updateFrequencyLoadout: async (puId, address) => {
      const token = await getToken();
      const { data, status } = await api.post(
        `${API_BASE_URL}/loadout-powerups/frequency`,
        {
          puLoadoutId: puId,
          playerEthAddress: address,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return { data: JSON.parse(data), status };
    },

    cancelInvite: async (inviteId) => {
      const token = await getToken();
      const { data, status } = await api.post(
        `${API_BASE_URL}/cancelInvite`,
        { inviteId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return { data: JSON.parse(data), status };
    },
  };
}

export default apiProvider();
