import { useEffect } from "react";
import classnames from "classnames";
import { NavLink as Link } from "reactstrap";
import styles from "./header.module.scss";
import { useMediaQuery } from "react-responsive";
import { useWeb3React } from "@web3-react/core";
import { injected } from "../../connectors";
import { truncateString, getErrorMessage } from "../../utils";
import { fetchProfileDetails } from "../../actions";
import { profileSelector } from "../../reducers/profileReducer";
import { APP_LINKS } from "../../constant";
import { HamburgerMenu } from "./HamburgerMenu";
import { Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 650px)" });
  const dispatch = useDispatch();
  const { active, account, activate, error } = useWeb3React();

  const connectWallet = async () => {
    try {
      await activate(injected);
    } catch (err) {
      console.log(err);
    }
  };

  if (error) {
    getErrorMessage(error);
  }
  const token = window.localStorage.getItem("token");
  useEffect(() => {
    const getProfileDetails = async () => {
      if (token && account) {
        try {
          dispatch(fetchProfileDetails(account));
        } catch (error) {
          console.log(error);
        }
      }
    };
    getProfileDetails();
  }, [account, dispatch, token]);

  useEffect(() => {
    activate(injected);
  }, []);

  const { user } = useSelector(profileSelector);
  const buttonText =
    (active && account && truncateString(account)) || "Connect Wallet";
  return (
    <div className={classnames(styles.header)}>
      {isMobile ? (
        <HamburgerMenu />
      ) : (
        <div className={styles.headerContainer}>
          <div className="d-flex justify-content-between align-items-center">
            <div className={styles.navLink} onClick={() => navigate("/")}>
              <div className={styles.logoContainer}>
                <div className={styles.logo}></div>
                <div>
                  <p className={styles.logoTitle}>BlockBouts</p>
                  <p className={styles.logoSubTitle}>The Game</p>
                </div>
              </div>
            </div>
            <div className={styles.navContainer}>
              {APP_LINKS.map((elm, idx) => {
                return (
                  <div key={idx} className={styles.navItem}>
                    {elm.external_link ? (
                      <a href={elm.link} target="_blank" rel="noreferrer">
                        {elm.display}
                      </a>
                    ) : (
                      <div
                        onClick={() => navigate(elm.link)}
                        className={styles.navInternalLink}
                      >
                        {elm.display}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {user?.playerEthAddress ? (
              <div
                className={styles.navLink}
                onClick={() => navigate("/profile")}
              >
                <div className={styles.headerUserProfileContainer}>
                  {user?.profilePic?.thumbnailPath ? (
                    <div className={styles.headerUserAvatarImageContainer}>
                      <img
                        className={styles.headerUserAvatarImage}
                        src={user?.profilePic?.thumbnailPath}
                        alt=""
                      />
                    </div>
                  ) : (
                    <div>
                      <div
                        className={classnames(styles.defaultAvatarContainer)}
                      >
                        <img
                          src={"../images/BB_Head.png"}
                          className={styles.defaultAvatarImage}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                  <div className={styles.headerUserTextContainer}>
                    <p className={styles.headerUsername}>{user?.userName}</p>
                    <p className={styles.headerEthAddress}>
                      {truncateString(user?.playerEthAddress)}
                    </p>
                  </div>
                </div>
              </div>
            ) : account && active ? (
              <div
                className={styles.navLink}
                onClick={() => navigate("/profile")}
              >
                <Button className={styles.connectButton}>{buttonText}</Button>
              </div>
            ) : (
              <Button
                disabled={account && active}
                onClick={connectWallet}
                className={styles.connectButton}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
