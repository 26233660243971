import React from "react";
import styles from "./powerupDetailCard.module.scss";
import { ButtonPurplePrimary as Button } from "../ButtonPurplePrimary";
import classnames from "classnames";
import { INC_DEC_PREFIX, INC_DEC_TO_MAP } from "../../constant";
import { PwrUpStatCard } from "../StatCard";
import { UncontrolledTooltip } from "reactstrap";

export const PowerupDetailCard = (props) => {
  const {
    name,
    tokenId,
    normalEffect = [],
    specialEffectFor = [],
    specialEffectAgainst = [],
    image,
    onClick,
    isRemove,
    slot,
    isSelected,
    onRemove,
    maxSelected,
    disabled,
    powerupType,
    maxRule,
    backgroundColor,
  } = props;
  const getAttributes = () => {
    let tempAttributes = [];
    if (normalEffect.length) {
      tempAttributes = [...tempAttributes, ...normalEffect];
    }
    if (specialEffectFor.length) {
      let tempSpecialEffectFor = specialEffectFor.map((item) => ({
        ...item,
        specialEffect: "for",
      }));

      tempAttributes = [...tempAttributes, ...tempSpecialEffectFor];
    }
    if (specialEffectAgainst.length) {
      let tempSpecialEffectAgainst = specialEffectAgainst.map((item) => ({
        ...item,
        specialEffect: "against",
      }));
      tempAttributes = [...tempAttributes, ...tempSpecialEffectAgainst];
    }
    return tempAttributes;
  };

  return !!tokenId ? (
    <div
      className={classnames(
        !isRemove ? styles.selectPowerupDetail : styles.removePowerupDetail,
        isSelected && styles.selectedBorder,
        !isSelected && maxSelected && styles.disabledCard,
        !isSelected && disabled && styles.hardDisable
      )}
      id={`token-id-${tokenId}`}
    >
      {!isSelected && disabled && (
        <UncontrolledTooltip target={`token-id-${tokenId}`}>
          {`You can equip only ${maxRule} of ${powerupType} type`}
        </UncontrolledTooltip>
      )}
      <div className={classnames(styles.powerupCardTitle)}>{name}</div>
      <div className={classnames(styles.powerupImageContainer)}>
        <img
          className={classnames(styles.imgPowerupDetailCard, "pt-0", "m-auto")}
          style={{ backgroundColor }}
          width="100%"
          src={image}
          alt=""
        />
        {powerupType && (
          <div className={classnames(styles.powerupTypeTextContainer)}>
            <p className={styles.powerupTypeText}>{powerupType}</p>
          </div>
        )}
      </div>
      <div>
        <div className={styles.statsContainer}>
          {getAttributes().map((item, idx) => {
            return (
              <PwrUpStatCard
                key={idx}
                isPowerupStatCard
                label={item.effectTo.replace(/_/g, " ")}
                specialEffectClan={item.clan}
                specialEffect={item.specialEffect}
                value={`${INC_DEC_PREFIX[item.operation]}${item.points}${
                  INC_DEC_TO_MAP[item.operation] === "%"
                    ? INC_DEC_TO_MAP[item.operation]
                    : ""
                }`}
              />
            );
          })}
        </div>
        <Button
          className={classnames(
            !isSelected && maxSelected && styles.disabledButton,
            !isSelected && disabled && styles.disabledButton,
            "primary-button"
          )}
          onClick={isSelected ? onRemove : onClick}
        >
          {isSelected ? "Remove" : "Equip"}
        </Button>
      </div>
    </div>
  ) : (
    <div className={styles.powerupBlankCard}>
      <div className="font-weight-bold p-2">Slot {slot}</div>
    </div>
  );
};
