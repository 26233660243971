import { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import clx from "classnames";

export const LoadoutModal = ({
  isOpen,
  onClose,
  onSave,
  selectedLoadout,
  modalTitle,
  errorMessage,
}) => {
  const { name } = selectedLoadout || {};
  const [loadoutName, setLoadoutName] = useState(null);
  const onSaveClick = () => {
    onSave(loadoutName);
  };

  useEffect(() => {
    if (name) setLoadoutName(name);
  }, [name]);
  return (
    <Modal className="loadout-modal" centered isOpen={isOpen}>
      <ModalHeader>{modalTitle}</ModalHeader>
      <ModalBody>
        <div className="loadout-modal-input-container">
          <div className="input-label">Name</div>
          <Input
            defaultValue={loadoutName}
            onChange={(e) => setLoadoutName(e.target.value)}
            placeholder="Loadout Name"
          />
        </div>
        <p className="text-danger text-center">{errorMessage}</p>
      </ModalBody>
      <ModalFooter className="load-modal-footer">
        <Button
          onClick={onClose}
          className={clx("primary-blue-btn", "transparent-btn")}
        >
          Cancel
        </Button>
        <Button onClick={onSaveClick} className="primary-blue-btn">
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
