import SwordsIcon from "../images/icons/swords.svg";
import ShieldIcon from "../images/icons/shield.svg";
import ChessPieceIcon from "../images/icons/chesspiece.svg";

export const FIGHT_TYPES = {
  ARENA: "ARENA",
  BLOCKBOUT: "BLOCKBOUT",
  EXHIBITION: "EXHIBITION",
};
export const BLOCK_BOUT_MATCH_TYPE = [
  {
    title: "Arena",
    bullets: [{ title: "Fight to win NFT in-game items", img: SwordsIcon }],
    image: "/images/bb-ring.png",
    type: FIGHT_TYPES.ARENA,
    isLive: true,
  },
  {
    title: "Championship",
    bullets: [{ title: "Battle for IND Tokens", img: ShieldIcon }],
    image: "/images/bb-championship.png",
    type: FIGHT_TYPES.BLOCKBOUT,
    isLive: true,
  },
  {
    title: "Exhibition",
    bullets: [
      {
        title: "Sharpen your skills with practice matches",
        img: ChessPieceIcon,
      },
    ],
    image: "/images/exhibition-potrait.png",
    type: FIGHT_TYPES.EXHIBITION,
    isLive: true,
    // description:
    //   "Play the exhibition match & win huge prizes! The match is free to enter and free to play. Open until 3 May.",
  },
];
// test
export const POWERUP_DRAG_TYPE = "POWERUP_DRAG";
export const ACTION_DRAG_TYPE = "ACTION_DRAG";
export const MAX_POWERUP_SELECTIONS = 5;
export const MAX_ACTION_SELECTIONS = 5;
export const MAX_OFFENSIVE_ACTIONS = 3;
export const BLOCKBOTS_METADATA_BASEURL = "https://api.blockbots.gg/token";
export const isPROD = process.env.REACT_APP_ENV === "PROD";
export const isPRE_PROD = process.env.REACT_APP_IS_PRE_PROD === "true";
export const API_BASE_URL = isPRE_PROD
  ? "https://bb-pre-prod.herokuapp.com"
  : isPROD
  ? "https://blockbots-game.herokuapp.com"
  : "https://blockbot-showcase-staging-k1.herokuapp.com";
/* eslint-disable no-undef */

export const LEADERBOARD_URI = isPROD
  ? "https://leaderboard.blockbots.gg"
  : "https://bb-leaderboard-staging.herokuapp.com";

export const MARKETPLACE_URI = isPROD
  ? "https://marketplace.blockbots.gg"
  : "https://trading-powerups.herokuapp.com/";

export const addresses = {
  BlockBots: process.env.REACT_APP_BLOCKBOTS_ADDRESS,
  PowerUps: process.env.REACT_APP_POWERUPS_ADDRESS,
  IndorseToken: process.env.REACT_APP_INDORSE_ADDRESS,
};
export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID || 137);
export const CHAIN_MAP = {
  137: "Polygon Mainnet",
  80001: "Mumbai Testnet",
};

export const GA_TRACKING_ID = isPROD ? "G-6FJ3RSN5Y7" : null;
export const CHAIN_NAME = CHAIN_MAP[CHAIN_ID];
export const CHAIN_HEX = { 137: "0x89", 80001: "0x13881" };
export const SELECT_BLOCKBOT = "SELECT_BLOCKBOT";
export const SELECT_POWERUPS = "SELECT_POWERUPS";
export const SELECT_ACTIONS = "SELECT_ACTIONS";

export const INC_DEC_TO_MAP = {
  INCREASE: "+",
  DECREASE: "-",
  INCREASE_PERCENT: "%",
  DECREASE_PERCENT: "%",
};

export const INC_DEC_PREFIX = {
  INCREASE: "+",
  DECREASE: "-",
  INCREASE_PERCENT: "+",
  DECREASE_PERCENT: "-",
};

export const BLOCKBOT_BASE_ATTRIBUTE = {
  HEALTH_POINTS: "HEALTH POINTS",
  STRENGTH: "STRENGTH",
  AGILITY: "AGILITY",
  DEFENSE: "DEFENSE",
};

export const BLOCKBOT_BASE_ATTRIBUTE_SHORT = {
  "HEALTH POINTS": "HP",
  STRENGTH: "STR",
  AGILITY: "AGI",
  DEFENSE: "DEF",
};

export const APP_LABELS = {
  "HEALTH POINTS": "Health Points",
  STRENGTH: "Strength",
  AGILITY: "Agility",
  DEFENSE: "Defense",
  "CRITICAL CHANCE": "Critical Chance",
  "CRITICAL MULTIPLIER": "Critical Multiplier",
  "FAST ATTACK": "Fast Attack",
  "EVADE CHANCE": "Evade Chance",
  BLOCK: "Block",
  ATTACK: "Attack",
  HEALTH_POINTS: "Health Points",
  BOOST: "Boost",
  FAST_ATTACK: "Fast Attack",
  EVADE: "Evade",
};

export const SHORT_HAND_ID = {
  ATTACK: "ATK",
  "FAST ATTACK": "FST_ATK",
  EVADE: "EVD",
  BLOCK: "BLK",
  BOOST: "BST",
};

export const IMAGE_MAP_BLOCKBOT_BASE_ATTRIBUTE = {
  [BLOCKBOT_BASE_ATTRIBUTE.HEALTH_POINTS]:
    process.env.PUBLIC_URL + "/icons/hp_icon.svg",
  [BLOCKBOT_BASE_ATTRIBUTE.STRENGTH]:
    process.env.PUBLIC_URL + "/icons/strength_icon.svg",
  [BLOCKBOT_BASE_ATTRIBUTE.AGILITY]:
    process.env.PUBLIC_URL + "/icons/agility_icon.svg",
  [BLOCKBOT_BASE_ATTRIBUTE.DEFENSE]:
    process.env.PUBLIC_URL + "/icons/defense_icon.svg",
};

export const APP_SOCIAL_LINKS = {
  INSTAGRAM: "https://www.instagram.com/blockbots_nft/",
  TWITTER: "https://twitter.com/BlockbotsNFT",
  OPENSEA: "https://opensea.io/collection/blockbots-by-indorse",
  DISCORD: "https://discord.gg/blockbots",
};

export const STAKING_URL = isPROD
  ? ""
  : "https://blockbots-staking-stag.herokuapp.com/";

export const APP_LINKS = [
  {
    display: "Home",
    link: "/",
    external_link: false,
  },
  {
    display: "My Wallet",
    link: `/wallet`,
    external_link: false,
  },
  {
    display: "Marketplace",
    link: MARKETPLACE_URI,
    external_link: true,
  },
  {
    display: "Leaderboard",
    link: LEADERBOARD_URI,
    external_link: true,
  },
];
export const BUY_IND_TOKENS_BLOGPOST_URL = `https://blog.indorse.io/how-to-buy-ind-on-polygon-115e263401eb`;
export const BLOCKBOUT_POOL_ADDRESS = process.env.REACT_APP_POOL_ADDRESS;
export const SPENDING_WALLET_ADDRESS =
  process.env.REACT_APP_SPENDING_WALLET_ADDRESS;

export const OLD_SPENDING_WALLET_ADDRESS =
  process.env.REACT_APP_OLD_SPENDING_WALLET_ADDRESS;

export const IND_TOKEN_ADDRESS =
  process.env.REACT_APP_IND_TOKEN_CONTRACT_ADDRESS;

export const USDT_TOKEN_ADDRESS =
  process.env.REACT_APP_USDT_TOKEN_CONTRACT_ADDRESS;

export const USDC_TOKEN_ADDRESS =
  process.env.REACT_APP_USDC_TOKEN_CONTRACT_ADDRESS;

export const DAI_TOKEN_ADDRESS =
  process.env.REACT_APP_DAI_TOKEN_CONTRACT_ADDRESS;

export const POWERUPS_ADDRESS = process.env.REACT_APP_POWERUPS_CONTRACT_ADDRESS;

export const MIN_POOL_REQUIRED = 100;
export const MIN_USDT_POOL_REQUIRED = 3;
export const MIN_USDC_POOL_REQUIRED = 3;
export const MIN_DAI_POOL_REQUIRED = 3;
export const MIN_POWERUPS_REQUIRED = 2;

export const INFURA_ETH_PROJECT_KEY =
  process.env.REACT_APP_INFURA_ETH_PROJECT_KEY;

export const INFURA_CHAIN = process.env.REACT_APP_INFURA_CHAIN;

//FEATURE FLAGS

export const REFERRAL_PROGRAM_ENABLED =
  process.env.REACT_APP_REFERRAL_PROGRAM_ENABLED === "ENABLED" ? true : false;

export const WIZARD_BACKGROUND_AUDIO_FEATURE_ENABLED =
  process.env.REACT_APP_WIZARD_BACKGROUND_AUDIO_FEATURE_ENABLED === "ENABLED"
    ? true
    : false;
