import React, { useState, useEffect } from "react";
import WithWeb3 from "../../HOC/withWeb3";
import classNames from "classnames";
import styles from "./Wallet.module.scss";
import { Container, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useINDContract } from "../../hooks/useINDContract";
import apiProvider from "../../providers/api";
import DepositModal from "./DepositModal";
import WithdrawModal from "./WithdrawModal";
import Stepper from "../../components/Stepper/Stepper";
import { PowerupCard } from "../../components/Wallet/PowerupCard";
import { convertAmountToUpperDenomBasedOnDecimal } from "../../utils";
import {
  BUY_IND_TOKENS_BLOGPOST_URL,
  DAI_TOKEN_ADDRESS,
  IND_TOKEN_ADDRESS,
  MARKETPLACE_URI,
  USDC_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
} from "../../constant";
import EmptyPowerupsImage from "../../images/PowerupInCase.png";
import { useNavigate } from "react-router-dom";
import { FaInfoCircle } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import { toast } from "react-toastify";
import { useSpenderWalletContract } from "../../hooks/useSpenderWalletContract";
import { usePowerupsContract } from "../../hooks/usePowerupsContract";

const Wallet = (props) => {
  const { account } = props;
  const navigate = useNavigate();
  const [showTokenStepper, setShowTokenStepper] = useState(false);
  const [showPowerupStepper, setShowPowerupStepper] = useState(false);
  const [tokenStepValues, setTokenStepValues] = useState([
    {
      name: "Withdrawing from the Old Game Wallet",
      isLoading: false,
      done: false,
    },
    { name: "Approve the New Game Wallet", isLoading: false, done: false },
    { name: "Deposit to the New Game Wallet", isLoading: false, done: false },
  ]);
  const [powerupStepValues, setPowerupStepValues] = useState([
    {
      name: "Withdrawing from the Old Game Wallet",
      isLoading: false,
      done: false,
    },
    { name: "Approve the New Game Wallet", isLoading: false, done: false },
    { name: "Deposit to the New Game Wallet", isLoading: false, done: false },
  ]);
  const [walletBalance, setWalletBalance] = useState({
    IND: 0,
    USDT: 0,
    USDC: 0,
    DAI: 0,
  });
  const [spenderWalletBalance, setSpenderWalletBalance] = useState({
    IND: 0,
    USDT: 0,
    USDC: 0,
    DAI: 0,
  });
  const [oldSpenderWalletBalance, setOldSpenderWalletBalance] = useState({
    IND: 0,
    powerups: [],
  });
  const [powerupsBalance, setPowerupsBalance] = useState(null);
  const [spenderWalletPowerupsBalance, setSpenderWalletPowerupsBalance] =
    useState(null);
  const [depositModalVisible, setDepositModalVisible] = useState(false);
  const [withdrawModalVisible, setWithdrawModalVisible] = useState(false);
  const [fetchUpdatedBalance, setFetchUpdatedBalance] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const { setApprovalForAll } = usePowerupsContract();
  const {
    getUserBalance,
    getDAIBalance,
    getUSDTBalance,
    getUSDCBalance,
    approveOlderTokenContract,
    getApproval,
  } = useINDContract();
  const [activeTokenSelect, setActiveTokenSelect] = useState("IND");
  const {
    withdrawOldIND,
    withdrawOldPowerups,
    depositOldTokenIND,
    depositWalletTokens,
    depositPowerUps,
    depositOldTokenPowerups,
  } = useSpenderWalletContract();

  const oldSpenderWalletBalanceHandler = React.useCallback(async () => {
    const data = await apiProvider.getOldSpenderWalletBalance(account);
    if (data.indBalance || data.powerupBalance) {
      if (
        parseFloat(data.indBalance.amount) > 0 ||
        data.powerupBalance.length > 0
      ) {
        setShowNotification(true);
      }
      setOldSpenderWalletBalance({
        IND: convertAmountToUpperDenomBasedOnDecimal(
          data.indBalance ? data.indBalance.amount : 0,
          18
        ).toString(),
        powerups: data.powerupBalance,
      });
    }
  }, [account]);

  const getSpenderWalletBalance = async () => {
    const res = await apiProvider.getSpendingBalance(account);
    if (res.multiTokenBalances) {
      const indBalance = res.multiTokenBalances.find(
        (item) =>
          item.tokenAddress.toLowerCase() === IND_TOKEN_ADDRESS.toLowerCase()
      );
      const usdtBalance = res.multiTokenBalances.find(
        (item) =>
          item.tokenAddress.toLowerCase() === USDT_TOKEN_ADDRESS.toLowerCase()
      );
      const usdcBalance = res.multiTokenBalances.find(
        (item) =>
          item.tokenAddress.toLowerCase() === USDC_TOKEN_ADDRESS.toLowerCase()
      );
      const daiBalance = res.multiTokenBalances.find(
        (item) =>
          item.tokenAddress.toLowerCase() === DAI_TOKEN_ADDRESS.toLowerCase()
      );
      setSpenderWalletBalance({
        IND: convertAmountToUpperDenomBasedOnDecimal(
          indBalance && indBalance.tokenBalance ? indBalance.tokenBalance : 0,
          18
        ).toString(),
        USDT: convertAmountToUpperDenomBasedOnDecimal(
          usdtBalance && usdtBalance.tokenBalance
            ? usdtBalance.tokenBalance
            : 0,
          6
        ).toString(),
        USDC: convertAmountToUpperDenomBasedOnDecimal(
          usdcBalance && usdcBalance.tokenAddress
            ? usdcBalance.tokenBalance
            : 0,
          6
        ).toString(),
        DAI: convertAmountToUpperDenomBasedOnDecimal(
          daiBalance && daiBalance.tokenBalance ? daiBalance.tokenBalance : 0,
          18
        ).toString(),
      });
    }
    if (res.powerupBalance) {
      setSpenderWalletPowerupsBalance(res.powerupBalance);
    }
  };

  useEffect(() => {
    const getWalletBalance = async () => {
      const balance = await getUserBalance();
      const usdtBalance = await getUSDTBalance();
      const usdcBalance = await getUSDCBalance();
      const daiBalance = await getDAIBalance();
      setWalletBalance({
        IND: balance,
        USDT: usdtBalance,
        USDC: usdcBalance,
        DAI: daiBalance,
      });
    };

    const getWalletPowerups = async () => {
      const { powerups } = await apiProvider.getPowerUpsDetails({
        account,
      });
      setPowerupsBalance(powerups);
    };

    if (account) {
      getWalletBalance();
      getWalletPowerups();
      getSpenderWalletBalance();
      oldSpenderWalletBalanceHandler();
    }

    if (fetchUpdatedBalance) {
      getWalletBalance();
      getWalletPowerups();
      getSpenderWalletBalance();
      setFetchUpdatedBalance(false);
    }
  }, [
    account,
    getUserBalance,
    fetchUpdatedBalance,
    getUSDTBalance,
    getUSDCBalance,
    getDAIBalance,
    oldSpenderWalletBalanceHandler,
  ]);

  const migrateAssetsHandler = async () => {
    if (
      !oldSpenderWalletBalance.IND &&
      oldSpenderWalletBalance.powerups.length === 0
    ) {
      toast.error("No assets to migrate");
      return;
    }
    try {
      if (parseFloat(oldSpenderWalletBalance.IND) > 0) {
        setShowTokenStepper(true);
        setTokenStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: true,
            done: false,
          },
          {
            name: "Approve the New Game Wallet",
            isLoading: false,
            done: false,
          },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: false,
          },
        ]);
        await withdrawOldIND(oldSpenderWalletBalance.IND);
        setTokenStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: true, done: false },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: false,
          },
        ]);
        await getApproval(oldSpenderWalletBalance.IND, "IND");
        setTokenStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: false, done: true },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: true,
            done: false,
          },
        ]);
        await depositWalletTokens(oldSpenderWalletBalance.IND, "IND");
        setTokenStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: false, done: true },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: true,
          },
        ]);
        setShowTokenStepper(false);
        toast.success("Migration of IND tokens completed!", {
          backgroundColor: "#311E86",
          autoClose: false,
        });
        if (oldSpenderWalletBalance.powerups.length <= 0)
          await getSpenderWalletBalance();
      }
      if (oldSpenderWalletBalance.powerups.length > 0) {
        toast.dismiss();
        const ids = oldSpenderWalletBalance.powerups.map(
          (item) => item.tokenId
        );
        const amounts = oldSpenderWalletBalance.powerups.map(
          (item) => item.balance
        );
        setShowPowerupStepper(true);
        setPowerupStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: true,
            done: false,
          },
          {
            name: "Approve the New Game Wallet",
            isLoading: false,
            done: false,
          },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: false,
          },
        ]);
        await withdrawOldPowerups(ids, amounts);
        setPowerupStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: true, done: false },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: false,
          },
        ]);
        await setApprovalForAll();
        setPowerupStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: false, done: true },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: true,
            done: false,
          },
        ]);
        await depositPowerUps(ids, amounts);
        setPowerupStepValues([
          {
            name: "Withdrawing from the Old Game Wallet",
            isLoading: false,
            done: true,
          },
          { name: "Approve the New Game Wallet", isLoading: false, done: true },
          {
            name: "Deposit to the New Game Wallet",
            isLoading: false,
            done: true,
          },
        ]);
        setShowPowerupStepper(false);
        toast.success("Migration of PowerUps completed!", {
          backgroundColor: "#311E86",
          autoClose: false,
        });
        await getSpenderWalletBalance();
      }
      oldSpenderWalletBalanceHandler();
    } catch (err) {
      setShowTokenStepper(false);
      setShowPowerupStepper(false);
      setTokenStepValues([
        {
          name: "Withdrawing from the Old Game Wallet",
          isLoading: false,
          done: false,
        },
        { name: "Approve the New Game Wallet", isLoading: false, done: false },
        {
          name: "Deposit to the New Game Wallet",
          isLoading: false,
          done: false,
        },
      ]);
      setPowerupStepValues([
        {
          name: "Withdrawing from the Old Game Wallet",
          isLoading: false,
          done: false,
        },
        { name: "Approve the New Game Wallet", isLoading: false, done: false },
        {
          name: "Deposit to the New Game Wallet",
          isLoading: false,
          done: false,
        },
      ]);
      toast.dismiss();
      toast.error("Error while migrating assets");
    }
  };

  // const oldTokenHandler = async () => {
  //   try {
  //     console.log(powerupsBalance);
  //     await approveOlderTokenContract("100");
  //     await depositOldTokenIND("100");
  //     // const ids = powerupsBalance.map((item) => item.tokenId);
  //     // const amounts = powerupsBalance.map((_) => 1);
  //     // console.log(ids, amounts);
  //     // await depositOldTokenPowerups(ids, amounts);
  //   } catch (err) {
  //     console.log(err);
  //     toast.dismiss();
  //   }
  // };

  // console.log(spenderWalletBalance);

  return (
    <>
      <Modal
        isOpen={showTokenStepper || showPowerupStepper}
        // toggle={toggle}
        className={classNames(styles.ModalContainer)}
        centered
        size="lg"
      >
        <ModalHeader
          className={classNames(
            "d-flex justify-content-center",
            styles.ModalHeader
          )}
          style={{
            width: "100%",
          }}
        >
          <p className={classNames(styles.depositModalHeaderText)}>
            {showTokenStepper && "Migrating Tokens"}
            {showPowerupStepper && "Migrating Powerups"}
          </p>
        </ModalHeader>
        <p className={classNames(styles.MigrateAssetText)}>
          Migrate assets to your game wallet to start playing. You can withdraw
          the assets after the game ends.
        </p>
        <ModalBody>
          {showTokenStepper && (
            <div className={classNames(styles.StepperModal)}>
              <Stepper stepValues={tokenStepValues} />
            </div>
          )}
          {showPowerupStepper && (
            <div className={classNames(styles.StepperModal)}>
              <Stepper stepValues={powerupStepValues} />
            </div>
          )}
        </ModalBody>
      </Modal>
      <div className={classNames(styles.walletPageContainer)}>
        {/* <button onClick={oldTokenHandler}>Deposit Older Token IND</button> */}
        <div className={classNames(styles.walletContentContainer)}>
          <Container
            className={classNames(styles.MigrationNotification)}
            style={{
              display: showNotification ? "flex" : "none",
            }}
          >
            <div>
              <img src="/icons/info-bb-icon.svg" alt="info-bb-icon" />
              <p>
                New Game Wallet: you can now use multiple tokens (other than
                IND), such as DAI, USDC and more.
              </p>
            </div>
            <img
              src="/icons/close-icon.svg"
              className={classNames(styles.CrossIcon)}
              alt="close-icon"
              onClick={() => setShowNotification(false)}
            />
          </Container>
          <div className={classNames(styles.walletsContainers)}>
            <div
              className={classNames(
                styles.yourWalletContainer,
                styles.walletContainer
              )}
            >
              <div className={classNames(styles.indBalanceContainer)}>
                <div className={classNames(styles.indBalanceLabelsContainer)}>
                  <p className={classNames(styles.indBalanceLabel)}>
                    Your Wallet
                  </p>
                  <p className={classNames(styles.indBalanceAccount)}>
                    {account?.slice(0, 5)}...
                    {account?.slice(account.length - 3, account.length)}
                  </p>
                </div>
                <div className={classNames(styles.indBalanceValueContainer)}>
                  <p className={classNames(styles.indBalanceValue)}>
                    {walletBalance[activeTokenSelect]} {activeTokenSelect}
                  </p>
                </div>
                <div className={classNames(styles.getTokensContainer)}>
                  <div>
                    <a
                      href={BUY_IND_TOKENS_BLOGPOST_URL}
                      target="_blank"
                      rel="noreferrer"
                      className={classNames(styles.getTokensButton)}
                    >
                      <p>Get IND Tokens</p>
                    </a>
                  </div>
                  <div>
                    <a
                      href={MARKETPLACE_URI}
                      target="_blank"
                      rel="noreferrer"
                      className={classNames(styles.getTokensButton)}
                    >
                      <p>Get Powerups</p>
                    </a>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.powerupsContainer)}>
                {powerupsBalance === null ? (
                  <div className="loader">
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ color: "mediumpurple" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : powerupsBalance && powerupsBalance.length > 0 ? (
                  powerupsBalance.map((powerup, index) => (
                    <PowerupCard powerup={powerup} key={index} />
                  ))
                ) : (
                  <div
                    className={classNames(
                      styles.emptySpenderWalletPowerupsContainer
                    )}
                  >
                    <img
                      src={EmptyPowerupsImage}
                      alt="no powerups"
                      className={classNames(
                        styles.emptySpenderWallerPowerupsImage
                      )}
                    />
                    <p
                      className={classNames(
                        styles.emptySpenderWalletPowerupsText
                      )}
                    >
                      No Powerups in your wallet.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div
              className={classNames(
                styles.spenderWalletContainer,
                styles.walletContainer
              )}
            >
              <div
                className={classNames(
                  styles.indBalanceContainer,
                  styles.spenderWalletBalanceContainer
                )}
              >
                <div className={classNames(styles.indBalanceLabelsContainer)}>
                  <span className="d-flex align-items-center">
                    <p className={classNames(styles.indBalanceLabel)}>
                      {oldSpenderWalletBalance.IND > 0 ||
                      oldSpenderWalletBalance.powerups.length
                        ? "Old Game Wallet"
                        : "Game Wallet"}
                    </p>
                    <UncontrolledTooltip target={"game-wallet-tooltip"}>
                      {oldSpenderWalletBalance.IND > 0 ||
                      oldSpenderWalletBalance.powerups.length
                        ? "Use your old wallet to migrate assets to your game wallet. You can withdraw tokens from your game wallet at any time in between games."
                        : "Transfer IND Tokens to your game wallet to enter the Championship mode. You can withdraw your tokens back to your wallet at any time."}
                    </UncontrolledTooltip>
                    <FaInfoCircle
                      className={classNames("mb-3 ml-2")}
                      id={"game-wallet-tooltip"}
                    />
                  </span>
                </div>
                <div className={classNames(styles.indBalanceValueContainer)}>
                  <p className={classNames(styles.indBalanceValue)}>
                    {parseFloat(oldSpenderWalletBalance.IND) > 0 ||
                    oldSpenderWalletBalance.powerups.length
                      ? `${oldSpenderWalletBalance.IND} IND`
                      : `${spenderWalletBalance[activeTokenSelect]} ${activeTokenSelect}`}
                  </p>
                </div>
                <div
                  className={classNames(styles.indTokenSelector)}
                  style={{
                    display:
                      oldSpenderWalletBalance.IND > 0 ||
                      oldSpenderWalletBalance.powerups.length
                        ? "none"
                        : "",
                  }}
                >
                  <div
                    className={classNames(
                      styles.IconButtonContainer,
                      activeTokenSelect === "IND" && styles.ActiveIconButton
                    )}
                    onClick={() => setActiveTokenSelect("IND")}
                  >
                    <img
                      src="../../../icons/indorse-token-icon.svg"
                      height={18}
                      width={18}
                      alt="indorse-token"
                    />
                    <p>IND</p>
                  </div>
                  <div
                    className={classNames(
                      styles.IconButtonContainer,
                      activeTokenSelect === "USDC" && styles.ActiveIconButton
                    )}
                    onClick={() => setActiveTokenSelect("USDC")}
                  >
                    <img
                      src="../../../icons/usdc-token-icon.svg"
                      height={18}
                      width={18}
                      alt="usdc-token"
                    />
                    <p>USDC</p>
                  </div>
                  <div
                    className={classNames(
                      styles.IconButtonContainer,
                      activeTokenSelect === "USDT" && styles.ActiveIconButton
                    )}
                    onClick={() => setActiveTokenSelect("USDT")}
                  >
                    <img
                      src="../../../icons/usdt-token-icon.svg"
                      height={18}
                      width={18}
                      alt="usdt-token"
                    />
                    <p>USDT</p>
                  </div>
                  <div
                    className={classNames(
                      styles.IconButtonContainer,
                      activeTokenSelect === "DAI" && styles.ActiveIconButton
                    )}
                    onClick={() => setActiveTokenSelect("DAI")}
                  >
                    <img
                      src="../../../icons/dai-token-icon.svg"
                      height={18}
                      width={18}
                      alt="dai-token"
                    />
                    <p>DAI</p>
                  </div>
                </div>
              </div>
              <div className={classNames(styles.powerupsContainer)}>
                {spenderWalletPowerupsBalance === null ? (
                  <div className="loader">
                    <div
                      className="spinner-border"
                      role="status"
                      style={{ color: "mediumpurple" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : oldSpenderWalletBalance.powerups &&
                  oldSpenderWalletBalance.powerups.length ? (
                  oldSpenderWalletBalance.powerups.map((powerup, index) => (
                    <PowerupCard powerup={powerup} key={index} />
                  ))
                ) : spenderWalletPowerupsBalance &&
                  spenderWalletPowerupsBalance.length > 0 &&
                  oldSpenderWalletBalance.IND === "0" ? (
                  spenderWalletPowerupsBalance.map((powerup, index) => {
                    return <PowerupCard powerup={powerup} key={index} />;
                  })
                ) : (
                  <div
                    className={classNames(
                      styles.emptySpenderWalletPowerupsContainer
                    )}
                  >
                    <img
                      src={EmptyPowerupsImage}
                      alt="no powerups"
                      className={classNames(
                        styles.emptySpenderWallerPowerupsImage
                      )}
                    />
                    <p
                      className={classNames(
                        styles.emptySpenderWalletPowerupsText
                      )}
                    >
                      No Powerups added to the game wallet.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={classNames(styles.buttonsContainer)}>
            {!parseFloat(oldSpenderWalletBalance.IND) &&
            !oldSpenderWalletBalance.powerups.length &&
            (walletBalance.IND > 0 || powerupsBalance?.length > 0) ? (
              <button
                className={classNames(styles.primaryButton, "mr-4")}
                onClick={() => setDepositModalVisible(true)}
              >
                Add to Game Wallet
              </button>
            ) : (
              <></>
            )}
            {(spenderWalletBalance[activeTokenSelect] > 0 ||
              spenderWalletPowerupsBalance?.length > 0) &&
            !parseFloat(oldSpenderWalletBalance.IND) &&
            !oldSpenderWalletBalance.powerups.length ? (
              <button
                className={classNames(styles.primaryButton, "mr-4")}
                onClick={() => setWithdrawModalVisible(true)}
              >
                Withdraw
              </button>
            ) : (
              <></>
            )}
            {
              <button
                className={classNames(styles.primaryButton)}
                onClick={() => navigate("/")}
                style={{
                  display:
                    oldSpenderWalletBalance.IND > 0 ||
                    oldSpenderWalletBalance.powerups.length
                      ? "none"
                      : "",
                }}
              >
                Play Game
              </button>
            }
            {oldSpenderWalletBalance.IND > 0 ||
            oldSpenderWalletBalance.powerups.length ? (
              <button
                className={classNames(styles.primaryButton)}
                onClick={migrateAssetsHandler}
              >
                Migrate Assets
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        <DepositModal
          modalVisible={depositModalVisible}
          modalValues={[walletBalance, powerupsBalance]}
          toggle={() => setDepositModalVisible(!depositModalVisible)}
          powerups={powerupsBalance}
          setFetchUpdatedBalance={setFetchUpdatedBalance}
        />
        <WithdrawModal
          modalVisible={withdrawModalVisible}
          modalValues={[spenderWalletBalance, spenderWalletPowerupsBalance]}
          toggle={() => setWithdrawModalVisible(!withdrawModalVisible)}
          powerups={spenderWalletPowerupsBalance}
          setFetchUpdatedBalance={setFetchUpdatedBalance}
        />
      </div>
    </>
  );
};

export default WithWeb3(Wallet);
