import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../FightWizard.module.scss";
import classNames from "classnames";
import { selectBlockbot, setCurrentState } from "../../../actions";
import {
  DAI_TOKEN_ADDRESS,
  IND_TOKEN_ADDRESS,
  MIN_DAI_POOL_REQUIRED,
  MIN_POOL_REQUIRED,
  MIN_USDC_POOL_REQUIRED,
  MIN_USDT_POOL_REQUIRED,
  SELECT_POWERUPS,
  USDC_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
} from "../../../constant";
import { isEmpty } from "lodash";
import { Button } from "reactstrap";
import { StatCardSmall } from "../../../components/StatCard";
import { Pagination } from "reactstrap";
import ReactPaginate from "react-paginate";
import { RECAPTCHA_THRESHOLD } from "../../../constant";
import {
  convertAmountToUpperDenomBasedOnDecimal,
  RecaptchaComponent,
} from "../../../utils";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import apiProvider from "../../../providers/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SelectBlockbot = ({
  blockbots,
  account,
  triedConnect,
  blockbotDetailsCurrentPage,
  setBlockbotDetailsCurrentPage,
  totalNumberOfBlockbots,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { selectedBlockbot, isOwner } = useSelector((state) => state.wizard);
  const { isLoading, data } = blockbots;
  // const [extraBots, setExtraBots] = useState([]);
  const numberOfPages = Math.ceil(totalNumberOfBlockbots / 10);

  const onNextClick = async () => {
    dispatch(setCurrentState(SELECT_POWERUPS));
    const recaptchaToken = await executeRecaptcha("select_blockbot");
    const reCaptchaResp = await apiProvider.verifyRecaptcha(recaptchaToken);
    const THRESHOLD = parseFloat(RECAPTCHA_THRESHOLD);
    if (reCaptchaResp && reCaptchaResp.score < THRESHOLD) {
      toast("You have been blocked due to too many requests", {
        toastId: "MAYBE_BOT",
      });
      throw new Error();
    }
  };

  const getTokenBasedDecimals = (address) => {
    switch (address) {
      case IND_TOKEN_ADDRESS:
        return 18;
      case USDT_TOKEN_ADDRESS:
        return 6;
      case USDC_TOKEN_ADDRESS:
        return 6;
      case DAI_TOKEN_ADDRESS:
        return 18;
      default:
        return 18;
    }
  };

  const getTokenBasedMinBalance = (address) => {
    switch (address) {
      case IND_TOKEN_ADDRESS:
        return MIN_POOL_REQUIRED;
      case USDT_TOKEN_ADDRESS:
        return MIN_USDT_POOL_REQUIRED;
      case USDC_TOKEN_ADDRESS:
        return MIN_USDC_POOL_REQUIRED;
      case DAI_TOKEN_ADDRESS:
        return MIN_DAI_POOL_REQUIRED;
      default:
        return MIN_POOL_REQUIRED;
    }
  };

  const getTokenName = (tokenName) => {
    switch (tokenName) {
      case IND_TOKEN_ADDRESS:
        return "IND";
      case USDT_TOKEN_ADDRESS:
        return "USDT";
      case USDC_TOKEN_ADDRESS:
        return "USDC";
      case DAI_TOKEN_ADDRESS:
        return "DAI";
      default:
        return "IND";
    }
  };

  useEffect(() => {
    const validateInviteId = async (id) => {
      const { data } = await apiProvider.validateInvite(id);
      const res = await apiProvider.getSpendingBalance(account);
      let inviteStakedTokenBalance = res.multiTokenBalances.find(
        (item) => item.tokenAddress === data.depositTokenAddress
      );
      inviteStakedTokenBalance = convertAmountToUpperDenomBasedOnDecimal(
        inviteStakedTokenBalance.tokenBalance,
        getTokenBasedDecimals(data.depositTokenAddress)
      ).toString();
      if (account.toLowerCase() === data.playerEthAddress.toLowerCase()) {
        toast.error(
          "This invite was created with the same account. Please share it with other players to play the game.",
          {
            toastId: "MAYBE_BOT",
            hideProgressBar: true,
          }
        );
        setTimeout(() => navigate("/select?fightType=ARENA"), 1900);
        return;
      } else if (data.linkExpired) {
        toast.error("Invite link has expired", {
          toastId: "INVITE_LINK_EXPIRED",
          hideProgressBar: true,
        });
        setTimeout(() => navigate("/select?fightType=ARENA"), 1900);
        return;
      } else if (
        inviteStakedTokenBalance <
        getTokenBasedMinBalance(data.depositTokenAddress)
      ) {
        const tokenName = getTokenName(data.depositTokenAddress);
        toast.error(
          tokenName === "IND"
            ? "You do not have enough balance to play this game. Please deposit at least 100 IND to enter."
            : `You do not have enough balance to play this game. Please deposit at least $3 worth of ${tokenName} to enter.`,
          {
            toastId: "MAYBE_BOT",
            hideProgressBar: true,
          }
        );
        setTimeout(() => navigate("/wallet"), 1900);
        return;
      } else {
        localStorage.setItem("inviteId", id);
      }
    };
    localStorage.removeItem("inviteId");
    const inviteId = new URLSearchParams(window.location.search).get(
      "inviteId"
    );
    if (inviteId) {
      validateInviteId(inviteId);
    }
  }, []);

  useEffect(() => {
    if (data && data.length === 1) {
      dispatch(selectBlockbot(data[0]));
      onNextClick();
    }
  }, [data, dispatch, onNextClick]);

  // const checkSpecialBot = useCallback(async () => {
  //   try {
  //     const data = await apiProvider.getSpecialBot();
  //     if (data) {
  //       const tempArr = [data];
  //       setExtraBots(tempArr);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  // useEffect(() => {
  //   checkSpecialBot();
  // }, [checkSpecialBot]);
  // console.log(extraBots);
  if (isLoading)
    return (
      <div className="loader">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "mediumpurple" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  return (
    <>
      <div className="wizard-heading-container">
        <div className="step-container">
          <div className="step-current">STEP 1</div>
          <div className="step-desc">Choose a BlockBot to continue</div>
          {!isOwner && (
            <div className="step-current">
              Since you don't own any BlockBot, you can book a bot from these
              options. You'll still be eligible for the rewards, while playing
              for free!
            </div>
          )}
        </div>

        <button
          onClick={() => onNextClick()}
          disabled={isEmpty(selectedBlockbot) || !selectedBlockbot.tokenId}
          style={{
            opacity: isEmpty(selectedBlockbot) ? 0.5 : 1,
          }}
          className="arrow-btn-styles"
        >
          <img
            src="../../../../icons/right-arrow.svg"
            alt="right-arrow"
            height={40}
            width={40}
          />
        </button>
      </div>
      <div className={classNames(styles.blockbotCardsContainer)}>
        {data && data.length ? (
          [...data].map((item, index) => {
            return (
              <CollapsibleCard
                imgSrc={item.image}
                name={item.name}
                clan={
                  item?.attributes?.filter(
                    (elm) => elm?.trait_type === "Clan"
                  )[0]?.value
                }
                basePower={item.basePower}
                selected={item?.tokenId === selectedBlockbot?.tokenId}
                setSelected={() => dispatch(selectBlockbot(item))}
                key={index}
                itemIdx={item.tokenId}
              />
            );
          })
        ) : (
          <div>Aww Snap !! seems like you dont own a blockbot</div>
        )}
      </div>
      {numberOfPages > 1 && (
        <>
          <Pagination className="pagination-bar mx-auto" size="lg">
            <ReactPaginate
              nextLabel=">"
              onPageChange={setBlockbotDetailsCurrentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={numberOfPages}
              previousLabel="<"
              pageClassName="page-item"
              pageLinkClassName={styles.pageLink}
              previousClassName={styles.previousPageItem}
              previousLinkClassName="previous-page-link"
              nextClassName={styles.nextPageItem}
              nextLinkClassName="next-page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName={styles.pageLink}
              containerClassName={styles.pagination}
              activeClassName={styles.activePageLink}
              renderOnZeroPageCount={null}
              forcePage={blockbotDetailsCurrentPage - 1}
            />
          </Pagination>
        </>
      )}
    </>
  );
};

export default RecaptchaComponent(SelectBlockbot);
// export default SelectBlockbot;

const CollapsibleCard = ({
  imgSrc,
  name,
  clan,
  basePower,
  selected,
  setSelected,
  itemIdx,
}) => {
  const coverImgStyle = { objectFit: "cover" };
  return (
    <div
      className={classNames(
        styles.collapsibleCard,
        selected && styles.blueBorder
      )}
      onClick={setSelected}
    >
      <div className={styles.cardContent}>
        <div className={styles.cardImg}>
          <img
            style={clan === "Legendary" ? coverImgStyle : null}
            src={imgSrc}
            alt=""
          />
        </div>
        <div className={styles.cardInfoWrapper}>
          <div className={styles.cardInfo}>
            <div className={styles.cardTitle}>{name}</div>
            <div className={styles.cardDesc}>{clan}</div>
          </div>
        </div>
        <div className={styles.attrCont}>
          {basePower?.map((item, index) => {
            const label =
              item.powerType === "HEALTH_POINTS"
                ? "HEALTH POINTS"
                : item.powerType;

            return (
              <StatCardSmall
                label={label}
                key={index}
                tooltipId={`${itemIdx}`}
                value={item.value}
              />
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            onClick={setSelected}
            className={classNames(
              "primary-blue-btn",
              "thin-btn",
              selected && "btn-selected"
            )}
          >
            {selected ? "Selected" : "Select"}
          </Button>
        </div>
      </div>
    </div>
  );
};
