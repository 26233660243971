import { createReducer, createSelector } from "@reduxjs/toolkit";
import { fetchBlockbotLayers } from "../actions";

const initialState = {
  gameDetails: {},
  selectedBlockbot: {},
  opponentBlockbot: {},
};

export const fightReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchBlockbotLayers.fulfilled, (state, action) => {
    if (action.payload.type === "selected") {
      state.selectedBlockbot = action.payload;
    }
    if (action.payload.type === "opponent") {
      state.opponentBlockbot = action.payload;
    }
  });
});

export const fightSelector = createSelector(
  (state) => state.fight,
  (state) => state
);
