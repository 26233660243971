import { createReducer, createSelector } from "@reduxjs/toolkit";
import { fetchProfileDetails, resetProfile } from "../actions";

const initialState = {
  user: {},
};

export const profileReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchProfileDetails.fulfilled, (state, action) => {
    state.user = action.payload;
  });
  builder.addCase(resetProfile, (state) => {
    state.user = {};
  });
});

export const profileSelector = createSelector(
  (state) => state.profile,
  (state) => state
);
