import React from "react";
import classNames from "classnames";
import styles from "./Stepper.module.scss";
import { FaCheckCircle } from "react-icons/fa";

const Stepper = ({ stepValues }) => {
  return (
    <div className={classNames(styles.StepperContainer)}>
      {stepValues.map((item, index) => (
        <div key={index}>
          <section className={classNames(styles.StepperItem)}>
            {item.isLoading ? (
              <div className={classNames(styles.StepperLoaderContainer)}>
                <div
                  className="spinner-border"
                  role="status"
                  style={{ color: "#311e86", height: 19, width: 19 }}
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : item.done ? (
              <FaCheckCircle className={styles.completed} />
            ) : (
              <div className={styles.upcoming}></div>
            )}
            <p className={classNames(styles.StepperTitle)}>{item.name}</p>
          </section>
          {item.isLoading && (
            <div className={classNames(styles.StepperLoadingStateContainer)}>
              <p className={classNames(styles.StepperLoadingStateTitle)}>
                Please wait while your transaction is being completed
              </p>
              <p className={classNames(styles.StepperLoadingStateDescription)}>
                Transactions can take a few seconds to mine. Do not refresh or
                reload this webpage. Please check your Metamask wallet to verify
                and approve any pending transactions
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
