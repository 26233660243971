import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import apiProvider from "../providers/api";

export const fetchProfileDetails = createAsyncThunk(
  "profile/fetchProfileDetails",
  async (ethAddress, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await apiProvider.fetchProfileDetails(ethAddress);
      return { ...data };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const setProfileDetails = createAsyncThunk(
  "profile/setProfileDetails",
  async (profileObject, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await apiProvider.setProfileDetails(profileObject);
      return { ...data };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const resetProfile = createAction("profile/resetProfile");
