import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./CurrentRound.module.scss";
import Lottie from "react-lottie";

import Round1Animation from "../../../assets/Lottie/Round1.json";
import Round2Animation from "../../../assets/Lottie/Round2.json";
import Round3Animation from "../../../assets/Lottie/Round3.json";
import Round4Animation from "../../../assets/Lottie/Round4.json";
import Round5Animation from "../../../assets/Lottie/Round5.json";

const CurrentRound = ({ currentRound }) => {
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    switch (currentRound) {
      case 1:
        setAnimationData(Round1Animation);
        return;
      case 2:
        setAnimationData(Round2Animation);
        return;
      case 3:
        setAnimationData(Round3Animation);
        return;
      case 4:
        setAnimationData(Round4Animation);
        return;
      case 5:
        setAnimationData(Round5Animation);
        return;
      default:
        setAnimationData(null);
        return;
    }
  }, [currentRound]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
    animationData: animationData,
  };
  return (
    <div className={classNames(styles.lottieAnimationContainer)}>
      <Lottie options={defaultOptions} height={400} width={400} />
    </div>
  );
};

export default CurrentRound;
