import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import clx from "classnames";
import { APP_SOCIAL_LINKS } from "../../../constant";
export const BookerModal = ({ isOpen, onClose }) => {
  return (
    <Modal className="booker-modal" size="lg" centered isOpen={isOpen}>
      <ModalHeader>
        <div className="booker-message-heading">
          Enjoying the BlockBots free-to-play tournament?
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="booker-modal-message">
          Get your own BlockBot to compete for even bigger prizes!
        </div>
      </ModalBody>
      <ModalFooter>
        <a href={APP_SOCIAL_LINKS.OPENSEA} target="_blank" rel="noreferrer">
          <Button
            className={clx("primary-blue-btn", "transparent-btn", "white-brd")}
          >
            <div className="booker-modal-btn-content">
              <img
                src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg"
                alt=""
              />
              Get Your BlockBot
            </div>
          </Button>
        </a>
        <Button
          onClick={onClose}
          className={clx("primary-blue-btn", "light-blue-btn")}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};
