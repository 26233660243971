import classNames from "classnames";
import styles from "./PowerupCard.module.scss";

export const PowerupCard = ({
  powerup,
  showQuantityManipulation,
  quantity,
  onAdd,
  onSubtract,
}) => {
  return (
    <div
      className={classNames(
        styles.powerupCardContainer,
        quantity > 0 ? styles.selectedPowerupCardContainer : null
      )}
    >
      <div
        className={classNames(styles.powerupCardImageContainer)}
        style={{ backgroundColor: powerup.backgroundColor }}
      >
        <img
          className={classNames(styles.powerupCardImage)}
          src={powerup.image}
          alt="powerup"
        />
      </div>
      <div
        className={
          showQuantityManipulation && classNames(styles.powerupContentContainer)
        }
      >
        <div className={classNames(styles.powerupTextContainer)}>
          <p className={classNames(styles.powerupText)}>{powerup.name}</p>
          <p className={classNames(styles.powerupQuantity)}>
            {powerup.numberOfTokens || powerup.balance}
          </p>
        </div>
        {showQuantityManipulation && (
          <div className={classNames(styles.powerupSelectedContainer)}>
            <div
              className={classNames(styles.operatorContainer)}
              onClick={() => onSubtract()}
            >
              <p> - </p>
            </div>
            <p className={classNames(styles.quantityValue)}>
              {quantity} selected
            </p>
            <div
              className={classNames(styles.operatorContainer)}
              onClick={() => onAdd()}
            >
              <p>+</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
