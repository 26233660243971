/* eslint-disable no-undef */
import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import { toast } from "react-toastify";

const chainId = process.env.REACT_APP_CHAIN_ID;
const supportedChain =
  parseFloat(chainId) === 137 ? "Polygon Mainnet" : "Polygon Testnet (Mumbai)";
const TOAST_ID_NOT_FOUND = "not-found";
const TOAST_ID_WRONG_CHAIN = "wrong-chain";
const TOAST_ID_REJECTED_REQUEST = "rejected-request";
const TOAST_ERROR_MSG = "error-toast";

// Handle errors for all connectors here
export const getErrorMessage = (error) => {
  if (error instanceof NoEthereumProviderError) {
    toast(
      "🦊 Metamask wallet not found, Please install Metamask to continue.",
      {
        toastId: TOAST_ID_NOT_FOUND,
        autoClose: 999999,
      }
    );
    return;
  } else if (error instanceof UnsupportedChainIdError) {
    toast(`Please connect your Metamask Wallet to ${supportedChain}.`, {
      toastId: TOAST_ID_WRONG_CHAIN,
      // autoClose: 999999,
    });
    return;
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    toast("Please press confirm on your Metamask to continue.", {
      toastId: TOAST_ID_REJECTED_REQUEST,
    });
    return;
  } else if (
    error?.data?.message === "execution reverted: Cooldown in progress."
  ) {
    toast("Cooldown in progress, Please try again after 10 blocks", {
      toastId: "COOLDOWN",
    });
    return;
  } else {
    toast.error(error?.message || "Some error occured", { toastId: "ERROR" });
    console.error(error.message);
    return;
  }
};

export const showToastErrorMessage = (err) => {
  toast.error(`${err.message || err || "Error Occurred"}`, {
    toastId: TOAST_ERROR_MSG,
  });
};
