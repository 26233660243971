import { useMemo } from "react";
import { useStore } from "./fightState";
import { AnimatedBot } from "./Blockbot/AnimatedBot";

export const AnimatedBotLayer = (props) => {
  const {
    selectedBlockbot,
    opponentBlockbot,
    setLoadingComplete,
    rounds,
    arena,
  } = props;
  const { currentRound } = useStore();
  const { botOneAction, botTwoAction, botOneNextAction, botTwoNextAction } =
    useMemo(() => {
      return {
        botOneAction:
          (rounds && rounds.length && rounds[currentRound]?.action1) || null,
        botTwoAction:
          (rounds && rounds.length && rounds[currentRound]?.action2) || null,
        botOneNextAction:
          (rounds && rounds.length && rounds[currentRound + 1]?.action1) ||
          null,
        botTwoNextAction:
          (rounds && rounds.length && rounds[currentRound + 1]?.action2) ||
          null,
      };
    }, [currentRound, rounds]);

  const position = useMemo(() => {
    switch (arena) {
      case "BOXING":
        return [0, -8, -7];
      case "UFC":
        return [0, -9, -3];
      default:
        return [0, -8, -7];
    }
  }, [arena]);

  return (
    <instancedMesh castShadow scale={1} position={position}>
      {selectedBlockbot ? (
        <AnimatedBot
          blockbot={selectedBlockbot}
          botAction={botOneAction}
          botNextAction={botOneNextAction}
          setLoaded={setLoadingComplete}
          botLoaderKey="botOneLoaded"
        />
      ) : null}
      {opponentBlockbot ? (
        <AnimatedBot
          blockbot={opponentBlockbot}
          botAction={botTwoAction}
          botNextAction={botTwoNextAction}
          isOnRight={true}
          setLoaded={setLoadingComplete}
          botLoaderKey="botTwoLoaded"
        />
      ) : null}
    </instancedMesh>
  );
};
