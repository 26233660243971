import React from "react";
import classNames from "classnames";
import styles from "./CurrentAction.module.scss";

const CurrentAction = ({ action, inverted }) => {
  if (!action) return null;
  const AttackIcon = `/Actions/Attack.svg`;
  const BlockIcon = `/Actions/Block.svg`;
  const BoostIcon = `/Actions/Boost.svg`;
  const EvadeIcon = `/Actions/Evade.svg`;
  const FastAttackIcon = `/Actions/FastAttack.svg`;

  const renderAction = (action) => {
    let title;
    let icon;
    switch (action) {
      case "ATTACK":
        icon = AttackIcon;
        title = "Attack";
        return { icon, title };
      case "BLOCK":
        icon = BlockIcon;
        title = "Block";
        return { icon, title };

      case "FAST_ATTACK":
        icon = FastAttackIcon;
        title = "Fast Attack";
        return { icon, title };
      case "BOOST":
        icon = BoostIcon;
        title = "Boost";
        return { icon, title };
      case "EVADE":
        icon = EvadeIcon;
        title = "Evade";
        return { icon, title };
      default:
        return null;
    }
  };

  if (inverted) {
    return (
      <div className={classNames(styles.opponentBlockbotAction)}>
        <p className={classNames(styles.blockbotActionLabel)}>CURRENT ACTION</p>
        <div className={classNames(styles.blockbotActionLabelContainer)}>
          {renderAction(action)?.icon ? (
            <img
              src={renderAction(action).icon}
              alt=""
              className={classNames(styles.blockbotActionIcon)}
            />
          ) : null}
          {renderAction(action)?.title ? (
            <p className={classNames(styles.blockbotActionValue)}>
              {renderAction(action).title}
            </p>
          ) : null}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames(styles.selectedBlockbotAction)}>
        <p className={classNames(styles.blockbotActionLabel)}>CURRENT ACTION</p>
        <div className={classNames(styles.blockbotActionLabelContainer)}>
          {renderAction(action)?.icon ? (
            <img
              src={renderAction(action).icon}
              alt=""
              className={classNames(styles.blockbotActionIcon)}
            />
          ) : null}
          {renderAction(action)?.title ? (
            <p className={classNames(styles.blockbotActionValue)}>
              {renderAction(action).title}
            </p>
          ) : null}
        </div>
      </div>
    );
  }
};

export default CurrentAction;
