import React, { useState } from "react";
import classNames from "classnames";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Stepper from "../../components/Stepper/Stepper";
import styles from "./Wallet.module.scss";
import { useSpenderWalletContract } from "../../hooks/useSpenderWalletContract";
import { useINDContract } from "../../hooks/useINDContract";
import { usePowerupsContract } from "../../hooks/usePowerupsContract";
import DepositPowerups from "../../components/Wallet/DepositPowerups";
import downArrow from "../../images/icons/down-arrow.svg";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";

const DepositModal = ({
  modalVisible,
  toggle,
  powerups,
  modalValues,
  setFetchUpdatedBalance,
}) => {
  const [depositType, setDepositType] = useState("IND");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const { depositWalletTokens, depositPowerUps } = useSpenderWalletContract();
  const { getApproval } = useINDContract();
  const { setApprovalForAll, isApprovedForAll } = usePowerupsContract();
  const [stepValues, setStepValues] = useState([
    { name: "Approve", isLoading: false, done: false },
    { name: "Deposit", isLoading: false, done: false },
  ]);
  const [showStepper, setShowStepper] = useState(false);

  const [selectedPowerups, setSelectedPowerups] = useState(null);

  const depositTokens = async () => {
    try {
      if (parseFloat(depositAmount) > parseFloat(modalValues[0])) {
        throw new Error(
          "Please deposit an amount less than or equal to the IND you own in your wallet."
        );
      }
      setStepValues([
        { name: "Approve", isLoading: true, done: false },
        { name: "Deposit", isLoading: false, done: false },
      ]);
      await getApproval(depositAmount, depositType);
      setStepValues((prev) =>
        prev.map((item) =>
          item.name === "Approve"
            ? { ...item, isLoading: false, done: true }
            : item
        )
      );
      setStepValues((prev) =>
        prev.map((item) =>
          item.name === "Deposit" ? { ...item, isLoading: true } : item
        )
      );
      await depositWalletTokens(depositAmount, depositType);
      setStepValues([
        { name: "Approve", isLoading: false, done: true },
        { name: "Deposit", isLoading: false, done: true },
      ]);
      setDropdownOpen(false);
      setDepositAmount("");
      setShowStepper(false);
      // setDepositType(null);
      toggle();
      setFetchUpdatedBalance(true);
      toast.success(`${depositType} Tokens deposited successfully`);
    } catch (err) {
      let message;
      if (err.message.includes("user rejected transaction"))
        message = "Transaction rejected by user";
      toast.error(message || "Error while depositing tokens", {
        autoClose: 1900,
        position: "top-right",
        hideProgressBar: true,
      });
      setShowStepper(false);
      setDepositAmount("");
      // setDepositType(null);
      toggle();
      setStepValues([
        { name: "Approve", isLoading: false, done: false },
        { name: "Deposit", isLoading: false, done: false },
      ]);
    }
  };

  const powerupsSubmitHandler = async (powerups) => {
    try {
      setStepValues([
        { name: "Approve", isLoading: true, done: false },
        { name: "Deposit", isLoading: false, done: false },
      ]);
      setShowStepper(true);
      const isAlreadyApproved = await isApprovedForAll();
      if (!isAlreadyApproved) {
        setStepValues([
          { name: "Approve", isLoading: true, done: false },
          { name: "Deposit", isLoading: false, done: false },
        ]);
        await setApprovalForAll();
      }

      setStepValues((prev) =>
        prev.map((item) =>
          item.name === "Approve"
            ? { ...item, isLoading: false, done: true }
            : item
        )
      );
      const nonZeroSelectedPowerups = powerups.filter(
        (powerup) => powerup.quantity > 0
      );

      const selectedTokenIds = nonZeroSelectedPowerups.map(
        (powerup) => powerup.id
      );
      const selectedQuantities = nonZeroSelectedPowerups.map(
        (powerup) => powerup.quantity
      );

      setStepValues((prev) =>
        prev.map((item) =>
          item.name === "Deposit" ? { ...item, isLoading: true } : item
        )
      );

      await depositPowerUps(selectedTokenIds, selectedQuantities);

      setStepValues([
        { name: "Approve", isLoading: false, done: true },
        { name: "Deposit", isLoading: false, done: true },
      ]);
      setDropdownOpen(false);
      // setDepositType(null);
      setShowStepper(false);
      setSelectedPowerups(null);
      toggle();
      setFetchUpdatedBalance(true);
      toast.success("Powerups deposited successfully");
    } catch (err) {
      console.log("Message: ", err.message);
      toast.error(err.message || "Error while depositing tokens", {
        autoClose: 1900,
        position: "top-right",
        hideProgressBar: true,
      });
      setDropdownOpen(false);
      setShowStepper(false);
      // setDepositType(null);
      setSelectedPowerups(null);
      toggle();
      setStepValues([
        { name: "Approve", isLoading: false, done: false },
        { name: "Deposit", isLoading: false, done: false },
      ]);
      console.log(err);
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      // toggle={toggle}
      className={classNames(styles.ModalContainer)}
      centered
      size="lg"
    >
      {!showStepper && (
        <div
          className={classNames(styles.closeButtonContainer)}
          onClick={() => toggle()}
        >
          <FaTimes />
        </div>
      )}
      <ModalHeader
        className={classNames(
          "d-flex justify-content-center",
          styles.ModalHeader
        )}
      >
        <p className={classNames(styles.depositModalHeaderText)}>
          Add to Game Wallet
        </p>
      </ModalHeader>
      {showStepper && (
        <div className={classNames(styles.StepperModal)}>
          <Stepper stepValues={stepValues} />
        </div>
      )}
      <ModalBody
        className={classNames(styles.ModalBody)}
        style={{
          display: showStepper ? "none" : "block",
        }}
      >
        <p className={classNames(styles.chooseDescriptionText)}>
          Choose what you want to add
        </p>
        <Dropdown
          isOpen={dropdownOpen && !showStepper}
          toggle={() => setDropdownOpen(!dropdownOpen)}
          className={classNames(styles.depositDropdown)}
        >
          <DropdownToggle className={classNames(styles.depositDropdownToggle)}>
            <p className={classNames(styles.dropdownSelectorText)}>
              {depositType === "IND"
                ? "IND Tokens"
                : depositType === "USDT"
                ? "USDT Tokens"
                : depositType === "USDC"
                ? "USDC Tokens"
                : depositType === "DAI"
                ? "DAI Tokens"
                : depositType === "POWERUPS"
                ? "Powerups"
                : "Choose Deposit Type"}
            </p>
            <img src={downArrow} alt="down-arrow" />
          </DropdownToggle>
          <DropdownMenu className={classNames(styles.dropDownMenu)}>
            <DropdownItem
              onClick={() => setDepositType("IND")}
              disabled={!modalValues[0].IND}
              className={classNames(styles.DropdownItem)}
            >
              IND Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setDepositType("USDT")}
              disabled={!modalValues[0].USDT}
              className={classNames(styles.DropdownItem)}
            >
              USDT Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setDepositType("USDC")}
              disabled={!modalValues[0].USDC}
              className={classNames(styles.DropdownItem)}
            >
              USDC Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setDepositType("DAI")}
              disabled={!modalValues[0].DAI}
              className={classNames(styles.DropdownItem)}
            >
              DAI Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setDepositType("POWERUPS")}
              disabled={!(modalValues[1] && modalValues[1].length)}
              className={classNames(styles.DropdownItem)}
            >
              Powerups
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {["IND", "USDT", "USDC", "DAI"].includes(depositType) && (
          <div className={classNames(styles.inputContainer)}>
            <input
              className={classNames(styles.inputStyles)}
              type="text"
              placeholder="Amount"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
            />
            <button
              className={classNames(styles.customButton)}
              onClick={() => {
                setShowStepper(true);
                depositTokens();
              }}
            >
              Deposit
            </button>
          </div>
        )}
        {depositType === "POWERUPS" && powerups && !showStepper && (
          <DepositPowerups
            powerups={powerups}
            selectedPowerups={selectedPowerups}
            setSelectedPowerups={setSelectedPowerups}
            powerupsSubmitHandler={powerupsSubmitHandler}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
export default DepositModal;
