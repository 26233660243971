import { useEffect, useCallback } from "react";
import { CHAIN_HEX, CHAIN_ID } from "../constant";
// import useWeb3Methods from '../hooks/useWeb3Methods';

export function useAutoSwitchNetwork() {
  const switchNetork = useCallback(async (eth) => {
    try {
      const chainIdHex = await eth.request({ method: "eth_chainId" });
      if (chainIdHex !== CHAIN_HEX[CHAIN_ID])
        await eth.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: CHAIN_HEX[CHAIN_ID] }],
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const { ethereum } = window;
    if (ethereum) {
      switchNetork(ethereum);
    } else {
      console.log("ETHEREUM NOT FOUND");
    }
  }, [switchNetork]);
}
