import { useState } from "react";
import { Collapse, NavbarToggler, Nav, Navbar, NavbarBrand } from "reactstrap";
import { NavLink as Link } from "reactstrap";
import styles from "./header.module.scss";
import { APP_LINKS } from "../../constant";

export const HamburgerMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);
  return (
    <div className={styles.hamMenuContainer}>
      <Navbar className="d-flex justify-content-between" color="faded" light>
        <NavbarBrand href="/" className="mr-auto">
          <div className="d-flex">
            <div className={styles.logo}></div>
            <div>
              <p className={styles.logoTitle}>BlockBots</p>
              <p className={styles.logoSubTitle}>PowerUps</p>
            </div>
          </div>
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse className={styles.mobileCollapse} isOpen={!collapsed} navbar>
          <Nav navbar>
            {APP_LINKS.map((elm, idx) => {
              return (
                <div key={idx} className={styles.mobileNavItem}>
                  {elm.external_link ? (
                    <a href={elm.link} target="_blank" rel="noreferrer">
                      {elm.display}
                    </a>
                  ) : (
                    <Link href={elm.link}>{elm.display}</Link>
                  )}
                </div>
              );
            })}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};
