/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import styles from "./Profile.module.scss";
import classNames from "classnames";
import WithWeb3 from "../../HOC/withWeb3";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileDetails, setProfileDetails } from "../../actions";
import { useWeb3React } from "@web3-react/core";
import { profileSelector } from "../../reducers/profileReducer";
import axios from "axios";
import { getToken } from "../../auth";
import { API_BASE_URL, REFERRAL_PROGRAM_ENABLED } from "../../constant";

const Profile = () => {
  const { account, active } = useWeb3React();
  const dispatch = useDispatch();
  const profile = useSelector(profileSelector);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingProfilePic, setIsEditingProfilePic] = useState(false);
  const [userName, setUserName] = useState(null);
  const [email, setEmail] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [saveProfileLoading, setSaveProfileLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("Profile");
  // temorary state variables for profile picture upload
  const [profilePicUploading, setProfilePicUploading] = useState(false);
  const [profilePicId, setProfilePicId] = useState(null);
  //backend source of truth for the imagePath of profile
  const [imagePath, setImagePath] = useState(null);

  useEffect(() => {
    const getProfileDetails = async () => {
      const token = await getToken();
      if (account && active && token && !isEditingProfilePic && !isEditing) {
        dispatch(fetchProfileDetails(account));
      }
    };
    getProfileDetails();
  }, [account, active, dispatch, isEditingProfilePic, isEditing]);

  useEffect(() => {
    if (profile.user.userName) {
      setUserName(profile.user.userName);
    }
    if (profile.user.email) {
      setEmail(profile.user.email);
    }

    if (profile?.user?.profilePic?.imagePath) {
      setImagePath(profile?.user?.profilePic?.imagePath);
    }
  }, [profile.user]);

  const saveProfile = async () => {
    setSaveProfileLoading(true);
    try {
      await dispatch(
        setProfileDetails({
          email,
          userName,
          playerEthAddress: profile.user.playerEthAddress,
        })
      );
    } catch (error) {
      console.log(error);
    }
    setSaveProfileLoading(false);
    setIsEditing(false);
  };

  const onProfilePicFileChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  const onProfilePicFileUpload = async () => {
    try {
      setProfilePicUploading(true);
      const token = await getToken();
      const formData = new FormData();
      formData.append("file", avatar, avatar.name);

      const response = await axios.post(
        `${API_BASE_URL}/file/upload`,
        formData,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data._id) {
        setProfilePicId(response.data._id);
        setProfilePicUploading(false);
        await dispatch(
          setProfileDetails({
            userName: profile.user.userName,
            email: profile.user.email,
            playerEthAddress: profile.user.playerEthAddress,
            profilePic: response.data._id,
          })
        );
      }
      setIsEditingProfilePic(false);
      setAvatar(null);
    } catch (error) {
      console.log(error);
      setProfilePicUploading(false);
      setIsEditingProfilePic(false);
      setAvatar(null);
    }
  };
  return (
    <div className={classNames(styles.profilePageContainer)}>
      <div
        className={classNames(styles.profilePageContentWrapper, "container")}
      >
        <div className={classNames(styles.titleContainer)}>
          <div className={classNames(styles.profilePageTabTitlesContainer)}>
            <p
              className={classNames(
                currentTab === "Profile"
                  ? styles.selectedProfilePageTab
                  : styles.profilePageTab
              )}
              onClick={() => setCurrentTab("Profile")}
            >
              Profile
            </p>
            {REFERRAL_PROGRAM_ENABLED && (
              <p
                className={classNames(
                  currentTab === "Referral"
                    ? styles.selectedProfilePageTab
                    : styles.profilePageTab
                )}
                onClick={() => setCurrentTab("Referral")}
              >
                Referral
              </p>
            )}
          </div>
          {currentTab !== "Profile" ? null : saveProfileLoading ? (
            <div className="loader">
              <div
                className="spinner-border"
                role="status"
                style={{ color: "mediumpurple" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : isEditing ? (
            <div className={classNames(styles.saveProfileButtonsContainer)}>
              <button
                onClick={saveProfile}
                className={classNames(styles.saveChangesButton)}
              >
                Save Changes
              </button>
              <button
                onClick={() => setIsEditing(false)}
                className={classNames(styles.cancelChangesButton)}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={() => setIsEditing(true)}
                className={classNames(styles.primaryButtonStyles)}
              >
                Edit Profile
              </button>
            </div>
          )}
        </div>
        {currentTab === "Profile" && (
          <div className={classNames(styles.bodyContainer)}>
            <div className={classNames(styles.avatarContainer)}>
              {isEditingProfilePic ? (
                <div className={classNames(styles.previewImageContainer)}>
                  {avatar && (
                    <div>
                      <img
                        src={URL.createObjectURL(avatar)}
                        alt=""
                        className={classNames(styles.previewImage)}
                      />
                    </div>
                  )}
                  {!avatar && (
                    <label
                      for="file-upload"
                      className={classNames(styles.customFileUpload)}
                    >
                      Click here to select your picture
                      <input
                        type="file"
                        onChange={onProfilePicFileChange}
                        id="file-upload"
                      />
                    </label>
                  )}
                  {profilePicUploading ? (
                    <div className="loader">
                      <div
                        className="spinner-border"
                        role="status"
                        style={{ color: "mediumpurple" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <button
                      className={classNames(styles.editAvatarButton)}
                      onClick={onProfilePicFileUpload}
                    >
                      Upload Photo
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex flex-column">
                  {profile?.user?.profilePic ? (
                    <div className={classNames(styles.avatarImageContainer)}>
                      <img
                        src={profile?.user?.profilePic.imagePath}
                        alt=""
                        className={classNames(styles.avatarImage)}
                      />
                    </div>
                  ) : (
                    <div className={classNames(styles.defaultAvatarContainer)}>
                      <img
                        src={"../images/BB_Head.png"}
                        className={styles.defaultAvatarImage}
                        alt=""
                      />
                    </div>
                  )}
                  <button
                    className={classNames(styles.editAvatarButton)}
                    onClick={() => setIsEditingProfilePic(true)}
                  >
                    Edit Photo
                  </button>
                </div>
              )}
            </div>
            <div className={classNames(styles.contentContainer)}>
              <div className={classNames(styles.contentInnerContainer)}>
                <div className={classNames("d-flex justify-content-between")}>
                  <div className={classNames(styles.fieldContainer)}>
                    <p className={classNames(styles.fieldLabel)}>Username</p>
                    {isEditing ? (
                      <input
                        type="text"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        className={classNames(styles.textInputStyles)}
                      />
                    ) : (
                      <p className={classNames(styles.fieldValue)}>
                        {userName}
                      </p>
                    )}
                  </div>
                  <div className={classNames(styles.fieldContainer)}>
                    <p className={classNames(styles.fieldLabel)}>Email</p>
                    {isEditing ? (
                      <input
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={classNames(styles.textInputStyles)}
                      />
                    ) : (
                      <p className={classNames(styles.fieldValue)}>
                        {email ? email : "—"}
                      </p>
                    )}
                  </div>
                </div>
                <div className={classNames(styles.fieldContainer)}>
                  <p className={classNames(styles.fieldLabel)}>ETH Address</p>
                  <p className={classNames(styles.fieldValue)}>
                    {profile?.user?.playerEthAddress}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentTab === "Referral" && (
          <div>
            <div className={classNames(styles.referralBannerContainer)}>
              <p className={classNames(styles.referralBannerTitle)}>
                Refer a friend and earn
              </p>
              <p className={classNames(styles.referralBannerDescription)}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut
                consequat nec quam non posuere. Duis eget fermentum magna, et
                lobortis odio.
              </p>
              <button>Learn More</button>
            </div>
            <div>
              <div>
                <p>Share your referral link</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WithWeb3(Profile);
