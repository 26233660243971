import { configureStore } from "@reduxjs/toolkit";
import { wizardReducer } from "../reducers/wizardReducer";
import { fightReducer } from "../reducers/fightReducer";
import { profileReducer } from "../reducers/profileReducer";

export const store = configureStore({
  reducer: {
    wizard: wizardReducer,
    fight: fightReducer,
    profile: profileReducer,
  },
});
