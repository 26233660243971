import jwt_decode from "jwt-decode";
import { Web3Provider } from "@ethersproject/providers";
import axios from "axios";
import { API_BASE_URL } from "../constant";

export const signMessage = async () => {
  try {
    const provider = new Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const AUTH_MESSAGE = `Welcome to the Blockbouts Game!
    
    In order to sign in to our DAPP, just click "Sign".
    
    You will not be charged any fee for signing this message.`;
    const signature = await signer.signMessage(AUTH_MESSAGE);
    const address = await signer.getAddress();
    return { message: AUTH_MESSAGE, signature, address };
  } catch (error) {
    console.log(error);
  }
};

export const checkifValidToken = (token) => {
  if (token) {
    const { exp } = jwt_decode(token);
    const currentDate = new Date();
    const expired = currentDate.getTime() > exp * 1000;
    if (!expired) return true;
  }
  return false;
};

export const login = async (message, address, signature) => {
  return await axios
    .post(`${API_BASE_URL}/user/login`, {
      message,
      ethAddress: address,
      signature,
    })
    .then((response) => {
      if (response?.data?.token) {
        window.localStorage.setItem("token", response?.data?.token);
        return response?.data?.token;
      }
    });
};

export const getToken = async () => {
  const token = window.localStorage.getItem("token");
  const isValidToken = checkifValidToken(token);

  if (!isValidToken) {
    const { message, address, signature } = await signMessage();
    return await login(message, address, signature);
  }
  return token;
};
