import useContract from "./useContract";
import { spenderWallet as OlderABI } from "../abi/spenderWallet";
import { multiTokenSpenderWallet as ABI } from "../abi/multiTokenSpenderWallet";
import {
  DAI_TOKEN_ADDRESS,
  IND_TOKEN_ADDRESS,
  SPENDING_WALLET_ADDRESS,
  OLD_SPENDING_WALLET_ADDRESS,
  USDC_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
  isPROD,
} from "../constant";
import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import web3 from "web3";
import apiProvider from "../providers/api";
import axios from "axios";
import { convertAmountToLowerDenomBasedOnDecimal } from "../utils";

export function useSpenderWalletContract() {
  const contract = useContract(SPENDING_WALLET_ADDRESS, ABI.abi);
  const olderSpenderWallet = useContract(
    OLD_SPENDING_WALLET_ADDRESS,
    OlderABI.abi
  );
  const { account } = useWeb3React();

  const tokenDecimalsValue = useCallback((name) => {
    switch (name) {
      case "IND":
        return [18, IND_TOKEN_ADDRESS];
      case "USDT":
        return [6, USDT_TOKEN_ADDRESS];
      case "USDC":
        return [6, USDC_TOKEN_ADDRESS];
      case "DAI":
        return [18, DAI_TOKEN_ADDRESS];
      default:
        return [18, IND_TOKEN_ADDRESS];
    }
  }, []);

  const getGasFee = async () => {
    let res = await axios.get(
      isPROD
        ? "https://gasstation-mainnet.matic.network/"
        : "https://gasstation-mainnet.matic.network/"
    );
    // returns
    return isPROD ? res.data.fast : res.data.fast;
  };

  const depositWalletTokens = useCallback(
    async (amountToBePooled, tokenName = "IND") => {
      // try {
      if (!account) return;
      const amount = convertAmountToLowerDenomBasedOnDecimal(
        amountToBePooled,
        tokenDecimalsValue(tokenName)[0]
      );

      const amountString = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      const data = await getGasFee();
      const gasPrice = web3.utils.toWei(data.toFixed(2), "gwei");
      let trx = await contract.depositTokens(
        tokenDecimalsValue(tokenName)[1],
        amountString,
        {
          gasLimit: "300000",
          gasPrice: gasPrice,
        }
      );
      await trx.wait();
      // } catch (err) {
      //   console.log(err);
      //   return err;
      // }
    },
    [account, tokenDecimalsValue, contract]
  );

  const depositOldTokenIND = useCallback(async (amountToBePooled) => {
    try {
      if (!account) return;
      const amount = convertAmountToLowerDenomBasedOnDecimal(
        amountToBePooled,
        18
      );
      const amountString = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      const data = await getGasFee();
      const gasPrice = web3.utils.toWei(data.toFixed(2), "gwei");
      let trx = await olderSpenderWallet.depositIND(amountString, {
        gasLimit: "100000",
        gasPrice: gasPrice,
      });
      await trx.wait();
    } catch (err) {
      console.log(err);
      return err;
    }
  });

  const depositOldTokenPowerups = useCallback(
    async (tokenIds, quantities) => {
      let trx = await olderSpenderWallet.depositPowerUps(tokenIds, quantities);
      await trx.wait();
    },
    [olderSpenderWallet]
  );

  const balanceOfIND = useCallback(async () => {
    return await contract.balanceOfIND(account);
  }, [account, contract]);

  const depositPowerUps = useCallback(
    async (tokenIds, quantities) => {
      let trx = await contract.depositPowerUps(tokenIds, quantities);
      await trx.wait();
    },
    [contract]
  );

  const withdrawWalletTokens = useCallback(
    async (amountToBeUnlocked, tokenName = "IND") => {
      if (!account) return;
      const amount = convertAmountToLowerDenomBasedOnDecimal(
        amountToBeUnlocked,
        tokenDecimalsValue(tokenName)[0]
      );
      const gasData = await getGasFee();
      const gasPrice = web3.utils.toWei(gasData.toFixed(2), "gwei");
      const amountString = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      const { data, status } = await apiProvider.getMessageSignatureHash(
        account
      );
      if (status === 400) {
        throw new Error(data.message);
      }
      const trx = await contract.unlockTokens(
        tokenDecimalsValue(tokenName)[1],
        amountString,
        data.messageHash,
        data.signature,
        {
          gasLimit: "300000",
          gasPrice: gasPrice,
        }
      );

      await trx.wait();
    },
    [account, tokenDecimalsValue, contract]
  );

  const withdrawOldIND = useCallback(async (amountToBeUnlocked) => {
    const amount = convertAmountToLowerDenomBasedOnDecimal(
      amountToBeUnlocked,
      18
    );
    const amountString = Number(amount).toLocaleString("fullwide", {
      useGrouping: false,
    });
    const gasData = await getGasFee();
    const gasPrice = web3.utils.toWei(gasData.toFixed(2), "gwei");
    const { data, status } = await apiProvider.getMessageSignatureHash(account);

    const trx = await olderSpenderWallet.unlockInd(
      amountString,
      data.messageHash,
      data.signature,
      {
        gasLimit: "300000",
        gasPrice: gasPrice,
      }
    );

    await trx.wait();
  }, []);

  const withdrawPowerups = useCallback(
    async (ids, amounts) => {
      if (!account) return;
      const { data, status } = await apiProvider.getMessageSignatureHash(
        account
      );
      if (status === 400) {
        throw new Error(data.message);
      }
      const gasData = await getGasFee();
      const gasPrice = web3.utils.toWei(gasData.toFixed(2), "gwei");

      const trx = await contract.unlockPowerUp(
        ids,
        amounts,
        data.messageHash,
        data.signature,
        {
          gasLimit: "300000",
          gasPrice: gasPrice,
        }
      );

      await trx.wait();
    },
    [contract, account]
  );

  const withdrawOldPowerups = useCallback(
    async (ids, amounts) => {
      if (!account) return;
      const { data, status } = await apiProvider.getMessageSignatureHash(
        account
      );
      if (status === 400) {
        throw new Error(data.message);
      }
      const gasData = await getGasFee();
      const gasPrice = web3.utils.toWei(gasData.toFixed(2), "gwei");

      const trx = await olderSpenderWallet.unlockPowerUp(
        ids,
        amounts,
        data.messageHash,
        data.signature,
        {
          gasLimit: "300000",
          gasPrice: gasPrice,
        }
      );

      await trx.wait();
    },
    [account, olderSpenderWallet]
  );

  const getRewardsByAddressAndGameId = useCallback(async () => {
    return contract
      .getRewardsByAddressAndGameId
      // address _address,
      // string memory _gameId
      ();
  }, [contract]);

  return useMemo(() => {
    return {
      depositWalletTokens,
      balanceOfIND,
      depositPowerUps,
      withdrawWalletTokens,
      withdrawPowerups,
      getRewardsByAddressAndGameId,
      withdrawOldIND,
      withdrawOldPowerups,
      depositOldTokenIND,
      depositOldTokenPowerups,
    };
  }, [
    balanceOfIND,
    depositWalletTokens,
    depositPowerUps,
    getRewardsByAddressAndGameId,
    withdrawPowerups,
    withdrawWalletTokens,
    withdrawOldIND,
    withdrawOldPowerups,
    depositOldTokenIND,
    depositOldTokenPowerups,
  ]);
}
