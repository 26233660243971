import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import styles from "./SelectPowerups.module.scss";
import { Button } from "reactstrap";
import {
  MAX_POWERUP_SELECTIONS,
  SELECT_ACTIONS,
  SELECT_BLOCKBOT,
  FIGHT_TYPES,
} from "../../../constant";
import { DraggablePowerupDetailsCard } from "../../../components/DND/draggablePowerupDetailCards";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  setSelectedPowerUps,
  setCurrentState,
  resetSelectedBB,
  resetSelectedPowerups,
} from "../../../actions";
import { showToastErrorMessage } from "../../../utils/web3Helpers";
import { wizardSelector } from "../../../reducers/wizardReducer";
import BlockbotCard from "../../../components/BlockbotCard";
import apiProvider from "../../../providers/api";
import { LoadLoadout, LoadoutModal } from "../Loadout";
import { toast } from "react-toastify";
import api from "../../../providers/api";

const SelectPowerups = ({ account }) => {
  const dispatch = useDispatch();
  const [openLoadoutModal, toggleLoadoutModal] = useState(false);
  const [savedLoadouts, setSavedLoadouts] = useState(null);
  const [selectedLoadout, setSelectedLoadout] = useState(null);
  const [loadoutErrorMessage, setLoadoutErrorMessage] = useState("");
  const [selectedLoadoutId, setSelectedLoadoutId] = useState("");
  const {
    powerups: { isLoading, data: powerupsData, loadoutEnabled },
    selectedPowerups,
    selectedBlockbot,
  } = useSelector(wizardSelector);
  const params = new URLSearchParams(window.location.search);
  const fightType = Object.fromEntries(params.entries()).fightType;
  const [basePUs, setBasePUS] = useState([]);
  const [externalPUs, setExternalPUs] = useState([]);
  const { tokenId } = useMemo(() => {
    const tokenId = selectedBlockbot?.tokenId || null;
    return {
      tokenId,
    };
  }, [selectedBlockbot?.tokenId]);

  const getBasePowerUPS = useCallback(async () => {
    if (tokenId) {
      const { basePowerUps } = await apiProvider.getBasePowerOfBB(tokenId);
      const modifiedBasePowerUps = basePowerUps.map((powerup) => ({
        ...powerup,
        equipType: "Base",
      }));
      setBasePUS(modifiedBasePowerUps);
    }
  }, [tokenId]);

  const getExternalPowerups = useCallback(async () => {
    if (powerupsData.length) {
      const modifiedPowerupsData = powerupsData.map((powerup) => ({
        ...powerup,
        equipType: "External",
      }));
      setExternalPUs(modifiedPowerupsData);
    }
  }, [powerupsData]);

  const getSavedLoadouts = useCallback(async () => {
    if (loadoutEnabled) {
      const loadoutsData = await apiProvider.getPowerupsLoadout(account);
      const loadouts = loadoutsData?.loadouts || null;
      setSavedLoadouts(loadouts);
    }
  }, [account, loadoutEnabled]);

  useEffect(() => {
    getBasePowerUPS();
    getExternalPowerups();
    getSavedLoadouts();
  }, [fightType, getBasePowerUPS, getExternalPowerups, getSavedLoadouts]);

  const onDrop = ({ item }) => {
    let newSelectedPUs = [...selectedPowerups];
    newSelectedPUs.push(item);
    dispatch(setSelectedPowerUps(newSelectedPUs));
  };

  const onRemove = (item) => {
    const filteredPUArr = selectedPowerups.filter(
      (elm) => elm.tokenId !== item.tokenId
    );
    dispatch(setSelectedPowerUps(filteredPUArr));
  };

  const showPowerList = () => {
    const selectTypes = [];
    const selectedTokenIds = selectedPowerups.map((elm) => {
      selectTypes.push(
        elm.attributes.filter((el) => el.trait_type === "Type")[0]?.value ||
          "BASE"
      );
      return elm ? elm.tokenId : null;
    });
    const allPUS = [...externalPUs, ...basePUs];
    return allPUS.map((item, index) => (
      <DraggablePowerupDetailsCard
        key={index}
        {...item}
        id={index}
        selectedTokenIds={selectedTokenIds}
        activeTypes={selectTypes}
        onClick={() => onDrop({ item })}
        onRemove={() => onRemove(item)}
      />
    ));
  };

  const onNextClick = async () => {
    try {
      if (selectedPowerups.filter(Boolean).length < 1)
        throw new Error("Atleast one powerup needs to be selected");
      if (selectedLoadout) {
        if (
          selectedLoadout &&
          !savedLoadouts.find((item) => item["_id"] === selectedLoadoutId)
        ) {
          dispatch(setSelectedPowerUps([]));
          throw new Error("Invalid loadout, please select again!");
        } else {
          await apiProvider.updatePUFrequency(selectedLoadoutId, account);
        }
      }
      dispatch(setCurrentState(SELECT_ACTIONS));
    } catch (err) {
      console.log(err);
      showToastErrorMessage(err);
    }
  };

  const onBackClick = () => {
    batch(() => {
      dispatch(resetSelectedBB());
      dispatch(resetSelectedPowerups());
      dispatch(setCurrentState(SELECT_BLOCKBOT));
    });
  };

  const onSaveLoadout = async (loadoutName) => {
    if (!loadoutName) {
      setLoadoutErrorMessage("Please set a name for your loadout");
      return;
    }
    try {
      setLoadoutErrorMessage("");
      const powerups = selectedPowerups
        .map((elm) => elm.tokenId < 10000 && elm.tokenId)
        .filter(Boolean);
      const reqBody = {
        name: loadoutName,
        powerups,
        playerEthAddress: account,
      };
      if (selectedLoadout) reqBody._id = selectedLoadout._id;
      const res = await apiProvider.savePowerupsLoadout(reqBody);
      if (res.status === 400) {
        const error = JSON.parse(res.data);
        console.log(error);
        setLoadoutErrorMessage(error?.errors[0].msg);
        throw new Error();
      }
      await getSavedLoadouts();
      toggleLoadoutModal(false);
      toast.success("Loadout Saved", { toastId: "SAVE_LOADOUT" });
    } catch (err) {
      console.log(err);
      toast.error("Cannot Save Loadout", { toastId: "FAIL_SAVE" });
    }
  };

  const onLoadoutSelect = (loadout) => {
    if (loadout) {
      const { powerups } = loadout;
      const filteredPUs = powerupsData.filter(
        (elm) => powerups.indexOf(elm.tokenId) > -1
      );
      setSelectedLoadoutId(loadout["_id"]);
      dispatch(setSelectedPowerUps(filteredPUs));
    } else dispatch(setSelectedPowerUps([]));
    setSelectedLoadout(loadout);
  };

  // ARENA MATCHES NEED A MINIMUM OF 2 SELECTED POWERUPS, THE OTHER MATCHES NEED A MINIMUM OF 1
  // ARENA MATCHES NEED TO HAVE A MINIMUM OF ONE EXTERNAL POWERUP
  const numberOfExternalPowerupsSelected = selectedPowerups.filter(
    (powerup) => powerup.equipType === "External"
  ).length;
  const isReady =
    fightType === FIGHT_TYPES.ARENA
      ? selectedPowerups.length > 1 && numberOfExternalPowerupsSelected > 0
      : selectedPowerups.length > 0;

  if (isLoading)
    return (
      <div className="loader">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "mediumpurple" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );

  const remaningSelectionsNum =
    MAX_POWERUP_SELECTIONS - selectedPowerups.length;

  return (
    <>
      <div className="wizard-heading-container">
        <div className="step-container">
          <div className="step-current">STEP 2</div>
          <div className="step-desc">
            {remaningSelectionsNum > 0
              ? `You can choose up to ${remaningSelectionsNum} Powerups`
              : "You have selected Max Powerups"}
          </div>
        </div>
        <div className={styles.navBtnCont}>
          <div className={styles.LoadoutButtonsContainer}>
            {savedLoadouts &&
              savedLoadouts.slice(0, 3).map((item, index) => (
                <Button
                  key={index}
                  className={classNames("outline-btn")}
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    onLoadoutSelect(item);
                    setSelectedLoadout(item);
                  }}
                >
                  {item.name.length <= 6
                    ? item.name
                    : `${item.name.substring(0, 4)}...
                  ${item.name.substring(item.name.length - 1)}`}
                </Button>
              ))}
          </div>
          <LoadLoadout
            menuHeader="Powerups Loadout"
            selectedLoadout={selectedLoadout}
            onSelect={onLoadoutSelect}
            menuItems={savedLoadouts}
            loadoutType="POWERUP"
            getSavedLoadouts={getSavedLoadouts}
            setSelectedLoadout={setSelectedLoadout}
          />
          {loadoutEnabled && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className={classNames(styles.VerticalDiv)}></div>
              <Button
                onClick={() => toggleLoadoutModal(true)}
                className={classNames("white-btn", "round-btn")}
                style={{
                  height: "40px",
                }}
              >
                <img
                  src="../../../../images/save-icon.png"
                  alt="link-img"
                  width={18}
                />
              </Button>
            </div>
          )}
          <button onClick={onBackClick} className="arrow-btn-styles">
            <img
              src="../../../../icons/left-arrow.svg"
              alt="right-arrow"
              height={40}
              width={40}
            />
          </button>
          <button
            onClick={onNextClick}
            disabled={!isReady}
            className="arrow-btn-styles"
            style={{
              opacity: isReady ? 1 : 0.5,
              marginLeft: "-10px",
            }}
          >
            <img
              src="../../../../icons/right-arrow.svg"
              alt="right-arrow"
              height={40}
              width={40}
            />
          </button>
        </div>
      </div>
      <div className={styles.selectedPUContainer}>
        <BlockbotCard
          item={selectedBlockbot}
          selectedPowerups={selectedPowerups}
        />
        <div className={classNames(styles.selectPowerupsContainer)}>
          <div className={styles.powerupGrid}>{showPowerList()}</div>
        </div>
      </div>
      {openLoadoutModal && (
        <LoadoutModal
          onClose={() => {
            toggleLoadoutModal(false);
            setLoadoutErrorMessage("");
          }}
          isOpen={openLoadoutModal}
          onSave={onSaveLoadout}
          selectedLoadout={selectedLoadout}
          modalTitle="Save Powerups Loadout"
          errorMessage={loadoutErrorMessage}
        />
      )}
    </>
  );
};

export default SelectPowerups;
