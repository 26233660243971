/* eslint-disable no-empty-pattern */
import React from "react";
import { useDrag } from "react-dnd";
import { MAX_ACTION_SELECTIONS } from "../../../constant";
import ActionCard from "../../ActionCard";

const ACTION_LIMIT = {
  ATTACK: 3,
  "FAST ATTACK": 3,
  BLOCK: 3,
  EVADE: 3,
  BOOST: 1,
};

const MAX_OFFENSIVE_ACTIONS = 3;

export const DraggableActionCard = (props) => {
  const { type, id, index, selectedActions, subtitle, actionPowers, ...rest } =
    props;
  const filteredActions = selectedActions.filter(Boolean);

  const individualActionLimitCriteria =
    filteredActions.filter((elm) => elm && elm.action === props.subtitle)
      .length !== ACTION_LIMIT[props.subtitle];

  const OFFENSIVE_ACTIONS_LENGTH = filteredActions.filter(
    (elm) => elm && (elm.action === "ATTACK" || elm.action === "FAST ATTACK")
  ).length;
  const canAdd = individualActionLimitCriteria;

  const [{}, drag] = useDrag(() => ({
    type,
    item: { id, index },
    canDrag: canAdd,
  }));

  return (
    <div ref={canAdd ? drag : null}>
      <ActionCard
        disableAdd={
          filteredActions.length === MAX_ACTION_SELECTIONS ||
          ((subtitle === "ATTACK" || subtitle === "FAST ATTACK") &&
            OFFENSIVE_ACTIONS_LENGTH >= MAX_OFFENSIVE_ACTIONS)
        }
        {...rest}
        id={id}
        index={index}
        atLimit={!canAdd}
        subtitle={subtitle}
        actionPowers={actionPowers}
      />
    </div>
  );
};
