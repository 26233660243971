import React, { Suspense, useMemo } from "react";
// import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import Ring from "./Ring";
import classNames from "classnames";
import styles from "./Fight.module.scss";
import { AnimatedBotLayer } from "./AnimatedBotLayer";
import { Loader } from "./Loader";

export const Scene = (props) => {
  const {
    selectedBlockbot,
    opponentBlockbot,
    arena,
    setLoadingComplete,
    rounds,
    loading,
  } = props;
  // console.log(arena, loading);

  const memoizedRing = useMemo(() => {
    if (loading) return null;
    return <Ring arena={arena} />;
  }, [arena, loading]);
  const memoizedBots = useMemo(
    () => (
      <AnimatedBotLayer
        selectedBlockbot={selectedBlockbot}
        opponentBlockbot={opponentBlockbot}
        setLoadingComplete={setLoadingComplete}
        rounds={rounds}
        arena={arena}
      />
    ),
    [arena, opponentBlockbot, rounds, selectedBlockbot, setLoadingComplete]
  );

  return (
    <Canvas
      sRGB
      dpr={[1, 2]}
      camera={{
        fov: 10,
        position: [0, 20, 85],
        aspect: window.innerWidth / window.innerHeight,
      }}
      shadows
      className={classNames(styles.threeCanvas)}
      invalidateFrameloop
      gl={{
        powerPreference: "high-performance",
      }}
    >
      <ambientLight intensity={0.1} />
      <directionalLight intensity={0.1} />

      <spotLight
        color="#FFD3AC"
        position={[0, 20, 15]}
        intensity={5}
        angle={0.3}
        distance={150}
        penumbra={1}
        decay={2}
        castShadow
        shadow-mapSize-height={1024}
        shadow-mapSize-width={1024}
      />
      <Suspense fallback={<Loader />}>
        {memoizedRing}
        {memoizedBots}
      </Suspense>
      {/* <OrbitControls enablePan={true} enableZoom={true} enableRotate={true} /> */}
    </Canvas>
  );
};
