import { useEffect, useRef, useMemo } from "react";
import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { NearestFilter, RepeatWrapping } from "three";
import { getFrames } from "../utils";

export const useSpriteAnimator = (
  src,
  frameDuration,
  rows,
  columns,
  isPaused = false,
  spriteJSON
) => {
  const texture = useTexture(src);
  const tex = useMemo(() => {
    const tex = texture.clone();
    tex.wrapS = RepeatWrapping;
    tex.wrapT = RepeatWrapping;
    tex.minFilter = NearestFilter;
    tex.magFilter = NearestFilter;
    tex.needsUpdate = true;

    return tex;
  }, [texture]);

  const t = useRef(0);
  const index = useRef(0);
  const totalFrames = rows * columns;
  const w = spriteJSON.meta.spriteWidth;
  const h = spriteJSON.meta.spriteHeight;
  const frames = useRef([]);

  useEffect(() => {
    t.current = 0;
    index.current = 0;
    frames.current = getFrames(spriteJSON);
  }, [spriteJSON]);

  //   console.log(frames.current);

  useFrame((_, delta) => {
    const currentFrame = frames.current[index.current];
    // console.log(f);
    if (!currentFrame) return;
    tex.repeat.set(currentFrame.width / w, currentFrame.height / h);

    if (!isPaused) {
      t.current += delta * 1000;
      if (t.current >= frameDuration) {
        index.current += 1;
        if (index.current >= totalFrames) {
          index.current = 0;
        }
        t.current = 0;
      }
    }

    // split index into x and y components
    tex.offset.x = currentFrame.x / w;
    tex.offset.y = currentFrame.y / h;
  });
  return [tex, index, t];
};
