import { useProgress, Html } from "@react-three/drei";

export const Loader = () => {
  const { progress } = useProgress();
  return (
    <Html className="scene-loader" center>
      {parseInt(progress, 10)} % Loaded
    </Html>
  );
};
