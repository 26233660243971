import { createReducer, createSelector } from "@reduxjs/toolkit";
import {
  fetchBlockbotDetails,
  fetchPowerupDetails,
  fetchActionDetails,
  selectBlockbot,
  setCurrentState,
  setSelectedPowerUps,
  setSelectedActions,
  updatePowerUps,
  resetWizard,
  fetchBasePowerups,
  resetSelectedPowerups,
  resetSelectedBB,
  resetSelectedActions,
} from "../actions";
import {
  MAX_ACTION_SELECTIONS,
  SELECT_BLOCKBOT,
  SELECT_POWERUPS,
  SELECT_ACTIONS,
} from "../constant";

const initialState = {
  blockbots: {
    isLoading: true,
    data: [],
    isError: "",
    total: 0,
  },
  powerups: {
    isLoading: false,
    data: [],
    isError: "",
    loadoutEnabled: false,
  },
  actions: {
    isLoading: false,
    data: [],
    isError: "",
  },
  isOwner: false,
  selectedBlockbot: {},
  selectedActions: new Array(MAX_ACTION_SELECTIONS).fill(null),
  selectedPowerups: [],
  currentState: SELECT_BLOCKBOT,
  hero: {
    title: "Choose a Blockbot to continue",
    label: "STEP 1",
  },
};

// The Wizard will have four states that flow in one direction
// Any return to a previous state clears selection data for all subsequent states
// Changes in metamask wallet will also clear all the user selections

// SELECT_GAME_TYPE
// SELECT_BLOCKBOT
// SELECT_POWERUPS
// SELECT_ACTIONS

export const wizardReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchBlockbotDetails.fulfilled, (state, action) => {
    state.blockbots.isLoading = false;
    state.blockbots.data = action.payload.blockbots;
    state.blockbots.total = action.payload.total;
    state.isOwner = action.payload.isOwner;
  });
  builder.addCase(fetchBlockbotDetails.pending, (state, action) => {
    state.blockbots.isLoading = true;
  });
  builder.addCase(fetchBlockbotDetails.rejected, (state, action) => {
    state.blockbots.isLoading = false;
  });
  builder.addCase(fetchPowerupDetails.fulfilled, (state, action) => {
    state.powerups.isLoading = false;
    state.powerups.data = action.payload.powerups;
    state.powerups.loadoutEnabled = action.payload.loadoutEnabled;
    state.powerups.isError = "";
  });
  builder.addCase(fetchPowerupDetails.pending, (state) => {
    state.powerups.isLoading = true;
    state.powerups.data = [];
    state.powerups.isError = "";
  });
  builder.addCase(fetchPowerupDetails.rejected, (state, action) => {
    state.powerups.isLoading = false;
    state.powerups.data = [];
    state.powerups.isError = action.error.message;
  });
  builder.addCase(fetchActionDetails.pending, (state) => {
    state.actions.data = [];
    state.actions.isError = "";
    state.actions.isLoading = true;
  });
  builder.addCase(fetchActionDetails.fulfilled, (state, action) => {
    state.actions.data = action.payload.actions;
    state.actions.isError = "";
    state.actions.isLoading = false;
  });
  builder.addCase(fetchActionDetails.rejected, (state, action) => {
    state.actions.data = [];
    state.actions.isError = action.error.message;
    state.actions.isLoading = false;
  });
  builder.addCase(fetchBasePowerups.fulfilled, (state, action) => {
    state.powerups.data = action.payload.powerups;
  });
  builder.addCase(selectBlockbot, (state, action) => {
    state.selectedBlockbot = action.payload.blockbot;
  });
  builder.addCase(updatePowerUps, (state, action) => {
    state.powerups.data = action.payload.powerups;
  });
  builder.addCase(setSelectedPowerUps, (state, action) => {
    state.selectedPowerups = action.payload.powerups;
  });
  builder.addCase(setCurrentState, (state, action) => {
    state.currentState = action.payload.state;
    if (action.payload.state === SELECT_BLOCKBOT) {
      state.hero = {
        title: "Choose a Blockbot to continue",
        label: "STEP 1",
      };
    }
    if (action.payload.state === SELECT_POWERUPS) {
      state.hero = {
        title: "You can choose upto 5 PowerUps",
        label: "STEP 2",
      };
    }
    if (action.payload.state === SELECT_ACTIONS) {
      state.hero = {
        title: "Choose your actions",
        label: "STEP 3",
      };
    }
  });
  builder.addCase(setSelectedActions, (state, actions) => {
    state.selectedActions = actions.payload.actions;
  });
  builder.addCase(resetSelectedPowerups, (state) => {
    state.selectedPowerups = [];
  });
  builder.addCase(resetSelectedBB, (state) => {
    state.selectedBlockbot = {};
  });
  builder.addCase(resetSelectedActions, (state) => {
    state.selectedActions = new Array(MAX_ACTION_SELECTIONS).fill(null);
  });
  builder.addCase(resetWizard, (state) => {
    state.blockbots = {
      isLoading: true,
      data: [],
      isError: "",
    };
    state.powerups = {
      isLoading: false,
      data: [],
      isError: "",
    };
    state.actions = {
      isLoading: false,
      data: [],
      isError: "",
    };
    state.hero = {
      title: "Choose a Blockbot to continue",
      label: "STEP 1",
    };
    state.selectedBlockbot = {};
    state.selectedActions = new Array(MAX_ACTION_SELECTIONS).fill(null);
    state.selectedPowerups = [];
    state.currentState = SELECT_BLOCKBOT;
  });
  builder.addDefaultCase((state, action) => {});
});

export const wizardSelector = createSelector(
  (state) => state.wizard,
  (state) => state
);
