import React from "react";
import { Button } from "reactstrap";
import classnames from "classnames";
import styles from "./ButtonPurplePrimary.module.scss";

export const ButtonPurplePrimary = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button
      className={classnames("px-5", "py-1", styles.purplePrimary, className)}
      {...rest}
    >
      {children}
    </Button>
  );
};
