import { useEffect, useState, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useEagerConnect from "../hooks/useEagerConnect";
import { injected } from "../connectors";
import { MetamaskFox } from "../components/metamask";
import { Button } from "reactstrap";
import { CHAIN_NAME } from "../constant";
import { useAutoSwitchNetwork } from "../hooks/useAutoSwitchNetwork";
import { useInactiveListener } from "../hooks/useInactiveListener";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetWizard, resetProfile } from "../actions";

const WithWeb3 = (Component) => {
  const ReallySmartComponent = (props) => {
    const triedEager = useEagerConnect();
    useAutoSwitchNetwork();
    const navigate = useNavigate();
    const { activate, account, connector, library } = useWeb3React();
    const dispatch = useDispatch();

    const connectWallet = useCallback(async () => {
      try {
        await activate(injected, undefined, true);
      } catch (err) {
        console.log(err);
      }
    }, [activate]);

    const onWeb3Update = useCallback(() => {
      navigate("/");
      dispatch(resetWizard());
      dispatch(resetProfile());
    }, [dispatch, navigate]);

    const [activatingConnector, setActivatingConnector] = useState();

    useEffect(() => {
      if (activatingConnector && activatingConnector === connector) {
        setActivatingConnector(undefined);
      }
    }, [activatingConnector, connector]);

    useInactiveListener(!!activatingConnector, onWeb3Update);

    if (!account) {
      return (
        <div className="empty-state">
          <span>
            Please connect your Metamask Wallet <br />
            <span className="sub-text">
              Also make sure that your wallet is connected to{" "}
              <b>{CHAIN_NAME}</b>{" "}
            </span>
          </span>
          <div className="blue-container">
            <MetamaskFox />
          </div>
          <Button onClick={connectWallet} className="default-blue-button">
            Connect
          </Button>
        </div>
      );
    }
    return (
      <Component
        {...props}
        account={account}
        connectWallet={connectWallet}
        loading={!triedEager}
        library={library}
      />
    );
  };
  return ReallySmartComponent;
};

export default WithWeb3;
