import { useEffect, useRef } from "react";

import makeFox from "metamask-logo";

export const MetamaskFox = (props) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef) return;

    const viewer = makeFox({
      pxNotRatio: true,
      width: 300,
      height: 300,
      // To make the face follow the mouse.
      followMouse: true,
      // head should slowly drift (overrides lookAt)
      slowDrift: false,
    });

    viewer.lookAt({ x: 100, y: 100 });
    containerRef.current.appendChild(viewer.container);

    return () => {
      viewer.stopAnimation();
      const { current } = containerRef;
      current && current.removeChild(viewer.container);
    };
  }, []);

  return <div ref={containerRef} />;
};
