import { createAsyncThunk } from "@reduxjs/toolkit";
import apiProvider from "../providers/api";

export const fetchBlockbotLayers = createAsyncThunk(
  "fight/fetchBlockbotLayers",
  async ({ tokenID, type }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const data = await apiProvider.getBlockbotDetails(tokenID);
      return { ...data, type };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);
