import { animated } from "@react-spring/three";
import { useSpriteAnimator } from "../../../../hooks/useSpriteAnimator";
import { useSpring } from "@react-spring/three";
import { degToRad } from "three/src/math/MathUtils";
import { useMemo } from "react";
import spriteImg from "./Attack_2.png";
import spriteJSON from "./spriteFrame.json";
export const AttackSpriteAnimation = (props) => {
  const { onAttackComplete, isRTL, isActive } = props;

  const [tex] = useSpriteAnimator(spriteImg, 100, 1, 12, !isActive, spriteJSON);

  const { START_POSITION, END_POSITION } = useMemo(
    () => ({
      START_POSITION: isRTL ? [2, 4, 0] : [-2, 4, 0],
      END_POSITION: isRTL ? [-2, 4, 0] : [2, 4, 0],
    }),
    [isRTL]
  );
  // const END_POSITION = isRTL ? [-2, 4, 0] : [2, 4, 0];

  const { position, scale } = useSpring({
    to: async (next) => {
      await next({ scale: 2.5, immediate: true });
      await next({ position: END_POSITION });
      await next({ position: START_POSITION, scale: 0, immediate: true });
      onAttackComplete();
    },
    from: { position: START_POSITION, scale: 0 },
    config: { duration: 1300 },
    pause: !isActive,
  });

  if (isRTL) tex.rotation = [degToRad(185)];

  return (
    <animated.mesh scale={scale} position={position}>
      <sprite>
        <spriteMaterial transparent map={tex} />
      </sprite>
    </animated.mesh>
  );
};
