import React from "react";
import { DraggableActionCard } from "../DND/draggableActionCard";
import styles from "./ActionsComponent.module.scss";
import { ACTION_DRAG_TYPE, MAX_ACTION_SELECTIONS } from "../../constant";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DnDActionCard } from "../DND/dndActionCard";
import { FaInfoCircle } from "react-icons/fa";
import { UncontrolledTooltip } from "reactstrap";
import classNames from "classnames";
// import { showToastErrorMessage } from "../../utils";
const ActionComponent = ({
  actions,
  selectedActions,
  setSelectedActions,
  isLoading,
  actionPowers,
}) => {
  const onDrop = ({ item, index }) => {
    let newSelectedActions = [...selectedActions];
    if (Number.isInteger(index) && item) {
      if (item.isInsideDrop) {
        const currentDragItem = newSelectedActions[item.index];
        newSelectedActions[item.index] = newSelectedActions[index];
        newSelectedActions[index] = currentDragItem;
      } else newSelectedActions[index] = actions[item.index];
    } else {
      const firstEmptyIndex = newSelectedActions.indexOf(null);
      newSelectedActions[
        firstEmptyIndex > -1 ? firstEmptyIndex : MAX_ACTION_SELECTIONS - 1
      ] = actions[index];
    }
    setSelectedActions(newSelectedActions);
  };

  const onRemove = (index) => {
    let p = [...selectedActions];
    p[index] = null;
    setSelectedActions(p);
  };

  const renderActions = () => {
    if (isLoading)
      return (
        <div className="loader">
          <div
            className="spinner-border"
            role="status"
            style={{ color: "mediumpurple" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    return actions.map((action, action_index) => (
      <DraggableActionCard
        title={action.name}
        type={ACTION_DRAG_TYPE}
        subtitle={action.action}
        index={action_index}
        id={action._id}
        key={`draggable-${action_index}`}
        onClick={onDrop}
        selectedActions={selectedActions}
        actionPowers={actionPowers}
      />
    ));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.dropboardContainer}>
        <Dropboard
          actions={actions}
          selectedActions={selectedActions}
          setSelectedActions={setSelectedActions}
          onDrop={onDrop}
          onRemove={onRemove}
          actionPowers={actionPowers}
        />
        <div className={styles.actionsWrapper}>
          <div className={styles.actionTitle}>
            Actions{" "}
            <UncontrolledTooltip target={"actions-tooltip"}>
              <div className={classNames(styles.rulesContainer)}>
                <p className={classNames(styles.rulesTitle)}>Rules</p>
                <ol className={classNames(styles.rulesList)}>
                  <li className={classNames(styles.rulesListItem)}>
                    Attack and Fast Attack cannot be used more than 3 times
                    collectively.
                  </li>
                  <li className={classNames(styles.rulesListItem)}>
                    Any action cannot be used more than 3 times.
                  </li>
                  <li className={classNames(styles.rulesListItem)}>
                    Boost can only be used once.
                  </li>
                </ol>
              </div>
            </UncontrolledTooltip>
            <FaInfoCircle id="actions-tooltip" size={14} />{" "}
          </div>
          <div className={styles.actionsContainer}>{renderActions()}</div>
        </div>
      </div>
    </DndProvider>
  );
};

export default ActionComponent;

const Dropboard = ({ selectedActions, onDrop, onRemove, actionPowers }) => {
  return (
    <div className={styles.dropboard}>
      <div className={styles.dropboardTitle}>
        Choose the actions that your BlockBot will perform during the fight
      </div>
      <div className={styles.selectedActionsContainer}>
        {selectedActions.map((action, action_index) => (
          <DnDActionCard
            title={action?.name}
            type={ACTION_DRAG_TYPE}
            subtitle={action?.action}
            index={action_index}
            id={action?._id}
            key={`DND-${action_index}`}
            onClick={onRemove}
            onDrop={onDrop}
            actionPowers={actionPowers}
          />
        ))}
      </div>
    </div>
  );
};
