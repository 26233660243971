import React, { Suspense, useRef } from "react";
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { Loader } from "./Loader";
// Randomized Ring
// function randomIntFromInterval(min, max) {
//   // min and max included
//   return Math.floor(Math.random() * (max - min + 1) + min);
// }

const GameRing = (props) => {
  const { arena } = props;
  //Ring 1: Boxing Ring
  const BoxingRingObj = "/Arenas/BoxingRing/boxing-ring.obj";
  const BoxingRingMtl = "/Arenas/BoxingRing/boxing-ring.mtl";

  //Ring 5: UFC Ring
  const UFCRingObj = "/Arenas/UFCRing/ufc-ring.obj";
  const UFCRingMtl = "/Arenas/UFCRing/ufc-ring.mtl";

  let obj = {};
  let mtl = {};

  switch (arena) {
    case "BOXING":
      obj = BoxingRingObj;
      mtl = BoxingRingMtl;
      break;

    case "UFC":
      obj = UFCRingObj;
      mtl = UFCRingMtl;
      break;

    default:
      obj = BoxingRingObj;
      mtl = BoxingRingMtl;
      break;
  }

  // This reference gives us direct access to the THREE.Mesh object
  const ref = useRef();

  const materials = useLoader(MTLLoader, mtl);
  // const materials = useLoader(MTLLoader, );
  const object = useLoader(OBJLoader, obj, (loader) => {
    // const object = useLoader(OBJLoader, , (loader) => {
    materials.preload();
    loader.setMaterials(materials);
  });

  object.children.forEach((elm) => {
    elm.receiveShadow = true;
  });

  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <Suspense fallback={<Loader />}>
      <instancedMesh
        receiveShadow
        {...props}
        ref={ref}
        position={[-4.8, -10, -2]}
        scale={1}
        // rotation={[0, 3.14 / 2, 0]}
      >
        <primitive object={object} />
      </instancedMesh>
    </Suspense>
  );
};

export default GameRing;

//Select a ring at random

// Random ring
// const ringNumber = randomIntFromInterval(1, 4);
// const ringNumber = 1; //Boxing Ring
// const ringNumber = 2; //Gladiator Ring
// const ringNumber = 3; // Pokemon Ring
// const ringNumber = 4; //Street Ring
// const ringNumber = 5; // UFC Ring

// const obj =
//     ringNumber === 1
//       ? BoxingRingObj
//       : ringNumber === 2
//       ? GladiatorRingObj
//       : ringNumber === 3
//       ? PokemonRingObj
//       : ringNumber === 4
//       ? StreetRingObj
//       : UFCRingObj;
//   const mtl =
//     ringNumber === 1
//       ? BoxingRingMaterial
//       : ringNumber === 2
//       ? GladiatorRingMaterial
//       : ringNumber === 3
//       ? PokemonRingMtl
//       : ringNumber === 4
//       ? StreetRingMtl
//       : UFCRingMtl;
