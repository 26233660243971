import { FaChevronDown, FaChevronUp, FaPlus, FaTrash } from "react-icons/fa";
import { useState } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import classNames from "classnames";
import apiProvider from "../../../providers/api";
export const LoadLoadout = ({
  menuItems,
  onSelect,
  selectedLoadout,
  menuHeader,
  nonMutable,
  loadoutType,
  getSavedLoadouts,
  setSelectedLoadout,
}) => {
  const [isOpen, toggle] = useState(false);
  const loadoutName =
    loadoutType === "POWERUP"
      ? "Select Powerups..."
      : loadoutType === "ACTION"
      ? "Select Actions..."
      : loadoutType === "TOKEN"
      ? "Select Token"
      : "";

  const deleteLoadout = async (id) => {
    if (loadoutType === "POWERUP") {
      await apiProvider.deletePowerupLoadout(id);
      await getSavedLoadouts();
      setSelectedLoadout(null);
    }
    if (loadoutType === "ACTION") {
      apiProvider.deleteActionLoadout(id);
      await getSavedLoadouts();
      setSelectedLoadout(null);
    }
  };
  return (
    <ButtonDropdown
      isOpen={isOpen}
      toggle={() => toggle((prevState) => !prevState)}
      className="primary-blue-btn"
    >
      <DropdownToggle caret>
        <span className="toggle-title">
          {selectedLoadout && selectedLoadout.name.length
            ? selectedLoadout.name
            : loadoutName}
        </span>
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
      </DropdownToggle>
      <DropdownMenu>
        {menuHeader && <DropdownItem header>{menuHeader}</DropdownItem>}
        {selectedLoadout && !nonMutable && (
          <DropdownItem onClick={() => onSelect(null)}>
            Create New <FaPlus />
          </DropdownItem>
        )}
        {menuItems && menuItems.length > 0 ? (
          menuItems.map((elm, idx) => {
            const { name } = elm;
            return (
              <DropdownItem key={idx} onClick={() => onSelect(elm)}>
                <div className="d-flex justify-content-between align-items-center mb-0">
                  {name ? (
                    <p className={"mb-0"}>{name}</p>
                  ) : (
                    <p>Loadout #{idx + 1}</p>
                  )}
                  <FaTrash onClick={() => deleteLoadout(elm._id)} />
                </div>
              </DropdownItem>
            );
          })
        ) : (
          <DropdownItem disabled>No Loadout Found</DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
};
