import classNames from "classnames";
import React from "react";
import styles from "./ActionCard.module.scss";
import { SHORT_HAND_ID, APP_LABELS } from "../../constant";
import { UncontrolledTooltip, Button } from "reactstrap";

const ACTIONS_IMAGE_MAP = {
  ATTACK: "/icons/attack.svg",
  "FAST ATTACK": "/icons/fast_attack.svg",
  BLOCK: "/icons/block.svg",
  EVADE: "/icons/evade.svg",
  BOOST: "/icons/boost.svg",
};

const ACTION_LIMIT = {
  ATTACK: 3,
  "FAST ATTACK": 3,
  BLOCK: 3,
  EVADE: 3,
  BOOST: 1,
};

const ActionCard = ({
  title,
  subtitle,
  onClick,
  isRemove,
  id,
  index,
  isActive,
  atLimit,
  disableAdd,
  actionPowers,
}) => {
  const imgSrc = subtitle && ACTIONS_IMAGE_MAP[subtitle];
  const tooltipId = `tooltip-${SHORT_HAND_ID[subtitle]}`;

  const getActionValue = (action_name) => {
    if (actionPowers) {
      switch (action_name) {
        case "ATTACK":
          const attackPower = actionPowers.find(
            ({ actionType }) => actionType === "ATTACK"
          );
          return `Power: ${attackPower.value}`;
        case "BLOCK":
          const blockPower = actionPowers.find(
            ({ actionType }) => actionType === "BLOCK"
          );
          return `Power: ${blockPower.value}`;
        case "FAST ATTACK":
          const fastAttackPower = actionPowers.find(
            ({ actionType }) => actionType === "FAST_ATTACK"
          );
          return `Power: ${fastAttackPower.value}`;
        case "EVADE":
          const evadeChance = actionPowers.find(
            ({ actionType }) => actionType === "EVADE_CHANCE"
          );
          return `Chance: ${parseFloat(evadeChance.value).toFixed(2)}%`;
        case "BOOST":
          return `Multiplier: 2x`;
        default:
          return `${action_name}`;
      }
    }
  };
  return id ? (
    <div
      className={classNames(
        styles.cardContainer,
        !isRemove && atLimit && styles.disabledCard
      )}
      id={!isRemove ? tooltipId : ""}
    >
      {!isRemove && atLimit && (
        <UncontrolledTooltip target={tooltipId}>
          {`You can equip this action only ${ACTION_LIMIT[subtitle]} times`}
        </UncontrolledTooltip>
      )}
      <div className={styles.imageAndTextContainer}>
        <div className={styles.imageContainer}>
          <img src={imgSrc} alt="" />
        </div>
        <div>
          <div className={styles.cardTitle}>{APP_LABELS[subtitle]}</div>
          <p className={styles.cardSubtitle}>{getActionValue(subtitle)}</p>
        </div>
      </div>
      <div>
        {isRemove ? (
          <div>
            <button
              onClick={() => onClick(index)}
              className={styles.cardRemoveButton}
            >
              Remove
            </button>
          </div>
        ) : (
          <div>
            <Button
              disabled={disableAdd}
              onClick={() => onClick({ id, index })}
              className={styles.cardAddButton}
            >
              Add
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      className={classNames(
        styles.actionBlankCard,
        isActive && styles.actionCardActive
      )}
    >
      Action {index + 1}
    </div>
  );
};

export default ActionCard;
