import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
import {
  FIGHT_TYPES,
  MIN_POOL_REQUIRED,
  MIN_USDT_POOL_REQUIRED,
  MIN_POWERUPS_REQUIRED,
} from "../../constant";

export const PoolModal = ({ isOpen, onCancel, onConfirm, fightType }) => {
  const message =
    fightType === FIGHT_TYPES.ARENA
      ? `You need to have at least ${MIN_POWERUPS_REQUIRED} powerups in your game wallet to enter arena mode. Add powerups to your game wallet.`
      : fightType === FIGHT_TYPES.BLOCKBOUT
      ? `Fund your game wallet with one of the following - 100 IND or 3 USDT or 3 USDC, or 3 DAI to enter the Championship mode.`
      : ``;
  return (
    <Modal className="confirm-modal-wrapper" centered isOpen={isOpen}>
      <ModalBody>
        <div className="confirm-modal-title">{message}</div>
        <div className="confirm-modal-actions">
          <Button
            onClick={onCancel}
            className={classnames("primary-blue-btn", "transparent-btn")}
          >
            Cancel
          </Button>
          <Button onClick={onConfirm} className="primary-blue-btn">
            {"Proceed"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
