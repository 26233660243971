export const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <div className="terms-wrapper">
        <div className="terms-heading">BlockBots</div>
        <div className="terms-subheading">Terms and Conditions of Use</div>
        <div className="terms-content">
          <p>
            BlockBots is a distributed application comprising a set of
            specially-developed smart contracts (each, a "<b>Smart Contract</b>
            ") deployed on the Ethereum network or such other supported
            blockchain network or Layer 2 scaling solution (each, the "
            <b>relevant Blockchain Network</b>") which allows any user to create
            and control unique non-fungible collectible tokens developed on the
            ERC-721 standard or other similar “non-fungible” token standard and
            which is irrevocably associated with a unique Uniform Resource
            Identifier JSON file containing metadata such as the name,
            description or image file (unique 3D Voxel avatar) stored on IPFS or
            such other storage network and/or usage terms of the underlying
            intellectual property ("<b>BlockBots</b>") within the BlockBots game
            ecosystem operated by Indorse Pte. Ltd. ("IPL", "we", or "us").
            Users may use these BlockBots to participate in events and
            mini-games within the BlockBots game ecosystem, complete quests,
            interact with other BlockBots, fight other BlockBots, or create and
            manage secondary child tokens based on the ERC-1155 standard
            (power-ups). These BlockBots, and the BlockBots game ecosystem, will
            be visualized on the https://blockbots.gg/ website that the user can
            interact with (the "<b>Site</b>"). The Smart Contracts and the Site
            are collectively referred to in these Terms as (the "<b>App</b>").
            Using the App, users can interact with the underlying Smart
            Contracts to view their BlockBots, personalise and name their
            BlockBots, participate in mini-games, level up their BlockBots,
            access the BlockBot Metaverse hideout, fight with other BlockBots to
            win unique and advanced power-ups, new BlockBots, participate in
            mini-games, and interact with other users in the BlockBots game
            ecosystem.
          </p>
          <p>
            IPL is making the App available to you. Before you use the App, the
            Smart Contracts, or the Site, however, you will need to agree to
            these Terms of Use and any terms and conditions incorporated herein
            by reference (collectively, these "<b>Terms</b>").{" "}
            <b>
              PLEASE READ THESE TERMS CAREFULLY BEFORE USING THE APP, THE SMART
              CONTRACTS, OR THE SITE. THESE TERMS GOVERN YOUR USE OF THE APP,
              THE SMART CONTRACTS, AND THE SITE, UNLESS WE HAVE EXECUTED A
              SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. WE ARE ONLY
              WILLING TO MAKE THE APP, THE SMART CONTRACTS, AND THE SITE
              AVAILABLE TO YOU IF YOU ACCEPT ALL OF THESE TERMS. BY USING THE
              APP, THE SMART CONTRACTS, THE SITE, OR ANY PART OF THEM, OR BY
              CLICKING "I ACCEPT" BELOW OR INDICATING YOUR ACCEPTANCE IN AN
              ADJOINING BOX, YOU ARE CONFIRMING THAT YOU UNDERSTAND AND AGREE TO
              BE BOUND BY ALL OF THESE TERMS. IF YOU ARE ACCEPTING THESE TERMS
              ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT
              YOU HAVE THE LEGAL AUTHORITY TO ACCEPT THESE TERMS ON THAT
              ENTITY’S BEHALF, IN WHICH CASE "YOU" WILL MEAN THAT ENTITY. IF YOU
              DO NOT HAVE SUCH AUTHORITY, OR IF YOU DO NOT ACCEPT ALL OF THESE
              TERMS, THEN WE ARE UNWILLING TO MAKE THE APP, THE SMART CONTRACTS,
              OR THE SITE AVAILABLE TO YOU. IF YOU DO NOT AGREE TO THESE TERMS,
              YOU MAY NOT ACCESS OR USE THE APP, THE SMART CONTRACTS, OR THE
              SITE.
            </b>
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on
            the Site, the App, and the Smart Contracts from time to time are
            hereby expressly incorporated herein by reference. We reserve the
            right, in our sole discretion, to make changes to the Terms from
            time to time. We will alert you of any changes by updating the “Last
            Updated" date of these Terms, and you waive any right to receive
            specific notice of each such change. It is your responsibility to
            periodically review these Terms to stay informed of updates. You
            will be subject to and will be deemed to have been made aware of and
            to have accepted, the changes in any revised Terms by your continued
            use of the Site, the App, and the Smart Contracts after the date
            such revised Terms are posted.
          </p>
          <p>
            The Site is intended for users who are at least 18 years old. People
            under the age of 18 are not permitted to use or register for the
            Site, the App, and the Smart Contracts.
          </p>
          <ol>
            <li>
              THE APP
              <ol type="A">
                <li>
                  To most easily use the App, you may first install a web
                  browser (such as the Google Chrome web browser) and an
                  electronic wallet compatible with the Non-Fungible Token (
                  <b>NFT</b>) standard on the relevant Blockchain Network, such
                  as the MetaMask electronic wallet. MetaMask and other
                  electronic wallets allow you to purchase, store, and engage in
                  transactions using Ethereum cryptocurrency. You will not be
                  able to engage in any transactions on the App other than
                  through MetaMask, or other browsers compatible with the
                  relevant Blockchain Network.
                </li>
                <li>
                  Transactions that take place on the App are managed and
                  confirmed via the relevant Blockchain Network. You understand
                  that your public address on the relevant Blockchain Network
                  will be made publicly visible whenever you engage in a
                  transaction on the App.
                </li>
                <li>
                  We neither own nor control MetaMask, Google Chrome, any
                  electronic wallet, the relevant Blockchain Network, or any
                  other third party site, product, or service that you might
                  access, visit, or use for the purpose of enabling you to use
                  the various features of the App. We will not be liable for the
                  acts or omissions of any such third parties, nor will we be
                  liable for any damage that you may suffer as a result of your
                  transactions or any other interaction with any such third
                  parties.
                </li>
                <li>
                  You must provide accurate and complete registration
                  information when you create an account for the App. By
                  creating an account, you agree to provide accurate, current
                  and complete account information about yourself, and to
                  maintain and promptly update as necessary your account
                  information. You are responsible for the security of your
                  account and your MetaMask wallet (and other wallets and
                  accounts on the relevant Blockchain Network). If you become
                  aware of any unauthorized use of your password or of your
                  account with us, you agree to notify us immediately at
                  info@indorse.io.
                </li>
              </ol>
            </li>
            <li>
              THE BLOCKBOTS
              <ol type="A">
                <li>
                  There are 9998 unique BlockBots created on the relevant
                  Blockchain Network. In order to obtain BlockBots, users may
                  acquire them (a) from IPL in various pre-sale / public sale
                  phases, (b) from other users via the peer-to-peer marketplace
                  in the App, or (c) from third party NFT marketplaces (e.g.
                  Rarible). Each BlockBot is created by algorithmically
                  combining different traits and layers of varying rarities and
                  powers, in diverse categories (clans, body, legs, head, etc).
                  Two BlockBots may also be combined into an even rarer and more
                  powerful BlockBot by combining two BlockBots into one, which
                  decreases the total supply of BlockBots.
                </li>
                <li>
                  To complement the BlockBots created, users may also create and
                  manage secondary child tokens based on the ERC-1155 standard
                  (i.e. "power-ups" in the BlockBots ecosystem). When associated
                  with a BlockBot, these power-ups can increase the chance of
                  the BlockBot winning battles, and enhances their abilities in
                  the upcoming play-to-earn game within the BlockBots ecosystem.
                </li>
                <li>
                  BlockBots may be used across compatible metaverses and games
                  on supported blockchain networks. For example, BlockBots may
                  be used to access the Decentraland ecosystem, "staked" in the
                  Indorse Staking Contract to receive IND token rewards,
                  deposited into NFTX vaults, or used as liquidity on various
                  decentralised exchanges
                </li>
                <li>
                  The first mini-game available within the BlockBots ecosystem
                  is "Block-bouts", which allows users to utilise their
                  BlockBots in fights against other BlockBots. Depending on the
                  base power and power-ups equipped and/or used during the
                  fight, the winner of the fight will be determined. The winning
                  BlockBot will receive a random power-up from the losing
                  BlockBot, which then may be utilised by the winning BlockBot
                  or traded in the peer-to-peer marketplace.
                </li>
              </ol>
            </li>
            <li>
              OWNERSHIP; LICENSE; RESTRICTIONS
              <ol type="A">
                <li>
                  Definitions. For the purposes of this Section 3., the
                  following capitalized terms will have the following meanings:
                  <p>
                    "<b>Art</b>" means any art, design, and drawings that may be
                    associated with an BlockBots that you Own.
                  </p>
                  <p>
                    "<b>Own</b>" means, with respect to an BlockBots, an
                    BlockBots that you have created, purchased or otherwise
                    rightfully acquired from a legitimate source, where proof of
                    such purchase is recorded on the relevant blockchain.
                  </p>
                  <p>
                    "<b>Third Party IP</b>" means any third party patent rights
                    (including, without limitation, patent applications and
                    disclosures), copyrights, trade secrets, trademarks,
                    know-how or any other intellectual property rights
                    recognized in any country or jurisdiction in the world.
                  </p>
                </li>
                <li>
                  Ownership
                  <ol type="i">
                    <li>
                      You Own the BlockBots. Each BlockBots is a NFT on the
                      relevant Blockchain Network which comprises, inter alia, a
                      licence to display the Art. When you purchase an
                      BlockBots, you own the non-fungible BlockBots token
                      completely. This means that you have the right to trade
                      your BlockBots, sell it or give it away, and are fully
                      responsible for all activities and interactions carried
                      out in connection with the BlockBots. Ownership of the
                      BlockBots is governed entirely by the Smart Contract and
                      the relevant Blockchain Network.
                    </li>
                    <li>
                      IPL owns the App and the Site. You acknowledge and agree
                      that IPL (or, as applicable, our licensors) owns all legal
                      right, title and interest in and to all other elements of
                      the App, and all intellectual property rights therein
                      (including, without limitation, all Art, the BlockBots
                      ecosystem, all designs, systems, methods, information,
                      computer code, software, services, website design, "look
                      and feel", themes, organization, compilation of the
                      content, code, data and database, functionality, audio,
                      sound effects, video, animation, text, photograph,
                      artwork, graphics, objects, characters, character names,
                      stories, dialogue, and all other elements of the App
                      (collectively, the "<b>Materials</b>"). You acknowledge
                      that the Materials are protected by copyright, trade
                      dress, patent, and trademark laws, international
                      conventions, other relevant intellectual property and
                      proprietary rights, and applicable laws. All Materials are
                      the copyrighted property of IPL or its licensors, and all
                      trademarks, service marks, and trade names associated with
                      the App or otherwise contained in the Materials are
                      proprietary to IPL or its licensors. Except as expressly
                      set forth herein, your use of the App does not grant you
                      ownership of or any other rights with respect to any
                      content, code, data, or other Materials that you may
                      access on or through the App. We reserve all rights in and
                      to the Materials that are not expressly granted to you in
                      these Terms. For the sake of clarity, you understand and
                      agree: (a) that your purchase of an BlockBots, whether via
                      the App or otherwise, does not give you any rights or
                      licenses in or to the Materials (including, without
                      limitation, our copyright in and to the associated Art)
                      other than those expressly contained in these Terms; (b)
                      that you do not have the right, except as otherwise set
                      forth in these Terms, to reproduce, distribute, or
                      otherwise commercialize any elements of the Materials
                      (including, without limitation, any Art) without our prior
                      written consent in each case, which consent we may
                      withhold in our sole and absolute discretion; and (c) that
                      you will not apply for, register, or otherwise use or
                      attempt to use any IPL trademarks or service marks, or any
                      confusingly similar marks, anywhere in the world without
                      our prior written consent in each case, which consent we
                      may withhold at our sole and absolute discretion.
                    </li>
                  </ol>
                </li>
                <li>
                  Licence
                  <p>
                    i. Use. Subject to your continued compliance with these
                    Terms, IPL grants you a limited, non-exclusive,
                    non-transferable, royalty-free license to display the Art
                    for your BlockBots and to access the BlockBots ecosystem,
                    solely for the following purposes: (i) for your own personal
                    use; (ii) as part of a marketplace that permits the purchase
                    and sale of your BlockBots, provided that the marketplace
                    cryptographically verifies each BlockBots owner’s rights to
                    display the Art for their BlockBots to ensure that only the
                    actual owner can display the Art; or (iii) as part of a
                    third party website or application that permits the
                    inclusion, involvement, or participation of your BlockBots,
                    provided that the website/application cryptographically
                    verifies each BlockBots owner’s rights to display the Art
                    for their BlockBots to ensure that only the actual owner can
                    display the Art, and provided that the Art is no longer
                    visible once the owner of the BlockBots leaves the
                    website/application.
                  </p>
                </li>
                <li>
                  Restrictions
                  <p>
                    You agree that you may not, nor permit any third party to do
                    or attempt to do any of the foregoing without IPL’s express
                    prior written consent in each case: (i) modify the Art for
                    your BlockBots in any way, including, without limitation,
                    the shapes, designs, drawings, attributes, or colour
                    schemes; (ii) use the Art for your BlockBots to advertise,
                    market, or sell any third party product or service; (iii)
                    use the Art for your BlockBots in connection with images,
                    videos, or other forms of media that depict hatred,
                    intolerance, violence, cruelty, or anything else that could
                    reasonably be found to constitute hate speech or otherwise
                    infringe upon the rights of others; (iv) use the Art for
                    your BlockBots in movies, videos, or any other forms of
                    media, except to the limited extent that such use is
                    expressly permitted in these Terms or solely for your own
                    personal use; (v) attempt to trademark, copyright, or
                    otherwise acquire additional intellectual property rights in
                    or to the Art for your BlockBots; or (vi) otherwise utilize
                    the Art for your BlockBots for your or any third party’s
                    benefit. To the extent that Art associated with your
                    BlockBots contains Third Party IP (e.g., licensed
                    intellectual property from a celebrity, athlete, or other
                    public figure), you understand and agree as follows: (x)
                    that you will not have the right to use such Third Party IP
                    in any way except as incorporated in the Art, and subject to
                    the license and restrictions contained herein; (y) that,
                    depending on the nature of the license granted from the
                    owner of the Third Party IP, IPL may need to pass through
                    additional restrictions on your ability to use the Art; and
                    (z) to the extent that IPL informs you of such additional
                    restrictions in writing (email is permissible), you will be
                    responsible for complying with all such restrictions from
                    the date that you receive the notice, and that failure to do
                    so will be deemed a breach of this license. The restrictions
                    in this Section 3.D will survive the expiration or
                    termination of these Terms.
                  </p>
                </li>
                <li>
                  Feedback
                  <p>
                    You may choose to submit comments, bug reports, ideas or
                    other feedback about the App, including without limitation
                    about how to improve the App (collectively, "<b>Feedback</b>
                    "). By submitting any Feedback, you agree that we are free
                    to use such Feedback at our discretion and without
                    additional compensation to you, and to disclose such
                    Feedback to third parties (whether on a non-confidential
                    basis, or otherwise). You hereby grant us a perpetual,
                    irrevocable, nonexclusive, worldwide license under all
                    rights necessary for us to incorporate and use your Feedback
                    for any purpose.
                  </p>
                </li>
                <li>
                  User Terms
                  <ol type="i">
                    <li>
                      User Obligations. You agree that you are responsible for
                      your own conduct while accessing or using the App, and for
                      any consequences thereof. You agree to use the App only
                      for purposes that are legal, proper and in accordance with
                      these Terms and any applicable laws or regulations. By way
                      of example, and not as a limitation, you may not, and may
                      not allow any third party to: (i) send, upload, distribute
                      or disseminate any unlawful, defamatory, harassing,
                      abusive, fraudulent, obscene, or otherwise objectionable
                      content; (ii) distribute viruses, worms, defects, Trojan
                      horses, corrupted files, hoaxes, or any other items of a
                      destructive or deceptive nature; (iii) impersonate another
                      person (via the use of an email address or otherwise);
                      (iv) upload, post, transmit or otherwise make available
                      through the App any content that infringes the
                      intellectual proprietary rights of any party; (v) use the
                      App to violate the legal rights (such as rights of privacy
                      and publicity) of others; (vi) engage in, promote, or
                      encourage illegal activity (including, without limitation,
                      money laundering); (vii) interfere with other users'
                      enjoyment of the App; (viii) exploit the App for any
                      unauthorized purpose, or facilitate, create or maintain
                      any unauthorised connection to the BlockBots ecosystem;
                      (ix) modify, adapt, translate, decompile, disassemble or
                      reverse engineer any portion of the App; (x) attempt to
                      bypass any measure of the Site designed to prevent or
                      restrict access to the Site, or any portion of the Site,
                      the App and the Smart Contracts; (xi) harass, intimidate,
                      or threaten any of our employees or agents engaged in
                      providing any portion of the Site, the App, and the Smart
                      Contracts to you; (xii) remove any copyright, trademark or
                      other proprietary rights notices contained in or on the
                      App or any part of it; (xi) reformat or frame any portion
                      of the App; (xiii) display any content on the App that
                      contains any hate-related or violent content or contains
                      any other material, products or services that violate or
                      encourage conduct that would violate any criminal laws,
                      any other applicable laws, or any third party rights;
                      (xiv) use any robot, spider, site search/retrieval
                      application, or other device to retrieve or index any
                      portion of the App or the content posted on the App, or to
                      collect information about its users for any unauthorized
                      purpose; (xv) upload or transmit (or attempt to upload or
                      to transmit) any material that acts as a passive or active
                      information collection or transmission mechanism,
                      including without limitation, clear graphics interchange
                      formats (“gifs”), 1×1 pixels, web bugs, cookies, or other
                      similar devices (sometimes referred to as “spyware” or
                      “passive collection mechanisms” or “pcms”); (xvi) create
                      user accounts by automated means or under false or
                      fraudulent pretences; (xvii) access or use the App for the
                      purpose of creating a product or service that is
                      competitive with any of our products or services; (xviii)
                      use the Site, the App and the Smart Contracts to advertise
                      or offer to sell goods and services; or (xix) disparage,
                      tarnish, or otherwise harm, in our opinion, us and/or the
                      Site, the App, and the Smart Contracts. If you engage in
                      any of the activities prohibited by this Section 3.F, we
                      may, at our sole and absolute discretion, without notice
                      to you, and without limiting any of our other rights or
                      remedies at law or in equity, immediately suspend or
                      terminate your user account and/or delete your BlockBots’s
                      images and descriptions from the App and the Site.
                    </li>
                    <li>
                      User Representations. By using the Site, BlockBots, the
                      App and the Smart Contracts, you represent and warrant
                      that: (i) you have read and understood these Terms and all
                      documentation on the App or the Site; (ii) you have good
                      and sufficient experience and understanding of the
                      functionality, usage, storage, transmission mechanisms and
                      other material characteristics of cryptographic tokens,
                      token storage mechanisms (such as token wallets),
                      blockchain technology, blockchain-like technology and
                      blockchain-based software systems to understand these
                      Terms and to appreciate the risks and implications of
                      creating or Owning BlockBots; (iii) you acknowledge and
                      agree that we may impose eligibility criteria to access
                      certain functionality in respect of BlockBots which may
                      require you to incur additional time and money costs; (iv)
                      you create and Own BlockBots for your own account and
                      shall not create or Own BlockBots on behalf of any other
                      entity or person; (v) your creation and/or Owning of
                      BlockBots complies with applicable law and regulation in
                      your jurisdiction, and the law and regulation of any
                      jurisdiction to which you may be subject (including, but
                      not limited to legal capacity and any other threshold
                      requirements for creating and owning BlockBots, using
                      BlockBots in the BlockBots ecosystem, and interacting with
                      other users within the BlockBots ecosystem, any foreign
                      exchange or regulatory restrictions applicable to creating
                      and Owning BlockBots, and any governmental or other
                      consents that may need to be obtained); (vi) all
                      registration information you submit will be true,
                      accurate, current, and complete (if you provide any
                      information that is untrue, inaccurate, not current, or
                      incomplete, we have the right to suspend or terminate your
                      account and refuse any and all current or future use of
                      the Site or the App (or any portion thereof)); (vii) you
                      will maintain the accuracy of such information and
                      promptly update such registration information as
                      necessary; (viii) you have the legal capacity and you
                      agree to comply with these Terms; (ix) you are not a minor
                      in the jurisdiction in which you reside; (x) you will not
                      use the Site, BlockBots, the App and the Smart Contracts
                      for any illegal, immoral or unauthorized purpose; (xi) you
                      will not use the Site, BlockBots, the App and the Smart
                      Contracts for any other purpose (save as approved by IPL
                      in writing); (xii) your use of the Site, BlockBots, the
                      App and the Smart Contracts will not violate any
                      applicable law or regulation; and (xiii) any funds or
                      digital assets used to create or purchase BlockBots are
                      not derived from or related to any unlawful activities,
                      including but not limited to money laundering or terrorist
                      financing and all applicable statutes of all jurisdictions
                      in which you are located, resident, organised or
                      operating, and/or to which it may otherwise be subject and
                      the rules and regulations thereunder (collectively, the "
                      <b>Compliance Regulations</b>"), and you will not use
                      BlockBots to finance, engage in, or otherwise support any
                      unlawful activities or in a manner which aids or
                      facilitates another party in the same. To the extent
                      required by applicable laws and regulations, you shall
                      fully comply with all Compliance Regulations.
                    </li>
                    <li>
                      User Registration. You may be required to register with
                      the Site, the App, and the Smart Contracts. You agree to
                      keep your password confidential and will be responsible
                      for all use of your account and password.
                    </li>
                    <li>
                      User Submissions. You acknowledge and agree that any
                      questions, comments, suggestions, ideas, feedback or other
                      information regarding the Site, the App and the Smart
                      Contracts ("<b>Submissions</b>") provided by you to us are
                      non-confidential and should become our sole property. We
                      should own exclusive rights, including all intellectual
                      property rights, and should be entitled to the
                      unrestricted use and dissemination of these Submissions to
                      any lawful purpose, commercial, or otherwise, without
                      acknowledgment or compensation for you. You hereby waive
                      any moral rights to any such Submissions, and you hereby
                      warrant that any such Submissions are original with you or
                      that you have the right to submit such Submissions. You
                      agree there should be no recourse against us for any
                      alleged or actual infringement or misappropriation of any
                      proprietary right in your Submissions.
                    </li>
                    <li>
                      Know Your Customer and Anti-Money Laundering. We reserve
                      the right to conduct "Know Your Customer" and "Anti-Money
                      Laundering" checks on you if deemed necessary by us (at
                      our sole discretion) or such checks become required under
                      applicable laws in any jurisdiction. Upon our request, you
                      shall immediately provide us with information and
                      documents that we, in our sole discretion, deem necessary
                      or appropriate to conduct "Know Your Customer" and
                      "Anti-Money Laundering" checks. Such documents may
                      include, but are not limited to, passports, driver's
                      licenses, utility bills, photographs of associated
                      individuals, government identification cards or sworn
                      statements before notaries or other equivalent
                      professionals. Notwithstanding anything herein, we may, in
                      its sole discretion, refuse to provide access to the
                      BlockBots, Smart Contracts, the App or the Site to you
                      until such requested information is provided, or in the
                      event that, based on information available to us, you are
                      suspected of using BlockBots, the smart Contracts, the App
                      or the Site in connection with any money laundering,
                      terrorism financing, or any other illegal activity. In
                      addition, we shall be entitled to use any possible efforts
                      for preventing money laundering, terrorism financing or
                      any other illegal activity, including without limitation
                      blocking of your access to BlockBots, Smart Contracts, the
                      App or the Site or providing your information to any
                      regulatory authority.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              FEES AND PAYMENT
              <ol type="A">
                <li>
                  If you elect to purchase or trade BlockBots on the App,
                  whether directly from us or in the peer-to-peer marketplace,
                  or interact within the BlockBots game ecosystem (whether with
                  other users or otherwise), any financial transactions that you
                  engage in will be conducted solely through the relevant
                  Blockchain Network. We will have no insight into or control
                  over these payments or transactions, nor do we have the
                  ability to reverse any transactions. With that in mind, we
                  will have no liability to you or to any third party for any
                  claims or damages that may arise as a result of any
                  transactions that you engage in via the App, or using the
                  Smart Contracts, or any other transactions that you conduct
                  via the relevant Blockchain Network.
                </li>
                <li>
                  The relevant Blockchain Network requires the payment of a
                  transaction fee (a "<b>Gas Fee</b>") for every transaction
                  that occurs on the relevant Blockchain Network. The Gas Fee
                  funds the network of computers that run the decentralized
                  network. This means that you will need to pay a Gas Fee for
                  each transaction that occurs via the App.
                </li>
                <li>
                  In addition to the Gas Fee, each time you utilize the
                  BlockBots marketplace Smart Contract to conduct a transaction
                  with another user via the App, you authorize us to collect a
                  trading calculated based on the total value of that
                  transaction, and as notified to you from time to time via the
                  most updated fee schedule in the App or the Site (the "
                  <b>Trading Fee</b>"). For each transaction where a Trading Fee
                  is payable, we shall give you the opportunity to view and
                  accept the Trading Fee before confirming the transaction and
                  paying the Trading Fee. You acknowledge and agree that the
                  Trading Fee will be transferred directly to us through the
                  relevant Blockchain Network as part of your payment.
                </li>
                <li>
                  As between us, you will be solely responsible to pay any and
                  all sales, use, value-added and other taxes, duties, and
                  assessments (except taxes on our net income) now or hereafter
                  claimed or imposed by any governmental authority
                  (collectively, "<b>Taxes</b>") associated with your use of the
                  App (including, without limitation, any Taxes that may become
                  payable as the result of your ownership or transfer or
                  interaction relating to your BlockBots). Except for income
                  taxes levied on IPL, you: (i) will pay or reimburse us for all
                  national, federal, state, local or other taxes and assessments
                  of any jurisdiction, including value added taxes and taxes as
                  required by international tax treaties, customs or other
                  import or export taxes, and amounts levied in lieu thereof
                  based on charges set, services performed or payments made
                  hereunder, as are now or hereafter may be imposed under the
                  authority of any national, state, local or any other taxing
                  jurisdiction; and (ii) shall not be entitled to deduct the
                  amount of any such taxes, duties or assessments from payments
                  made to us pursuant to these Terms. You confirm that you are
                  not registered for Goods and Services tax or any similar sales
                  tax in Singapore, and will inform IPL if your status changes
                  in the future.
                </li>
              </ol>
            </li>
            <li>
              TERMINATION
              <p>
                You may terminate these Terms at any time by cancelling your
                account on the App and discontinuing your access to and use of
                the App. You will not receive any refunds if you cancel your
                account, or otherwise terminate these Terms. You agree that we,
                in our sole discretion and for any or no reason, may terminate
                these Terms and suspend and/or terminate your account(s) for the
                App. You agree that any suspension or termination of your access
                to the App may be without prior notice, and that we will not be
                liable to you or to any third party for any such suspension or
                termination. If we terminate these Terms or suspend or terminate
                your access to or use of the App due to your breach of these
                Terms or any suspected fraudulent, abusive, or illegal activity,
                then termination of these Terms will be in addition to any other
                remedies we may have at law or in equity. Upon any termination
                or expiration of these Terms, whether by you or us, you may no
                longer have access to information that you have posted on the
                App or that is related to your account, and you acknowledge that
                we will have no obligation to maintain any such information in
                our databases or to forward any such information to you or to
                any third party. Sections 1.C and 3. through 14. will survive
                the termination or expiration of these Terms for any reason.
              </p>
            </li>
            <li>
              DISCLAIMERS
              <ol type="A">
                <li>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO AND USE
                  OF THE APP IS AT YOUR SOLE RISK, AND THAT THE APP IS PROVIDED
                  "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTIES OF ANY KIND,
                  WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE
                  PURSUANT TO APPLICABLE LAW, WE, OUR SUBSIDIARIES, AFFILIATES,
                  AND LICENSORS MAKE NO EXPRESS WARRANTIES AND HEREBY DISCLAIM
                  ALL IMPLIED WARRANTIES REGARDING THE APP AND ANY PART OF IT
                  (INCLUDING, WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT,
                  OR ANY EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-
                  INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY. WITHOUT
                  LIMITING THE GENERALITY OF THE FOREGOING, WE, OUR
                  SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT REPRESENT OR
                  WARRANT TO YOU THAT: (I) YOUR ACCESS TO OR USE OF THE APP WILL
                  MEET YOUR REQUIREMENTS, (II) YOUR ACCESS TO OR USE OF THE APP
                  WILL BE UNINTERRUPTED, TIMELY, SECURE OR FREE FROM ERROR,
                  (III) USAGE DATA PROVIDED THROUGH THE APP WILL BE ACCURATE,
                  (IV) THE APP OR ANY CONTENT, SERVICES, OR FEATURES MADE
                  AVAILABLE ON OR THROUGH THE APP ARE FREE OF VIRUSES OR OTHER
                  HARMFUL COMPONENTS, OR (V) THAT ANY DATA THAT YOU DISCLOSE
                  WHEN YOU USE THE APP WILL BE SECURE. SOME JURISDICTIONS DO NOT
                  ALLOW THE EXCLUSION OF IMPLIED WARRANTIES IN CONTRACTS WITH
                  CONSUMERS, SO SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT
                  APPLY TO YOU.
                </li>
                <li>
                  YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                  INFORMATION AND DEALING ONLINE OVER THE INTERNET, AND AGREE
                  THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR ANY BREACH OF
                  SECURITY UNLESS IT IS DUE TO OUR WILFUL DEFAULT.
                </li>
                <li>
                  WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSSES YOU
                  INCUR AS THE RESULT OF YOUR USE OF THE RELEVANT BLOCKCHAIN
                  NETWORK OR THE METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT
                  LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS ARISING FROM: (i)
                  USER ERROR, SUCH AS FORGOTTEN PASSWORDS OR INCORRECTLY
                  CONSTRUED SMART CONTRACTS OR OTHER TRANSACTIONS; (ii) SERVER
                  FAILURE OR DATA LOSS; (iii) CORRUPTED WALLET FILES; OR (iv)
                  UNAUTHORIZED ACCESS OR ACTIVITIES BY THIRD PARTIES, INCLUDING
                  BUT NOT LIMITED TO THE USE OF VIRUSES, PHISHING, BRUTEFORCING
                  OR OTHER MEANS OF ATTACK AGAINST THE APP, THE RELEVANT
                  BLOCKCHAIN NETWORK, OR THE METAMASK ELECTRONIC WALLET.
                </li>
                <li>
                  BLOCKBOTS ARE INTANGIBLE DIGITAL ASSETS THAT EXIST ONLY BY
                  VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE RELEVANT
                  BLOCKCHAIN NETWORK. ALL SMART CONTRACTS IN CONNECTION WITH THE
                  BLOCKBOTS ECOSYSTEM ARE DEPLOYED ON AND
                  INTERACTIONS/TRANSACTIONS WITH THE SAME OCCUR ON THE
                  DECENTRALIZED LEDGER WITHIN THE RELEVANT BLOCKCHAIN NETWORK.
                  WE HAVE NO CONTROL OVER AND MAKE NO GUARANTEES OR PROMISES
                  WITH RESPECT TO SMART CONTRACTS.
                </li>
                <li>
                  IPL IS NOT RESPONSIBLE FOR LOSSES DUE TO BLOCKCHAINS OR ANY
                  OTHER FEATURES OF THE RELEVANT BLOCKCHAIN NETWORK OR THE
                  METAMASK ELECTRONIC WALLET, INCLUDING BUT NOT LIMITED TO LATE
                  REPORT BY DEVELOPERS OR REPRESENTATIVES (OR NO REPORT AT ALL)
                  OF ANY ISSUES WITH THE BLOCKCHAIN SUPPORTING THE RELEVANT
                  BLOCKCHAIN NETWORK, INCLUDING FORKS, TECHNICAL NODE ISSUES, OR
                  ANY OTHER ISSUES HAVING FUND LOSSES AS A RESULT.
                </li>
              </ol>
            </li>
            <li>
              LIMITATION OF LIABILITY
              <ol type="A">
                <li>
                  YOU UNDERSTAND AND AGREE THAT WE, OUR SUBSIDIARIES,
                  AFFILIATES, AND LICENSORS WILL NOT BE LIABLE TO YOU OR TO ANY
                  THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                  CONSEQUENTIAL, OR EXEMPLARY DAMAGES WHICH YOU MAY INCUR,
                  HOWSOEVER CAUSED AND UNDER ANY THEORY OF LIABILITY, INCLUDING,
                  WITHOUT LIMITATION, ANY LOSS OF PROFITS (WHETHER INCURRED
                  DIRECTLY OR INDIRECTLY), LOSS OF GOODWILL OR BUSINESS
                  REPUTATION, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE
                  GOODS OR SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE
                  HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                </li>
                <li>
                  YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR ANY
                  AND ALL CLAIMS ARISING OUT OF OR RELATING TO THESE TERMS OR
                  YOUR ACCESS TO OR USE OF (OR YOUR INABILITY TO ACCESS OR USE)
                  ANY PORTION OF THE APP, WHETHER IN CONTRACT, TORT, STRICT
                  LIABILITY, OR ANY OTHER LEGAL THEORY, IS LIMITED TO THE
                  GREATER OF (A) THE AMOUNTS YOU ACTUALLY PAID US UNDER THESE
                  TERMS IN THE 12 MONTH PERIOD PRECEDING THE DATE THE CLAIM
                  AROSE, OR (B) S$100.
                </li>
                <li>
                  YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE APP AVAILABLE
                  TO YOU AND ENTERED INTO THESE TERMS IN RELIANCE UPON THE
                  WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH
                  HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK
                  BETWEEN THE PARTIES AND FORM AN ESSENTIAL BASIS OF THE BARGAIN
                  BETWEEN US. WE WOULD NOT BE ABLE TO PROVIDE THE APP TO YOU
                  WITHOUT THESE LIMITATIONS.
                </li>
                <li>
                  SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                  INCIDENTAL OR CONSEQUENTIAL DAMAGES, AND SOME JURISDICTIONS
                  ALSO LIMIT DISCLAIMERS OR LIMITATIONS OF LIABILITY FOR
                  PERSONAL INJURY FROM CONSUMER PRODUCTS, SO THE ABOVE
                  LIMITATIONS MAY NOT APPLY TO PERSONAL INJURY CLAIMS.
                </li>
              </ol>
            </li>
            <li>
              ASSUMPTION OF RISK
              <p>You accept and acknowledge each of the following:</p>
              <ol type="A">
                <li>
                  The prices of blockchain assets are extremely volatile.
                  Fluctuations in the price of other digital assets could
                  materially and adversely affect the value of your BlockBots,
                  which may also be subject to significant price volatility. We
                  cannot guarantee that any purchasers of BlockBots will not
                  lose money.
                </li>
                <li>
                  You are solely responsible for determining what, if any, taxes
                  apply to your BlockBots - related transactions. IPL is not
                  responsible for determining the taxes that apply to your
                  transactions on the App, the Site, or the Smart Contracts.
                </li>
                <li>
                  The App does not store, send, or receive BlockBots. This is
                  because BlockBots exist only by virtue of the ownership record
                  maintained on the App’s supporting blockchain in the relevant
                  Blockchain Network. Any transfer of BlockBots occurs within
                  the supporting blockchain in the relevant Blockchain Network,
                  and not on the App.
                </li>
                <li>
                  The publicly deployed Smart Contracts you interact with may
                  contain security vulnerabilities, errors, failures, bugs or
                  economic loopholes which may be exploited by third parties.
                  Interaction with these Smart Contracts are entirely your own
                  responsibility and liability, and IPL is not a party to the
                  Smart Contracts.
                </li>
                <li>
                  At any time, your access to BlockBots or your cryptocurrency
                  assets may be suspended or terminated or there may be a delay
                  in your access or use of your cryptocurrency assets, which may
                  result in the cryptocurrency assets diminishing in value or
                  you being unable to interact with a Smart Contract, the App or
                  the Site. The App or the Site may be suspended or terminated
                  for any or no reason, which may limit your access to your
                  cryptocurrency assets.
                </li>
                <li>
                  There are risks associated with using an Internet-based
                  currency, including, but not limited to, the risk of hardware,
                  software and Internet connections, the risk of malicious
                  software introduction, and the risk that third parties may
                  obtain unauthorized access to information stored within your
                  wallet. You accept and acknowledge that IPL will not be
                  responsible for any communication failures, disruptions,
                  errors, distortions or delays you may experience when using
                  the relevant Blockchain Network, however caused.
                </li>
                <li>
                  Because BlockBots is based on blockchain technology, any
                  malfunction, breakdown or abandonment of the relevant
                  Blockchain Network may have a material adverse effect on the
                  BlockBots you own. Moreover, advances in cryptography, or
                  technical advances such as the development of quantum
                  computing, could present risks to BlockBots, the underlying
                  digital assets, or the relevant Blockchain Network by
                  rendering ineffective the cryptographic consensus mechanism
                  that underpins the relevant Blockchain Network. The future of
                  cryptography and security innovations are highly
                  unpredictable.
                </li>
                <li>
                  A private key, or a combination of private keys, is necessary
                  to control and dispose of BlockBots stored in your digital
                  wallet, vault or other storage mechanism. Accordingly, loss of
                  requisite private key(s) associated with such digital wallet,
                  vault or other storage mechanism storing BlockBots may result
                  in loss of such BlockBots. Moreover, any third party that
                  gains access to such private key(s), including by gaining
                  access to login credentials of a hosted wallet service which
                  you use, may be able to misappropriate any BlockBots held by
                  you. IPL cannot be responsible for any such losses.
                </li>
                <li>
                  Hackers or other malicious groups or organisations may attempt
                  to interfere with BlockBots, the Smart Contracts, the App or
                  the Site in a variety of ways, including, but not limited to,
                  malware attacks, denial of service attacks, consensus-based
                  attacks, Sybil attacks, smurfing and spoofing, which may
                  result in losses incurred by you.
                </li>
                <li>
                  A lack of use or public interest in the creation and
                  development of distributed ecosystems could negatively impact
                  the development of the BlockBots ecosystem, and therefore the
                  potential utility or value of BlockBots.
                </li>
                <li>
                  The regulatory regime governing blockchain technologies,
                  cryptocurrencies, and tokens is uncertain, and new regulations
                  or policies may materially adversely affect the development of
                  the BlockBots ecosystem, and therefore the potential utility
                  or value of BlockBots.
                </li>
                <li>
                  An upgrade, hard fork, or a change in how transactions are
                  confirmed on the relevant Blockchain Network may have
                  unintended, adverse effects on all blockchains using the
                  ERC-20, ERC-721, or ERC-1155 standard, including the BlockBots
                  ecosystem.
                </li>
                <li>
                  Cryptographic tokens such as BlockBots are a new and untested
                  technology. In addition to the aforementioned risks, there may
                  be other risks associated with your creation, holding and use
                  of BlockBots, including those that IPL cannot anticipate. Such
                  risks may further materialise as unanticipated variations or
                  combinations of the risks discussed herein.
                </li>
              </ol>
            </li>
            <li>
              INDEMNIFICATION
              <p>
                You agree to hold harmless and indemnify IPL and its
                subsidiaries, affiliates, officers, agents, employees,
                advertisers, licensors, suppliers or partners from and against
                any claim, liability, loss, damage (actual and consequential) of
                any kind or nature, suit, judgment, litigation cost, and
                attorneys' fees arising out of or in any way related to (i) your
                breach of these Terms, (ii) your misuse of the App, or (iii)
                your violation of applicable laws, rules or regulations in
                connection with your access to or use of the App. You agree that
                IPL will have control of the defence or settlement of any such
                claims.
              </p>
            </li>
            <li>
              EXTERNAL SITES
              <p>
                The App may include hyperlinks to other web sites or resources
                (collectively, "<b>External Sites</b>"), which are provided
                solely as a convenience to our users. We have no control over
                any External Sites. You acknowledge and agree that we are not
                responsible for the availability of any External Sites, and that
                we do not endorse any advertising, products or other materials
                on or made available from any External Sites. Furthermore, you
                acknowledge and agree that we are not liable for any loss or
                damage which may be incurred as a result of the availability or
                unavailability of the External Sites, or as a result of any
                reliance placed by you upon the completeness, accuracy or
                existence of any advertising, products or other materials on, or
                made available from, any External Sites.
              </p>
            </li>
            <li>
              CHANGES TO THE APP
              <p>
                We are constantly innovating the App to help provide the best
                possible experience. You acknowledge and agree that the form and
                nature of the App, and any part of it, may change from time to
                time without prior notice to you, and that we may add new
                features and change any part of the App at any time without
                notice.
              </p>
            </li>
            <li>
              PRIVACY POLICY AND USER DATA
              <ol type="i">
                <li>
                  Our Privacy Policy (available at [link]) describes the ways we
                  collect, use, store and disclose your personal information,
                  and is hereby incorporated by this reference into these Terms.
                  You agree to the collection, use, storage, and disclosure of
                  your data in accordance with the aforementioned Privacy
                  Policy.
                </li>
                <li>
                  We will maintain certain data that you transmit to the Site,
                  the App and the Smart Contracts for the purpose of managing
                  the performance of the Site, the App and the Smart Contracts,
                  as well as data relating to your use of the Site, the App and
                  the Smart Contracts. Although we perform regular routine
                  backups of data, you are solely responsible for all data that
                  you transmit or that release to any activity you have
                  undertaken using the Site, the App and the Smart Contracts.
                  You agree that we shall have no liability to you for any loss
                  or corruption of any such data, and you hereby waive any right
                  of action against us arising from any such loss or corruption
                  of such data.
                </li>
              </ol>
            </li>
            <li>
              DISPUTE RESOLUTION; ARBITRATION
              <p>
                Please read this Section 13. carefully. It requires you to
                arbitrate disputes with IPL, and limits the manner in which you
                can seek relief from us.
              </p>
              <p>
                All disputes arising out of or in connection with these Terms
                (including without limitation the enforceability of this Section
                13. or any question regarding its existence, validity or
                termination, your access or use of the App, the Site, or the
                Smart Contracts, or to any products sold or distributed through
                the App, the Site, or the Smart Contracts) shall be referred to
                and finally resolved by arbitration administered by the
                Singapore International Arbitration Centre ("<b>SIAC</b>") in
                accordance with the Arbitration Rules of the Singapore
                International Arbitration Centre ("<b>SIAC Rules</b>") for the
                time being in force, which rules are deemed to be incorporated
                by reference in this Section 13.. The seat of the arbitration
                shall be Singapore. The Tribunal shall consist of 1 arbitrator.
                The language of the arbitration shall be English. The award of
                the arbitrator will be final and binding, and any judgment on
                the award rendered by the arbitrator may be entered in any court
                of competent jurisdiction. Each party will cover its own fees
                and costs associated with the arbitration proceedings.
                Notwithstanding the foregoing, IPL may seek and obtain
                injunctive relief in any jurisdiction in any court of competent
                jurisdiction, and you agree that these Terms are specifically
                enforceable by IPL through injunctive relief and other equitable
                remedies without proof of monetary damages.
              </p>
              <p>
                WITH RESPECT TO ANY DISPUTE ARISING OUT OF OR RELATED TO THESE
                TERMS, INCLUDING WITHOUT LIMITATION DISPUTES RELATED TO THE APP,
                THE SITE, THE SMART CONTRACTS, OR ANY PRODUCTS SOLD OR
                DISTRIBUTED THROUGH THE APP, THE SITE, OR THE SMART CONTRACTS:
                (I) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO HAVE A TRIAL BY
                JURY; AND (II) YOU HEREBY EXPRESSLY GIVE UP YOUR RIGHT TO
                PARTICIPATE AS A MEMBER OF A CLASS OF CLAIMANTS IN ANY LAWSUIT,
                INCLUDING BUT NOT LIMITED TO CLASS ACTION LAWSUITS INVOLVING ANY
                SUCH DISPUTE.
              </p>
            </li>
            <li>
              General
              <ol type="A">
                <li>
                  These Terms constitute the entire legal agreement between you
                  and IPL, govern your access to and use of the App, and
                  completely replace any prior or contemporaneous agreements
                  between the parties related to your access to or use of the
                  App, whether oral or written.
                </li>
                <li>
                  There are no third party beneficiaries to these Terms. A
                  person who is not a party under these Terms has no right under
                  the Contracts (Rights of Third Parties) Act, Chapter 53B of
                  Singapore to enforce or to enjoy the benefit of these Terms.
                </li>
                <li>
                  The parties are independent of each other, and nothing in
                  these Terms create any agency, partnership, joint venture or
                  any similar relationship, nor cause the parties to be deemed
                  acting in concert in any respect.
                </li>
                <li>
                  The language in these Terms will be interpreted as to its fair
                  meaning, and not strictly for or against any party.
                </li>
                <li>
                  You may not assign any or your rights or obligations under
                  these Terms, whether by operation of law or otherwise, without
                  our prior written consent. Notwithstanding anything contained
                  herein, we may assign our rights and obligations under these
                  Terms in our sole discretion (without your consent) to an
                  affiliate for any reason, including without limitation any
                  assignment or novation in connection with a reincorporation to
                  change IPL's domicile.
                </li>
                <li>
                  Should any provision or part-provision of these Terms is or
                  becomes invalid, illegal or unenforceable in any respect under
                  any law of any jurisdiction, it shall be deemed modified to
                  the minimum extent necessary to make it valid, legal and
                  enforceable; if such modification is not possible, the
                  relevant provision or part-provision shall be deemed deleted.
                  Any modification to or deletion of a provision or
                  part-provision pursuant to this Section shall not affect or
                  impair the validity and enforceability of the rest of these
                  Terms, nor the validity and enforceability of such provision
                  or part-provision under the law of any other jurisdiction.
                </li>
                <li>
                  Our failure to enforce any provision of these Terms will not
                  be deemed a waiver of such provision, nor of the right to
                  enforce such provision.
                </li>
                <li>
                  These Terms will be governed by and construed in accordance
                  with the laws of Singapore, without regard to conflict of law
                  rules and principles (whether of Singapore or any other
                  jurisdiction) that would cause the application of the laws of
                  any other jurisdiction.
                </li>
                <li>
                  Subject to Section 13., any legal action or proceeding arising
                  under these Terms will be brought exclusively in the courts of
                  Singapore, and the parties irrevocably consent to the personal
                  jurisdiction and venue there.
                </li>
                <li>
                  We will not be liable for any failure or delayed performance
                  of our obligations that result from any condition beyond our
                  reasonable control, including, but not limited to,
                  governmental action, acts of terrorism, earthquake, fire,
                  flood, acts of God, labour conditions, power failures,
                  Internet disturbances, or acts or omissions of third parties.
                </li>
                <li>
                  You agree that we may provide you with notices (including,
                  without limitation those regarding changes to these Terms) by
                  email, regular mail, or postings on the App. By providing us
                  with your email address, you consent to our using the email
                  address to send you any notices required by law in lieu of
                  communication by postal mail.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};
