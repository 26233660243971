import React, { useCallback, useEffect, useMemo, useState } from "react";
import Layer from "./Layer";
import { animated } from "@react-spring/three";

const Blockbot = (props) => {
  const {
    blockbot: blockbotDetails,
    blockbotRef,
    position = [0, 0, 0],
    scale = 1,
    rotation,
    setLoaded,
    botLoaderKey,
  } = props;
  const [loadedLayers, setLoadedLayers] = useState(0);
  const attributes = useMemo(
    () => blockbotDetails.attributes || [],
    [blockbotDetails.attributes]
  );

  const filteredLayerURLs = useMemo(
    () =>
      attributes
        .map((attribute) => {
          return attribute["3DAssetLink"]
            ? { url: attribute["3DAssetLink"], layer: attribute.trait_type }
            : null;
        })
        .filter(Boolean),
    [attributes]
  );

  const totalLayers = useMemo(
    () => filteredLayerURLs.length,
    [filteredLayerURLs]
  );

  const increamentLoadedLayers = useCallback(() => {
    setLoadedLayers((prevState) => {
      if (prevState < totalLayers) return prevState + 1;
      return prevState;
    });
  }, [totalLayers]);

  const layers = useMemo(
    () =>
      filteredLayerURLs.map((elm, idx) => {
        const { url } = elm;
        return (
          <Layer
            assetURL={url}
            key={idx}
            updateStatusCb={increamentLoadedLayers}
          />
        );
      }),
    [filteredLayerURLs, increamentLoadedLayers]
  );

  useEffect(() => {
    if (totalLayers && loadedLayers === totalLayers) setLoaded(botLoaderKey);
  }, [botLoaderKey, loadedLayers, setLoaded, totalLayers]);

  return (
    <animated.mesh
      ref={blockbotRef}
      position={position}
      scale={scale}
      rotation={rotation}
    >
      {layers}
    </animated.mesh>
  );
};

export default Blockbot;
