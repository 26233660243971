import React from "react";
import classNames from "classnames";
import styles from "./BlockbotHUDStats.module.scss";
import { Progress } from "reactstrap";

const BlockbotHUDStats = (props) => {
  const { blockbot, inverted, health, max_health, basePowers, isCurrentOwner } =
    props;

  if (!props?.blockbot) return null;
  let stats = {};
  basePowers?.forEach((base_power) => {
    stats = { ...stats, [base_power.powerType]: base_power.value };
  });
  return (
    <div
      className={classNames(
        styles.blockbotHUDContainer,
        inverted ? styles.invertedContainer : styles.straightContainer
      )}
    >
      <div
        className={classNames(
          inverted ? styles.InvertedHUDInnerContainer : styles.HUDInnerContainer
        )}
      >
        <div className={classNames(styles.blockbotHUDImageContainer)}>
          <img
            src={blockbot.image}
            alt=""
            className={classNames(
              inverted
                ? styles.invertedBlockbotHUDImage
                : styles.blockbotHUDImage
            )}
          />
        </div>
        <div className={classNames(styles.HUDDetailsContainer)}>
          <p className={classNames(styles.HUDName, inverted && "text-right")}>
            {blockbot.name} {isCurrentOwner && " (You)"}
          </p>
          <div className={classNames(styles.HealthbarContainer)}>
            <HealthBar
              health={Math.round(health)}
              max_health={max_health}
              inverted={inverted}
              className={classNames(styles.healthbar)}
            />
          </div>
          <div className={classNames(styles.baseStatsContainer)}>
            <div className={classNames(styles.statsWrapper, "agi-bck")}>
              <img
                src="./icons/agility_icon.svg"
                alt=""
                className={classNames(styles.statImageStyles)}
              />
              <span className={classNames(styles.agilityStatText)}>
                {stats.AGILITY}
              </span>
            </div>
            <div className={classNames(styles.statsWrapper, "str-bck")}>
              <img
                src="./icons/strength_icon.svg"
                alt=""
                className={classNames(styles.statImageStyles)}
              />
              <span className={classNames(styles.strengthStatText)}>
                {stats.STRENGTH}
              </span>
            </div>
            <div className={classNames(styles.statsWrapper, "def-bck")}>
              <img
                src="./icons/defense_icon.svg"
                alt=""
                className={classNames(styles.statImageStyles)}
              />
              <span className={classNames(styles.defenseStatText)}>
                {stats.DEFENSE}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockbotHUDStats;

const HealthBar = (props) => {
  const { health, max_health, inverted } = props;
  return (
    <div>
      <div
        className={classNames(
          styles.healthBarContainer,
          inverted && styles.invertedRow
        )}
      >
        <div
          className={classNames(
            styles.healthBarTextContainer,
            inverted && styles.invertedRow
          )}
        >
          <img
            src="./icons/heart-wht.png"
            alt=""
            className={classNames(styles.statImageStyles)}
          />
          <p className={classNames(styles.healthBarLabel)}>HEALTH POINTS</p>
        </div>
        <div>
          <p className={classNames(styles.healthBarValue)}>{health}</p>
        </div>
      </div>
      <div className={classNames(styles.progressBarContainer)}>
        <Progress
          value={health}
          max={max_health}
          barClassName={styles.healthBarBg}
          className={classNames(
            styles.healthBar,
            inverted ? "right" : null,
            "healthbar"
          )}
        />
      </div>
    </div>
  );
};
