import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  HOME_PAGE,
  BLOCK_BOUTS_FIGHT_WIZARD_PAGE,
  BLOCK_BOUTS_WAITING_ROOM,
  BLOCK_BOUTS_FIGHT_PAGE,
  BLOCK_BOUTS_PROFILE_PAGE,
  BLOCK_BOUTS_WALLET_PAGE,
  // GA_TRACKING_ID,
} from "./constant";
import Header from "./components/header";
import BlockBouts from "./pages/BlockBouts";
import FightWizard from "./pages/FightWizard";
import WaitingRoom from "./pages/WaitingRoom";
import Fight from "./pages/Fight";
import Profile from "./pages/Profile";
import Wallet from "./pages/Wallet";
import { TermsAndConditions } from "./pages/TermsAndConditions";
// import ReactGA from "react-ga";
import { Footer } from "./components/Footer";
import { ToastContainer } from "react-toastify";
import SoundConsole from "./components/WizardSoundConsole";
import { WIZARD_BACKGROUND_AUDIO_FEATURE_ENABLED } from "./constant/config";
// GA_TRACKING_ID && ReactGA.initialize(GA_TRACKING_ID);

function App() {
  const [fullscreen, setFullscreen] = useState(false);
  const location = useLocation();

  // useEffect(() => {
  //   if (GA_TRACKING_ID) ReactGA.pageview(window.location.pathname);
  // }, []);

  useEffect(() => {
    if (location.pathname === "/fight") {
      setFullscreen(true);
      return;
    }
    setFullscreen(false);
  }, [location]);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {!fullscreen && <Header />}
      {!fullscreen && WIZARD_BACKGROUND_AUDIO_FEATURE_ENABLED && (
        <SoundConsole />
      )}
      <div className="main-container">
        <Routes>
          <Route path={HOME_PAGE} element={<BlockBouts isOpen />} />
          <Route
            path={`${BLOCK_BOUTS_FIGHT_WIZARD_PAGE}`}
            element={<FightWizard />}
          />
          <Route path={BLOCK_BOUTS_WAITING_ROOM} element={<WaitingRoom />} />
          <Route path={BLOCK_BOUTS_FIGHT_PAGE} element={<Fight />} />
          <Route path={BLOCK_BOUTS_PROFILE_PAGE} element={<Profile />} />
          <Route path={BLOCK_BOUTS_WALLET_PAGE} element={<Wallet />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
        <ToastContainer />
      </div>
      {!fullscreen && <Footer />}
    </div>
  );
}

export default App;
