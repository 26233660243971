import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import apiProvider from "../providers/api";
import { FIGHT_TYPES } from "../constant";
export const fetchBlockbotDetails = createAsyncThunk(
  "wizard/fetchBlockbotDetails",
  async ({ address, pagePosition }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      // const startingIndex = (pagePosition - 1) * 10;
      // const endingIndex = startingIndex + 10;
      let freeBBs = [];
      // const ids = (await contract.walletOfOwner(address)).map((item) =>
      //   item.toString()
      // );
      // if (ids.length) {
      //   const modifiedChunkIds = ids.slice(startingIndex, endingIndex);
      //   const joinedIds = modifiedChunkIds.join(",");
      //   const chunkedResp = await apiProvider.getBlockBotArray(joinedIds);

      //   blockbots = chunkedResp
      //     .reduce((acc, item) => acc.concat(item), [])
      //     .filter((item) => !!item.name);
      // } else {
      //   blockbots = await apiProvider.getFreeBlockBots();
      // }
      const { data = [], total } = await apiProvider.getBlockBotsByAddress(
        address,
        pagePosition
      );
      if (data.length === 0) freeBBs = await apiProvider.getFreeBlockBots();
      return {
        blockbots: [...data, ...freeBBs],
        isOwner: data.length > 0,
        total,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchPowerupDetails = createAsyncThunk(
  "wizard/fetchPowerupDetails",
  async ({ address, fightType }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      if (fightType === FIGHT_TYPES.ARENA) {
        const powerups = await apiProvider.getSpenderWalletPowerups(address);
        return {
          powerups: powerups,
          loadoutEnabled: false,
        };
      }
      let freeProwerups = [];
      const { powerups } = await apiProvider.getPowerUpsDetails({
        account: address,
        includeSpendingWallet: true,
      });
      // DISBALED FETCHING OF FREE POWERUPS
      // if (powerups.length === 0)
      //   freeProwerups = await apiProvider.getFreePowerUps();

      // const { basePowerUps } = await apiProvider.getBasePowerOfBB(bbId);
      const basePowerUps = [];
      return {
        powerups: powerups.concat(basePowerUps).concat(freeProwerups),
        loadoutEnabled: powerups.length > 0,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const fetchBasePowerups = createAsyncThunk(
  "wizard/fetchBasePowerups",
  async ({ tokenId }, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const { wizard } = getState();
    const { powerups } = wizard;
    try {
      const { basePowerUps } = await apiProvider.getBasePowerOfBB(tokenId);
      return {
        powerups: powerups.data.concat(basePowerUps),
      };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);
export const fetchActionDetails = createAsyncThunk(
  "wizard/fetchActionDetails",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const actions = await apiProvider.getActions();
      return {
        actions,
      };
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const selectBlockbot = createAction(
  "wizard/selectBlockbot",
  function prepare(blockbot) {
    return {
      payload: {
        blockbot,
      },
    };
  }
);

export const setSelectedPowerUps = createAction(
  "wizard/setSelectedPowerUps",
  function prepare(powerups) {
    return {
      payload: {
        powerups,
      },
    };
  }
);

export const updatePowerUps = createAction(
  "wizard/updatePowerUps",
  function prepare(powerups) {
    return {
      payload: {
        powerups,
      },
    };
  }
);

export const selectActions = createAction(
  "wizard/selectActions",
  function prepare(actions) {
    return {
      payload: {
        actions,
      },
    };
  }
);

export const setCurrentState = createAction(
  "wizard/setCurrentState",
  function prepare(state) {
    return {
      payload: {
        state,
      },
    };
  }
);

export const setSelectedActions = createAction(
  "wizard/setSelectedActions",
  function prepare(actions) {
    return {
      payload: {
        actions,
      },
    };
  }
);

export const resetSelectedPowerups = createAction(
  "wizard/resetSelectedPowerups"
);
export const resetSelectedBB = createAction("wizard/resetSelectedBB");
export const resetSelectedActions = createAction("wizard/resetSelectedActions");

export const resetWizard = createAction("wizard/resetWizard");
