import React from "react";
import { PowerupDetailCard } from "../../PowerupDetailCard";
import { MAX_APPLY_RULE } from "../../../constant";

export const DraggablePowerupDetailsCard = (props) => {
  const {
    name,
    tokenId,
    id,
    selectedTokenIds,
    attributes,
    activeTypes,
    ...rest
  } = props;
  const maxApplyRule = MAX_APPLY_RULE.powerups;
  const isSelected = selectedTokenIds.indexOf(tokenId) > -1;
  const maxSelected = selectedTokenIds.filter(Boolean).length === 5;
  const currentPowerUpType =
    attributes.filter((el) => el.trait_type === "Type")[0]?.value || "BASE";
  const currentApplyCount = activeTypes.filter(
    (elm) => elm === currentPowerUpType
  ).length;
  return (
    <PowerupDetailCard
      {...rest}
      name={name}
      powerupType={currentPowerUpType}
      disabled={currentApplyCount === maxApplyRule[currentPowerUpType]}
      maxRule={maxApplyRule[currentPowerUpType]}
      tokenId={tokenId}
      isSelected={isSelected}
      maxSelected={maxSelected}
      attributes={attributes}
    />
  );
};
