import { PowerupCard } from "../PowerupCard";
import classNames from "classnames";
import styles from "./DepositPowerups.module.scss";

const DepositPowerups = ({
  powerups,
  selectedPowerups,
  setSelectedPowerups,
  powerupsSubmitHandler,
}) => {
  if (selectedPowerups === null) {
    const initialQuantities =
      powerups &&
      powerups.map((powerup) => {
        return { id: powerup.tokenId, quantity: 0 };
      });
    setSelectedPowerups(initialQuantities);
  }
  if (!powerups.length && !selectedPowerups.length) return null;
  const addPowerupToSelectedPowerups = (powerup, powerup_index) => {
    if (selectedPowerups[powerup_index].quantity < powerup.numberOfTokens) {
      const modifiedSelectedPowerups = [...selectedPowerups];
      modifiedSelectedPowerups[powerup_index].quantity += 1;
      setSelectedPowerups(modifiedSelectedPowerups);
    }
  };

  const removePowerupFromSelectedPowerups = (powerup, powerup_index) => {
    if (selectedPowerups[powerup_index].quantity !== 0) {
      const modifiedSelectedPowerups = [...selectedPowerups];
      modifiedSelectedPowerups[powerup_index].quantity -= 1;
      setSelectedPowerups(modifiedSelectedPowerups);
    }
  };

  return (
    <div className={classNames(styles.powerupsContainer)}>
      <div className={styles.depositModalWalletPowerupsContainer}>
        {powerups &&
          powerups.map((powerup, powerup_index) => (
            <PowerupCard
              powerup={powerup}
              key={powerup_index}
              showQuantityManipulation={true}
              quantity={selectedPowerups?.[powerup_index].quantity || 0}
              onAdd={() => addPowerupToSelectedPowerups(powerup, powerup_index)}
              onSubtract={() =>
                removePowerupFromSelectedPowerups(powerup, powerup_index)
              }
            />
          ))}
      </div>
      <button
        className={classNames(styles.customButton)}
        onClick={() => powerupsSubmitHandler(selectedPowerups)}
      >
        Confirm
      </button>
    </div>
  );
};

export default DepositPowerups;
