/* eslint-disable no-empty-pattern */
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ActionCard from "../../ActionCard";

export const DnDActionCard = (props) => {
  const { id, index, onDrop, type, actionPowers, ...rest } = props;
  const ref = useRef(null);

  const [{ isActive }, drop] = useDrop({
    accept: type,
    drop: (item) => {
      onDrop({ item, index });
    },
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
  });

  const [{}, drag] = useDrag({
    type,
    item: () => {
      return { id, index, isInsideDrop: true };
    },
  });

  drag(drop(ref));
  return (
    <div ref={ref}>
      <ActionCard
        {...rest}
        isRemove
        id={id}
        index={index}
        isActive={isActive}
        actionPowers={actionPowers}
      />
    </div>
  );
};
