/* eslint-disable no-unused-vars */
import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";
import { injected } from "../connectors";
import { getErrorMessage } from "../utils";
import { getLibrary } from "../utils/getLibrary";
// import Web3 from "web3";

export default function useWeb3Methods() {
  const { activate, active, error, library, account, chainId } = useWeb3React();
  const [mmAcc, setMMAcount] = useState(account);
  const [web3Rawprovider, setWeb3Rawprovider] = useState();

  const [provider, setProvider] = useState(undefined);

  const connectWallet = useCallback(async () => {
    try {
      await activate(injected);
    } catch (err) {
      console.log(err);
      getErrorMessage(err);
    }
  }, [activate]);

  useEffect(() => {
    connectWallet();
    setMMAcount(account);
  }, [account, chainId, connectWallet]);

  useEffect(() => {
    if (active && library) {
      setProvider(library);
      setWeb3Rawprovider(getLibrary(window.ethereum));
    }
  }, [active, library, account]);

  if (error) getErrorMessage(error);
  return {
    provider,
    connectWallet,
    account,
    chainId,
    active,
    error,
    web3Rawprovider,
  };
}
