import { animated } from "@react-spring/three";
// import { useAnimatedSprite } from "use-animated-sprite";
import { useSpring } from "@react-spring/three";
import { degToRad } from "three/src/math/MathUtils";
import { useRef } from "react";
import FastATKSprite from "./Fast_Attack_V2.png";
import spriteJSON from "./spriteFrame.json";
import { useSpriteAnimator } from "../../../../hooks/useSpriteAnimator";

export const FastAttackSpriteAnimation = (props) => {
  const { onAttackComplete, isRTL, isActive } = props;
  const spriteRef = useRef();
  const [tex] = useSpriteAnimator(
    FastATKSprite,
    100,
    1,
    6,
    !isActive,
    spriteJSON
  );

  const START_POSITION = isRTL ? [2, 4, 0] : [-2, 4, 0];
  const END_POSITION = isRTL ? [-2, 4, 0] : [2, 4, 0];

  const { position, scale } = useSpring({
    to: async (next) => {
      await next({ scale: 2.5, immediate: true });
      await next({ position: END_POSITION });
      await next({ scale: 0, position: START_POSITION, immediate: true });
      onAttackComplete();
    },
    from: { position: START_POSITION, scale: 0 },
    config: { duration: 700 },
    pause: !isActive,
  });

  if (isRTL) tex.rotation = [degToRad(185)];

  return (
    <animated.mesh scale={scale} position={position}>
      <sprite ref={spriteRef}>
        <spriteMaterial transparent map={tex} />
      </sprite>
    </animated.mesh>
  );
};
