import { BigNumber } from "bignumber.js";

export const toRound = (value) => value.integerValue(BigNumber.ROUND_FLOOR);
export const toEpochTime = (time) =>
  toRound(new BigNumber(time.getTime()).div(1000)).toNumber();
export const truncateString = (value, start = 5, end = 5) => {
  if (value && typeof value === "string") {
    return (
      value.substr(0, start) +
      "..." +
      value.substr(value.length - end, value.length)
    );
  }
  return "";
};

export const weiToRbtc = (value) => {
  return value / 10 ** 18;
};

export const rbtcToWei = (value) => {
  return value * 10 ** 18;
};

export const setIntervalSynchronous = function (func, delay) {
  let intervalFunction, timeoutId, clear;
  // Call to clear the interval.
  clear = function () {
    clearTimeout(timeoutId);
  };
  intervalFunction = function () {
    func();
    timeoutId = setTimeout(intervalFunction, delay);
  };
  // Delay start.
  timeoutId = setTimeout(intervalFunction, delay);
  // You should capture the returned function for clearing.
  return clear;
};

export const convertAmountToLowerDenomBasedOnDecimal = (value, decimal) => {
  return toRound(
    new BigNumber(value).times(getExponentiatedPowerOf10(decimal))
  );
};

export const convertAmountToUpperDenomBasedOnDecimal = (value, decimal) => {
  return new BigNumber(value).div(getExponentiatedPowerOf10(decimal));
};

export const getExponentiatedPowerOf10 = (powerof) => {
  return new BigNumber(10).exponentiatedBy(powerof);
};

export const toPriceString = (priceObject) => {
  return `${new BigNumber(priceObject.quantity / 10 ** priceObject.decimal)} ${
    priceObject.symbol
  }`;
};

export const handleKeypress = (e) => {
  const characterCode = e.key;
  if (characterCode === "Backspace") return;

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  } else {
    e.preventDefault();
  }
};

export const parseGameDetails = (gameDetails) => {
  const {
    participant1,
    participant2,
    rounds,
    arena,
    matchType,
    playedWithBot,
  } = gameDetails;
  const selectedBotTokenId = participant1?.participantDetails?.tokenId;
  const oponentBotTokenId = participant2?.participantDetails?.tokenId;
  const selectedBotBasePowers = participant1?.participantDetails?.basePowers;
  const opponentBotBasePowers = participant2?.participantDetails?.basePowers;
  const participant1EthAddress =
    participant1?.participantDetails?.playerEthAddress;
  const participant2EthAddress =
    participant2?.participantDetails?.playerEthAddress;

  const winnerBotKey =
    participant1?.result === "DRAW"
      ? null
      : participant1?.result === "WON"
      ? "selectedBlockbot"
      : "opponentBlockbot";
  const winnerEthAddress =
    participant1.result === "DRAW"
      ? null
      : participant1?.result === "WON"
      ? participant1EthAddress
      : participant2EthAddress;
  return {
    selectedBotTokenId,
    oponentBotTokenId,
    winnerBotKey,
    rounds,
    selectedBotBasePowers,
    opponentBotBasePowers,
    participant1EthAddress,
    participant2EthAddress,
    arena,
    winnerEthAddress,
    matchType,
    playedWithBot,
    player1Name: participant1.participantDetails.userName,
    player2Name: participant2.participantDetails.userName,
  };
};

export const getFrames = (spriteJSON) => {
  return Object.values(spriteJSON.frames);
};
export const deg2rad = (degrees) => degrees * (Math.PI / 180);
