import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./SoundConsole.module.scss";
import useSound from "use-sound";
import {
  FaVolumeUp,
  FaVolumeMute,
  FaVolumeDown,
  FaTimes,
} from "react-icons/fa";
//Background music
import EpicDrums from "../../../assets/Sound/EpicDrums.wav";

// Action based sounds
import BlockSound from "../../../assets/Sound/Block.wav";
import AttackSound from "../../../assets/Sound/Attack.wav";
import FstAttckSound from "../../../assets/Sound/Fast_Attack.wav";
import BoostSound from "../../../assets/Sound/Boost.wav";
import EvadeSound from "../../../assets/Sound/Evade.wav";
import MatchStartSound from "../../../assets/Sound/Round_Start.wav";
import InGameBGM from "../../../assets/Sound/In-Game-BGM.mp3";
import PostGameBGM from "../../../assets/Sound/PostGameBGM.mp3";
import WinnerSound from "../../../assets/Sound/Winner.mp3";
import LoseSound from "../../../assets/Sound/Lose.wav";

const SoundConsole = ({
  selectedBlockbotAction,
  opponentBlockbotAction,
  matchStarted,
  matchEnded,
  isCurrentUserWinner,
  account,
}) => {
  const [consoleExpanded, setConsoleExpanded] = useState(false);
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [backgroundAudioVolume, setBackgroundAudioVolume] = useState(0.2);
  const [soundEffectsVolume, setSoundEffectsVolume] = useState(0.6);

  const [playBackgroundAudio, { stop: stopBackgroundAudio }] = useSound(
    EpicDrums,
    {
      volume: backgroundAudioVolume,
      // soundEnabled,
    }
  );
  const [playAttackSound, { stop: stopAttackSound }] = useSound(AttackSound, {
    volume: soundEffectsVolume,
  });
  const [playBlockSound, { stop: stopBlockSound }] = useSound(BlockSound, {
    volume: soundEffectsVolume,
    soundEnabled,
  });
  const [playBoostSound, { stop: stopBoostSound }] = useSound(BoostSound, {
    volume: soundEffectsVolume,
    soundEnabled,
  });
  const [playEvadeSound, { stop: stopEvadeSound }] = useSound(EvadeSound, {
    volume: soundEffectsVolume,
    soundEnabled,
  });
  const [playFastAttackSound, { stop: stopFastAttackSound }] = useSound(
    FstAttckSound,
    {
      volume: soundEffectsVolume,
      soundEnabled,
    }
  );

  const [playMatchStartSound, { stop: stopMatchStartSound }] = useSound(
    MatchStartSound,
    {
      volume: soundEffectsVolume,
      soundEnabled,
    }
  );

  const [playInGameBGM, { stop: stopInGameBGM }] = useSound(InGameBGM, {
    volume: backgroundAudioVolume,
    soundEnabled,
  });

  const [playPostGameBGM, { stop: stopPostGameBGM }] = useSound(PostGameBGM, {
    volume: backgroundAudioVolume,
    soundEnabled,
  });

  const [playWinnerSound, { stop: stopWinnerSound }] = useSound(WinnerSound, {
    volume: soundEffectsVolume,
    soundEnabled,
  });
  const [playLoseSound, { stop: stopLoseSound }] = useSound(LoseSound, {
    volume: soundEffectsVolume,
    soundEnabled,
  });

  useEffect(() => {
    if (matchStarted) {
      stopBackgroundAudio();
      playMatchStartSound();
      setTimeout(() => {
        playInGameBGM();
      }, 500);
    }
  }, [matchStarted, playInGameBGM, playMatchStartSound, stopBackgroundAudio]);

  useEffect(() => {
    if (matchEnded) {
      stopInGameBGM();
      stopMatchStartSound();
      if (!isCurrentUserWinner && account) playLoseSound();
      else playWinnerSound();
      setTimeout(() => {
        playPostGameBGM();
      }, 500);
    }
  }, [
    account,
    isCurrentUserWinner,
    matchEnded,
    playLoseSound,
    playPostGameBGM,
    playWinnerSound,
    stopInGameBGM,
    stopMatchStartSound,
  ]);

  useEffect(() => {
    return () => {
      stopBackgroundAudio();
      stopInGameBGM();
      stopPostGameBGM();
      stopWinnerSound();
      stopLoseSound();
    };
  }, [
    stopBackgroundAudio,
    stopInGameBGM,
    stopLoseSound,
    stopPostGameBGM,
    stopWinnerSound,
  ]);

  useEffect(() => {
    if (!soundEnabled) {
      stopBackgroundAudio();
      stopAttackSound();
      stopBlockSound();
      stopEvadeSound();
      stopBoostSound();
      stopFastAttackSound();
    }
  }, [
    soundEnabled,
    stopAttackSound,
    stopBackgroundAudio,
    stopBlockSound,
    stopBoostSound,
    stopEvadeSound,
    stopFastAttackSound,
  ]);

  useEffect(() => {
    if (selectedBlockbotAction && opponentBlockbotAction && soundEnabled) {
      if (
        selectedBlockbotAction === "ATTACK" ||
        opponentBlockbotAction === "ATTACK"
      ) {
        setTimeout(() => playAttackSound(), 100);
      }
      if (
        selectedBlockbotAction === "BLOCK" ||
        opponentBlockbotAction === "BLOCK"
      ) {
        setTimeout(() => playBlockSound(), 1500);
      }
      if (
        selectedBlockbotAction === "EVADE" ||
        opponentBlockbotAction === "EVADE"
      ) {
        setTimeout(() => playEvadeSound(), 1000);
      }
      if (
        selectedBlockbotAction === "BOOST" ||
        opponentBlockbotAction === "BOOST"
      ) {
        setTimeout(() => playBoostSound(), 500);
      }
      if (
        selectedBlockbotAction === "FAST_ATTACK" ||
        opponentBlockbotAction === "FAST_ATTACK"
      ) {
        setTimeout(() => playFastAttackSound(), 100);
      }
    }
  }, [
    opponentBlockbotAction,
    playAttackSound,
    playBlockSound,
    playBoostSound,
    playEvadeSound,
    playFastAttackSound,
    selectedBlockbotAction,
    soundEnabled,
  ]);

  const onToggleAllSounds = () => {
    setSoundEnabled((prevState) => !prevState);
    playBackgroundAudio();
  };

  //Play corresponding action music after a certain delay of the round starting
  // useEffect(() => {
  //   console.log(p1Action, p2Action);
  //   if (p1Action === "ATTACK" || p2Action === "ATTACK") {
  //   }
  // }, [p1Action, p2Action]);

  return (
    <div className={classNames(styles.soundConsoleContainer)}>
      {consoleExpanded ? (
        <div className={classNames(styles.expandedConsoleContainer)}>
          <div onClick={onToggleAllSounds}>
            {soundEnabled ? (
              <div className={classNames(styles.soundEnableContainer)}>
                <FaVolumeMute className={classNames(styles.soundEnableIcon)} />
                <p className={classNames(styles.soundEnableText)}>
                  Mute All Audio
                </p>
              </div>
            ) : (
              <div className={classNames(styles.soundEnableContainer)}>
                <FaVolumeUp className={classNames(styles.soundEnableIcon)} />
                <p className={classNames(styles.soundEnableText)}>
                  Enable Audio
                </p>
              </div>
            )}
          </div>
          <div className={classNames(styles.sliderContainer)}>
            <div
              className={classNames(styles.closeButtonContainer)}
              onClick={() => setConsoleExpanded(false)}
            >
              <FaTimes className={classNames(styles.closeButtonIcon)} />
            </div>
            <p className={classNames(styles.sliderText)}>BACKGROUND SOUND</p>
            <div className={classNames(styles.sliderInnerContainer)}>
              <FaVolumeDown className={classNames(styles.sliderLeftIcon)} />
              <input
                type="range"
                className={classNames(styles.slider)}
                value={backgroundAudioVolume}
                onChange={(e) => setBackgroundAudioVolume(e.target.value)}
                min={0}
                max={1}
                step={0.01}
              />
              <FaVolumeUp className={classNames(styles.sliderRightIcon)} />
            </div>
          </div>
          <div className={classNames(styles.sliderContainer)}>
            <p className={classNames(styles.sliderText)}>FIGHT SOUND EFFECTS</p>
            <div className={classNames(styles.sliderInnerContainer)}>
              <FaVolumeDown className={classNames(styles.sliderLeftIcon)} />
              <input
                type="range"
                className={classNames(styles.slider)}
                value={soundEffectsVolume}
                onChange={(e) => setSoundEffectsVolume(e.target.value)}
                min={0}
                max={1}
                step={0.01}
              />
              <FaVolumeUp className={classNames(styles.sliderRightIcon)} />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classNames(styles.consoleButtonContainer)}
          onClick={() => setConsoleExpanded(true)}
        >
          {soundEnabled ? (
            <FaVolumeUp className={classNames(styles.consoleButtonIcon)} />
          ) : (
            <FaVolumeMute className={classNames(styles.consoleButtonIcon)} />
          )}
        </div>
      )}
    </div>
  );
};

export default SoundConsole;
