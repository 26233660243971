import React, { useCallback, useState, useEffect } from "react";
import { useLoader } from "@react-three/fiber";

import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
// import { sRGBEncoding } from "three";
/*
useLoader caches the loaded layers (objects) 
so if two bots have any one of the layers that are repeating,
it will only be loaded once and will wont show for one of the bots.
Therefore we use the legacy ThreeJs loading manager that comes with OBJLoader
*/

const Layer = ({ assetURL, updateStatusCb }) => {
  const [obj, set] = useState();

  const material = useLoader(MTLLoader, `${assetURL}.mtl`);
  material.preload();
  // material.materials.palette.map.encoding = sRGBEncoding;
  // material.materials.palette.map.anisotropy = 16;
  // material.materials.palette.needsUpdate = true;
  // console.log({
  //   map: material.materials.palette.map,
  //   material: material,
  // });
  const loadLayer = useCallback(() => {
    new OBJLoader()
      .setMaterials(material)
      .load(`${assetURL}.obj`, set, (xhr) => {
        if (xhr.loaded === xhr.total) updateStatusCb();
      });
  }, [assetURL, material, updateStatusCb]);

  useEffect(() => {
    loadLayer();
  }, [loadLayer]);
  if (obj)
    obj.children.forEach((elm) => {
      elm.castShadow = true;
      elm.receiveShadow = true;
    });
  return obj ? <primitive castShadow receiveShadow object={obj} /> : null;
};

export default Layer;

//old
// const materials = useLoader(MTLLoader, `${assetURL}.mtl`);
// const object = useLoader(
//   OBJLoader,
//   `${assetURL}.obj`,
//   (loader) => {
//     try {
//       materials.preload();
//       loader.setMaterials(materials);
//     } catch (err) {
//       console.log(err, "LAYER LOAD ERROR");
//     }
//   },
//   (xhr) => {
//     console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
//     if (xhr.loaded === xhr.total) updateStatusCb();
//   }
// );

// return <primitive object={object} />;
