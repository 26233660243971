import clx from "classnames";
import { APP_SOCIAL_LINKS } from "../../constant";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";
export const Footer = () => {
  return (
    <footer
      id="sticky-footer"
      className={clx("flex-shrink-0 py-4", "white-bg", "position-relative")}
    >
      <div className="footer-socials">
        <a
          href={APP_SOCIAL_LINKS.DISCORD}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          <BsDiscord color="#5865F2" />
        </a>
        <a
          href={APP_SOCIAL_LINKS.TWITTER}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          <FaTwitter color="#1DA1F2" />
        </a>{" "}
        <a
          href={APP_SOCIAL_LINKS.INSTAGRAM}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          <FaInstagram color="#E1306C" />
        </a>
        <a
          href={APP_SOCIAL_LINKS.OPENSEA}
          target="_blank"
          referrerPolicy="no-referrer"
          rel="noreferrer"
        >
          <img
            src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.svg"
            alt=""
          />
        </a>
      </div>
      <div className={clx("container text-center", "footer-wrap")}>
        Powered By Indorse
        <span className={clx("position-absolute", "terms-link")}>
          <a href="/terms">Terms And Conditions</a>
        </span>
      </div>
    </footer>
  );
};
