import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal,
  ModalBody,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  ButtonDropdown,
} from "reactstrap";
import {} from "axios";
import styles from "./SelectActions.module.scss";
import classNames from "classnames";
import BlockbotCard from "../../../components/BlockbotCard";
import ActionComponent from "../../../components/ActionsComponent";
import {
  resetSelectedActions,
  resetSelectedPowerups,
  setCurrentState,
  setSelectedActions,
  setSelectedPowerUps,
  updatePowerUps,
} from "../../../actions";
import { batch, useDispatch, useSelector } from "react-redux";
import useWeb3Methods from "../../../hooks/useWeb3Methods";
import {
  DAI_TOKEN_ADDRESS,
  FIGHT_TYPES,
  IND_TOKEN_ADDRESS,
  MAX_ACTION_SELECTIONS,
  MIN_DAI_POOL_REQUIRED,
  MIN_POOL_REQUIRED,
  MIN_POWERUPS_REQUIRED,
  MIN_USDC_POOL_REQUIRED,
  MIN_USDT_POOL_REQUIRED,
  SELECT_POWERUPS,
  USDC_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
} from "../../../constant";
import { convertAmountToUpperDenomBasedOnDecimal } from "../../../utils";
import remove from "lodash/remove";
import isEmpty from "lodash/isEmpty";
import { API_BASE_URL } from "../../../constant";
import axios from "axios";
import { Button } from "reactstrap";
// import { getToken } from "../../../auth";
import { ConfirmationModal } from "../ConfirmationModal";
import { LoadLoadout, LoadoutModal } from "../Loadout";
import { toast } from "react-toastify";
import apiProvider from "../../../providers/api";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { RecaptchaComponent } from "../../../utils";
import { RECAPTCHA_THRESHOLD } from "../../../constant";
import { ButtonPurplePrimary } from "../../../components/ButtonPurplePrimary";

const SelectActions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { account } = useWeb3Methods();
  const [inviteData, setInviteData] = useState({
    type: "",
    id: "",
  });
  const [currentInviteId, setCurrentInviteId] = useState("");
  const [hasActiveInvite, setHasActiveInvite] = useState(false);
  const [showShareInviteBtn, setShowShareInviteBtn] = useState(true);
  const [openLoadoutModal, toggleLoadoutModal] = useState(false);
  const [savedLoadouts, setSavedLoadouts] = useState(null);
  const [selectedLoadout, setSelectedLoadout] = useState(null);
  const [loadoutErrorMessage, setLoadoutErrorMessage] = useState("");
  const [isConfirmModalOpen, toggleConfirmModal] = useState(false);
  const [actionPowers, setActionPowers] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const [currentTokenSelect, setCurrentTokenSelect] = useState({
    name: "",
  });
  const [stakedTokenMenu, setStakedTokenMenu] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);
  const {
    actions: { isLoading, data: actionsData },
    powerups: { data: powerupData },
    selectedActions,
    selectedPowerups,
    selectedBlockbot,
    isOwner,
  } = useSelector((state) => state.wizard);

  const params = new URLSearchParams(window.location.search);
  const fightType = Object.fromEntries(params.entries()).fightType;

  const getSavedLoadouts = useCallback(async () => {
    const loadoutsData = await apiProvider.getActionsLoadout(account);
    const loadouts = loadoutsData?.loadouts || null;
    setSavedLoadouts(loadouts);
  }, [account]);

  useEffect(() => {
    getSavedLoadouts();
  }, [getSavedLoadouts]);

  useEffect(() => {
    const getStakedTokenBalances = async () => {
      const res = await apiProvider.getSpendingBalance(account);
      if (res.multiTokenBalances) {
        const indBalance = parseFloat(
          convertAmountToUpperDenomBasedOnDecimal(
            res.multiTokenBalances.find((item) => item.tokenName === "IND")
              .tokenBalance,
            18
          )
        );
        const usdcBalance = parseFloat(
          convertAmountToUpperDenomBasedOnDecimal(
            res.multiTokenBalances.find((item) => item.tokenName === "USDC")
              .tokenBalance,
            6
          )
        );
        const usdtBalance = parseFloat(
          convertAmountToUpperDenomBasedOnDecimal(
            res.multiTokenBalances.find((item) => item.tokenName === "USDT")
              .tokenBalance,
            6
          )
        );
        const daiBalance = parseFloat(
          convertAmountToUpperDenomBasedOnDecimal(
            res.multiTokenBalances.find((item) => item.tokenName === "DAI")
              .tokenBalance,
            18
          )
        );
        // const balance = convertAmountToUpperDenomBasedOnDecimal(
        //   res.indBalance.amount,
        //   res.indBalance.decimal
        // );
        const currentTokenMenu = [];
        if (indBalance >= MIN_POOL_REQUIRED) {
          currentTokenMenu.push("IND");
        }
        if (usdtBalance >= MIN_USDT_POOL_REQUIRED) {
          currentTokenMenu.push("USDT");
        }
        if (usdcBalance >= MIN_USDC_POOL_REQUIRED) {
          currentTokenMenu.push("USDC");
        }
        if (daiBalance >= MIN_DAI_POOL_REQUIRED) {
          currentTokenMenu.push("DAI");
        }
        setStakedTokenMenu([...currentTokenMenu]);
      }
    };

    getStakedTokenBalances();
  }, [account]);

  const isValid =
    selectedActions.filter((item) => !!item?.action).length ===
      MAX_ACTION_SELECTIONS &&
    selectedPowerups.filter((item) => !!item?.name).length > 0;

  const onSaveLoadout = async (loadoutName) => {
    if (!loadoutName) {
      setLoadoutErrorMessage("Please set a name for your loadout");
      return;
    }
    if (!isValid) {
      setLoadoutErrorMessage(
        "Please select a valid configuration for your loadout"
      );
      return;
    }
    try {
      setLoadoutErrorMessage("");
      const actions = selectedActions.map((elm) => {
        return elm.action === "FAST ATTACK" ? "FAST_ATTACK" : elm.action;
      });
      const reqBody = {
        name: loadoutName,
        actions,
        playerEthAddress: account,
      };
      if (selectedLoadout) reqBody._id = selectedLoadout._id;
      const res = await apiProvider.saveActionsLoadout(reqBody);
      if (res.status === 400) {
        const error = JSON.parse(res.data);
        setLoadoutErrorMessage(error?.errors[0].msg);
        throw new Error();
      }
      await getSavedLoadouts();
      toggleLoadoutModal(false);
      toast.success("Loadout Saved", { toastId: "SAVE_LOADOUT" });
    } catch (err) {
      toast.error("Cannot Save Loadout", { toastId: "FAIL_SAVE" });
    }
  };

  useEffect(() => {
    const getActiveInviteData = async () => {
      const { data } = await apiProvider.checkInviteStatus(
        account.toLowerCase()
      );
      if (data.isActiveInvite) {
        setCurrentInviteId(data.inviteId);
        setHasActiveInvite(true);
      }
    };
    const getInviteIdData = async (id) => {
      const { data } = await apiProvider.validateInvite(id);
      const tokenName = getTokenName(data.depositTokenAddress);
      if (
        tokenName === "IND" &&
        stakedTokenMenu.length &&
        !stakedTokenMenu.includes("IND")
      ) {
        toast.error(
          "You do not have enough balance to play this game. Please deposit at least 100 IND to enter.",
          {
            toastId: "FAIL_JOIN",
          }
        );
        return;
      } else if (
        stakedTokenMenu.length &&
        !stakedTokenMenu.includes(tokenName)
      ) {
        toast.error(
          `You do not have enough balance to play this game. Please deposit at least $3 worth of ${tokenName} to enter.`,
          {
            toastId: "FAIL_JOIN",
          }
        );
        return;
      }
      setCurrentTokenSelect({ name: getTokenName(data.depositTokenAddress) });
      if (data) {
        setInviteData(data);
      }
    };
    const inviteId = new URLSearchParams(window.location.search).get(
      "inviteId"
    );
    getActiveInviteData();
    if (inviteId) {
      getInviteIdData(inviteId);
      setShowShareInviteBtn(false);
    }
  }, [stakedTokenMenu]);

  const onLoadoutSelect = (loadout) => {
    if (loadout) {
      const { actions } = loadout;
      let actionsArr = [];
      for (let i = 0; i < actions.length; i++) {
        const actionKey =
          actions[i] === "FAST_ATTACK" ? "FAST ATTACK" : actions[i];
        actionsArr.push(
          actionsData.filter((elm) => elm.action === actionKey)[0]
        );
      }

      dispatch(setSelectedActions(actionsArr));
    } else
      dispatch(setSelectedActions(new Array(MAX_ACTION_SELECTIONS).fill(null)));
    setSelectedLoadout(loadout);
  };

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    const blockbotTokenId = selectedBlockbot.tokenId;
    const powerupIds = selectedPowerups.map((powerup) => powerup.tokenId);
    try {
      if (selectedPowerups) {
        axios
          .get(
            `${API_BASE_URL}/action-powers?tokenId=${blockbotTokenId}&powerups=[${powerupIds}]`
          )
          .then((response) => {
            if (
              response.data.actionPowers &&
              response.data.actionPowers.length
            ) {
              setActionPowers(response.data.actionPowers);
            }
          });
      }
      if (!selectedPowerups) {
        // setActionPowers(item.basePower);
      }
    } catch (error) {
      // setBasePower(item.basePower);
    }
  }, [selectedBlockbot.tokenId, selectedPowerups]);

  const onRemove = ({ name }) => {
    let p = [...selectedPowerups];
    let d = remove(p, (item) => item.name === name);
    if (isEmpty(d)) return;
    dispatch(
      setSelectedPowerUps(
        new Array(5).fill({}).map((_, idx) => {
          if (idx < p.length) {
            return p[idx];
          }
          return {};
        })
      )
    );
    dispatch(updatePowerUps([...powerupData, ...d]));
  };

  const onNextClick = () => {
    toggleConfirmModal((prevState) => !prevState);
  };

  const getTokenAddress = useCallback((tokenName) => {
    switch (tokenName) {
      case "IND":
        return IND_TOKEN_ADDRESS;
      case "USDT":
        return USDT_TOKEN_ADDRESS;
      case "USDC":
        return USDC_TOKEN_ADDRESS;
      case "DAI":
        return DAI_TOKEN_ADDRESS;
      default:
        return IND_TOKEN_ADDRESS;
    }
  }, []);

  const getTokenName = useCallback((tokenName) => {
    switch (tokenName) {
      case IND_TOKEN_ADDRESS:
        return "IND";
      case USDT_TOKEN_ADDRESS:
        return "USDT";
      case USDC_TOKEN_ADDRESS:
        return "USDC";
      case DAI_TOKEN_ADDRESS:
        return "DAI";
      default:
        return "IND";
    }
  }, []);

  const isElgibleBasedOnMatchType = async () => {
    const res = await apiProvider.getSpendingBalance(account);
    if (fightType === FIGHT_TYPES.ARENA) {
      if (
        res &&
        res.powerupBalance &&
        res.powerupBalance.length >= MIN_POWERUPS_REQUIRED
      ) {
        return true;
      }
      return false;
    }
    if (fightType === FIGHT_TYPES.BLOCKBOUT) {
      if (res && res.multiTokenBalances) {
        const balance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[0].tokenBalance,
          18
        );
        const usdtBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[1].tokenBalance,
          6
        );
        const usdcBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[2].tokenBalance,
          6
        );
        const daiBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[3].tokenBalance,
          18
        );
        if (
          balance >= MIN_POOL_REQUIRED ||
          usdtBalance >= MIN_USDT_POOL_REQUIRED ||
          usdcBalance >= MIN_USDC_POOL_REQUIRED ||
          daiBalance >= MIN_DAI_POOL_REQUIRED
        ) {
          return true;
        }
        return false;
      }
    }
    if (fightType === FIGHT_TYPES.EXHIBITION) {
      return true;
    }
  };
  const onConfirm = async () => {
    try {
      const token = await executeRecaptcha("confirm_actions");
      const reCaptchaResp = await apiProvider.verifyRecaptcha(token);
      const THRESHOLD = parseFloat(RECAPTCHA_THRESHOLD);
      if (reCaptchaResp && reCaptchaResp.score < THRESHOLD) {
        toast("You have been blocked due to too many requests", {
          toastId: "MAYBE_BOT",
        });
        throw new Error();
      }
      const inviteId = new URLSearchParams(window.location.search).get(
        "inviteId"
      );
      if (
        !currentTokenSelect.name.length &&
        !inviteId &&
        fightType !== FIGHT_TYPES.EXHIBITION &&
        fightType !== FIGHT_TYPES.ARENA
      ) {
        toast.error("Please select a token to fight with", {
          toastId: "NO_TOKEN",
        });
        toggleConfirmModal(false);
        setProgress(false);
        return;
      }
      setProgress(true);
      const isEligible = await isElgibleBasedOnMatchType();

      if (!isEligible) {
        throw new Error(
          "User not eligible for this match type. Please check if you have the required tokens in your game wallet."
        );
      }

      if (isEligible) {
        const body = {
          playerEthAddress: account,
          tokenId: selectedBlockbot.tokenId,
          matchType: fightType,
          actions: selectedActions.map((elm) => {
            return elm.action === "FAST ATTACK" ? "FAST_ATTACK" : elm.action;
          }),
          powerUps: selectedPowerups.filter(Boolean).map((elm) => elm.tokenId),
          isAlphaGame: false,
          isBookedBot: !isOwner,
          booker: !isOwner ? account : null,
          depositTokenAddress: getTokenAddress(currentTokenSelect.name),
        };
        const inviteId = new URLSearchParams(window.location.search).get(
          "inviteId"
        );
        if (inviteId) {
          body.inviteId = inviteId;
          toast.info("Redirecting to the game!", {
            toastId: "SEND_TO_GAME",
            hideProgressBar: true,
          });
          const { data } = await apiProvider.acceptInvite(body);
          if (data) {
            navigate(`/fight?id=${data.gameId}`, { replace: true });
          }
          return;
        }
        if (selectedLoadout) {
          await apiProvider.updateLoadoutFrequency(
            selectedLoadout["_id"],
            account.toLowerCase()
          );
        }
        const res = await apiProvider.getParticipantId(body);
        if (res.status === 400 || res.status === 420) {
          throw new Error(res?.data?.message);
        }
        const participantId = res?.data?.participant?._id;
        if (participantId) {
          toggleConfirmModal(false);
          navigate(`/waiting-room`, {
            state: { participantId, gameType: fightType },
          });
        }
      }
    } catch (error) {
      toggleConfirmModal(false);
      setProgress(false);
      toast.error(error.message || "An Error Ocurred!");
    }
  };

  const onBackClick = () => {
    batch(() => {
      dispatch(resetSelectedActions());
      dispatch(resetSelectedPowerups());
      dispatch(setCurrentState(SELECT_POWERUPS));
    });
  };

  const validateInviteId = async () => {
    const inviteId = new URLSearchParams(window.location.search).get(
      "inviteId"
    );
    if (inviteId) {
      const { data } = await apiProvider.validateInvite(inviteId);
      if (data.linkExpired) {
        return toast.error("Invite Id is already expired", {
          hideProgressBar: true,
        });
      } else onNextClick();
    } else onNextClick();
  };

  const getInviteLinkHandler = async () => {
    if (!currentTokenSelect.name.length && fightType !== FIGHT_TYPES.ARENA) {
      toast.error("Please select a token to fight with", {
        toastId: "NO_TOKEN",
      });
      return;
    }
    const body = {
      tokenId: selectedBlockbot.tokenId,
      matchType: fightType,
      powerUps: selectedPowerups.filter(Boolean).map((elm) => elm.tokenId),
      actions: selectedActions.map((elm) => {
        return elm.action === "FAST ATTACK" ? "FAST_ATTACK" : elm.action;
      }),
      playerEthAddress: account,
      depositTokenAddress: getTokenAddress(currentTokenSelect.name),
    };

    const { data } = await apiProvider.getInvite(body);
    setInviteModal(true);
    setCurrentInviteId(data.invite["_id"]);
    setInviteData({
      type: data.invite.matchType,
      id: data.invite["_id"],
    });
    setShowShareInviteBtn(false);
  };

  const cancelActiveInvite = async () => {
    toast.info("Cancelling invite...", {
      toastId: "CANCEL_INVITE",
      hideProgressBar: true,
    });
    try {
      const { data, status } = await apiProvider.cancelInvite(currentInviteId);
      if (status === 400) {
        toast.error("Invite was already expired", {
          toastId: "INVITE_EXPIRED",
          hideProgressBar: true,
        });
      }
      if (data) {
        toast.dismiss();
        toast.info("Invite cancelled!", {
          toastId: "INVITE_CANCELLED",
          hideProgressBar: true,
        });
        setCurrentInviteId("");
        setHasActiveInvite(false);
        setShowShareInviteBtn(true);
      }
    } catch (err) {
      toast.dismiss();
      console.log(err);
    }
  };

  return (
    <>
      <Modal className="confirm-modal-wrapper" centered isOpen={inviteModal}>
        <ModalBody>
          <div className="modal-invite-title">Share Invite Link</div>
          <div className="modal-invite-link">
            <p>
              {`${
                window.location.hostname === "localhost"
                  ? "http://"
                  : "https://"
              }${window.location.host}/select?fightType=${
                inviteData.type
              }&inviteId=${inviteData.id}`}
            </p>
            <img
              src="../../../../icons/invite-link-icon.svg"
              alt="invite-img-icon"
              onClick={() => {
                toast.info("Copied to clipboard", {
                  position: "bottom-center",
                  hideProgressBar: true,
                });
                navigator.clipboard.writeText(
                  `${
                    window.location.hostname === "localhost"
                      ? "http://"
                      : "https://"
                  }${window.location.host}/select?fightType=${
                    inviteData.type
                  }&inviteId=${inviteData.id}`
                );
              }}
            />
          </div>
          <div className="confirm-modal-actions">
            <Button
              onClick={() => setInviteModal(false)}
              className="primary-blue-btn"
            >
              Done
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <div className="wizard-heading-container">
        <div className="step-container">
          <div className="step-current">STEP 3</div>
          <div className="step-desc">Choose your actions</div>
        </div>
        <div className={styles.navBtnCont}>
          <div className={styles.LoadoutButtonsContainer}>
            {savedLoadouts &&
              savedLoadouts.slice(0, 3).map((item, index) => (
                <Button
                  key={index}
                  className={classNames("outline-btn")}
                  style={{
                    marginRight: 10,
                  }}
                  onClick={() => {
                    onLoadoutSelect(item);
                    setSelectedLoadout(item);
                  }}
                >
                  {item.name.length <= 6
                    ? item.name
                    : `${item.name.substring(0, 4)}...
                  ${item.name.substring(item.name.length - 1)}`}
                </Button>
              ))}
          </div>
          <LoadLoadout
            menuHeader="Actions Loadout"
            selectedLoadout={selectedLoadout}
            onSelect={onLoadoutSelect}
            menuItems={savedLoadouts}
            loadoutType="ACTION"
            getSavedLoadouts={getSavedLoadouts}
            setSelectedLoadout={setSelectedLoadout}
          />
          {fightType !== FIGHT_TYPES.EXHIBITION &&
            fightType !== FIGHT_TYPES.ARENA && (
              <LoadLoadout
                menuHeader="Token List"
                loadoutType="TOKEN"
                nonMutable={true}
                loadoutName={currentTokenSelect}
                selectedLoadout={currentTokenSelect}
                onSelect={(item) => setCurrentTokenSelect({ name: item.name })}
                menuItems={stakedTokenMenu.map((item) => ({
                  name: item,
                }))}
              />
            )}
          <div className={classNames(styles.RoundButtonContainer)}>
            <div className={classNames(styles.VerticalDiv)}></div>
            <Button
              onClick={() => toggleLoadoutModal(true)}
              className={classNames("white-btn", "round-btn")}
              disabled={!isValid}
              style={{
                height: "40px",
              }}
            >
              <img
                src="../../../../images/save-icon.png"
                alt="link-img"
                width={18}
              />
            </Button>
            {showShareInviteBtn && currentInviteId.length === 0 && (
              <Button
                onClick={() => {
                  if (selectedActions[0] === null) {
                    toast.error("Please select an action first!", {
                      autoClose: 1400,
                      hideProgressBar: true,
                    });
                    return;
                  }
                  getInviteLinkHandler();
                }}
                className={classNames("white-btn", "round-btn")}
                style={{
                  height: "40px",
                }}
              >
                <img
                  src="../../../../images/link-img.png"
                  alt="link-img"
                  width={18}
                />
              </Button>
            )}
          </div>
          {currentInviteId.length > 0 && (
            <Button
              onClick={cancelActiveInvite}
              className={classNames("red-btn")}
            >
              <p>Cancel Match Invite</p>
            </Button>
          )}
          <button onClick={onBackClick} className="arrow-btn-styles">
            <img
              src="../../../../icons/left-arrow.svg"
              alt="right-arrow"
              height={40}
              width={40}
            />
          </button>
          <button
            onClick={async () => {
              if (currentInviteId.length > 0) {
                toast.error("Please cancel the active invite to proceed", {
                  autoClose: 1400,
                  hideProgressBar: true,
                });
                return;
              }

              await validateInviteId();
            }}
            disabled={!isValid}
            className="arrow-btn-styles"
            style={{
              opacity: isValid ? 1 : 0.5,
              marginLeft: "-10px",
            }}
          >
            <img
              src="../../../../icons/right-arrow.svg"
              alt="right-arrow"
              height={40}
              width={40}
            />
          </button>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <BlockbotCard
          item={selectedBlockbot}
          selectedPowerups={selectedPowerups}
        />
        <ActionComponent
          actions={actionsData}
          selectedActions={selectedActions}
          setSelectedActions={(item) => dispatch(setSelectedActions(item))}
          isLoading={isLoading}
          onRemove={onRemove}
          actionPowers={actionPowers}
        />
        {isConfirmModalOpen && (
          <ConfirmationModal
            isOpen={isConfirmModalOpen}
            onCancel={() => {
              toggleConfirmModal(false);
            }}
            inProgress={inProgress}
            onConfirm={onConfirm}
            fightType={fightType}
          />
        )}
      </div>
      {openLoadoutModal && (
        <LoadoutModal
          onClose={() => {
            toggleLoadoutModal(false);
            setLoadoutErrorMessage("");
          }}
          isOpen={openLoadoutModal}
          onSave={onSaveLoadout}
          selectedLoadout={selectedLoadout}
          modalTitle="Save Actions Loadout"
          errorMessage={loadoutErrorMessage}
        />
      )}
    </>
  );
};

export default RecaptchaComponent(SelectActions);
// export default SelectActions;
