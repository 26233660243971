import React, { useCallback, useEffect, useState } from "react";
import styles from "./BlockBouts.module.scss";
import classnames from "classnames";
import { Alert, Button } from "reactstrap";
import {
  BLOCK_BOUT_MATCH_TYPE,
  BLOCK_BOUTS_FIGHT_WIZARD_PAGE,
  BLOCK_BOUTS_WALLET_PAGE,
  MIN_POWERUPS_REQUIRED,
  MIN_USDC_POOL_REQUIRED,
  MIN_USDT_POOL_REQUIRED,
  MIN_DAI_POOL_REQUIRED,
} from "../../constant";
import WithWeb3 from "../../HOC/withWeb3";
import { useNavigate, createSearchParams } from "react-router-dom";
import { getToken } from "../../auth";
import { APP_SOCIAL_LINKS } from "../../constant";
import apiProvider from "../../providers/api";
import { toast } from "react-toastify";
import { useSpenderWalletContract } from "../../hooks/useSpenderWalletContract";
import { PoolModal } from "./PoolModal";
import { convertAmountToUpperDenomBasedOnDecimal } from "../../utils";
import { MIN_POOL_REQUIRED } from "../../constant";
import { useDispatch, useSelector, batch } from "react-redux";
import { fetchBlockbotDetails, resetWizard } from "../../actions";
import { selectBlockbot } from "../../actions";
import C3POImage from "../../images/C3POBlockbot.png";
import { useBlockbots } from "../../hooks/useBlockbots";

const BlockBouts = ({ loading, account }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getBalance } = useBlockbots();
  const { balanceOfIND } = useSpenderWalletContract();
  const [spenderWalletBalance, setSpenderWalletBalance] = useState({
    IND: 0,
    USDT: 0,
    USDC: 0,
    DAI: 0,
  });
  const [spenderWalletPowerupsBalance, setSpenderWalletPowerupsBalance] =
    useState(null);
  const [walletModalVisibility, setWalletModalVisibility] = useState(false);
  const [walletModalFightType, setWalletModalFightType] = useState(null);
  const [spenderWalletLoading, setSpenderWalletLoading] = useState(false);
  const [totalNumberOfBlockbots, setTotalNumberOfBlockbots] = useState(null);
  const [numberOfBlockbotsLoading, setNumberOfBlockbotsLoading] =
    useState(false);

  useEffect(() => {
    if (!account) return;
    dispatch(resetWizard());

    const fetchTotalNumberOfBlockbots = async () => {
      await dispatch(selectBlockbot({}));
      setNumberOfBlockbotsLoading(true);
      const res = await getBalance();
      setTotalNumberOfBlockbots(parseInt(res));
      setNumberOfBlockbotsLoading(false);
    };

    fetchTotalNumberOfBlockbots();
  }, [account, dispatch, getBalance]);

  useEffect(() => {
    const getSpenderWalletBalance = async () => {
      setSpenderWalletLoading(true);
      const res = await apiProvider.getSpendingBalance(account);
      if (res.multiTokenBalances) {
        const indBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[0].tokenBalance,
          18
        );
        const usdtBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[1].tokenBalance,
          6
        );
        const usdcBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[2].tokenBalance,
          6
        );
        const daiBalance = convertAmountToUpperDenomBasedOnDecimal(
          res.multiTokenBalances[3].tokenBalance,
          18
        );
        // const balance = convertAmountToUpperDenomBasedOnDecimal(
        //   res.indBalance.amount,
        //   res.indBalance.decimal
        // );
        setSpenderWalletBalance({
          IND: indBalance.toString(),
          USDT: usdtBalance.toString(),
          USDC: usdcBalance.toString(),
          DAI: daiBalance.toString(),
        });
      }
      if (res.powerupBalance) {
        setSpenderWalletPowerupsBalance(res.powerupBalance);
      }
      setSpenderWalletLoading(false);
    };
    if (account) {
      getSpenderWalletBalance();
    }
  }, [account, balanceOfIND]);

  const verifyAndNavigate = useCallback(
    async (type) => {
      try {
        const token = await getToken();
        if (token) {
          navigate({
            pathname: BLOCK_BOUTS_FIGHT_WIZARD_PAGE,
            search: createSearchParams({ fightType: type }).toString(),
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [navigate]
  );

  const checkIfEligibleForBlockBouts = useCallback(
    async (type) => {
      if (
        account &&
        spenderWalletBalance &&
        (spenderWalletBalance.IND >= MIN_POOL_REQUIRED ||
          spenderWalletBalance.USDC >= MIN_USDC_POOL_REQUIRED ||
          spenderWalletBalance.USDT >= MIN_USDT_POOL_REQUIRED ||
          spenderWalletBalance.DAI >= MIN_DAI_POOL_REQUIRED)
      ) {
        verifyAndNavigate(type);
      } else {
        setWalletModalVisibility(true);
        setWalletModalFightType(type);
      }
    },
    [account, spenderWalletBalance, verifyAndNavigate]
  );

  const checkIfEligibleForArena = useCallback(
    async (type) => {
      if (
        account &&
        spenderWalletPowerupsBalance &&
        spenderWalletPowerupsBalance.length >= MIN_POWERUPS_REQUIRED
      ) {
        verifyAndNavigate(type);
      } else {
        setWalletModalVisibility(true);
        setWalletModalFightType(type);
      }
    },
    [account, spenderWalletPowerupsBalance, verifyAndNavigate]
  );

  const onMatchSelect = useCallback(
    async (type) => {
      switch (type) {
        case "BLOCKBOUT":
          await checkIfEligibleForBlockBouts(type);
          return;
        case "ARENA":
          await checkIfEligibleForArena(type);
          return;
        case "EXHIBITION":
          await verifyAndNavigate(type);
          return;
        default:
          setWalletModalVisibility(true);
          setWalletModalFightType(type);
          return;
      }
    },
    [checkIfEligibleForArena, checkIfEligibleForBlockBouts, verifyAndNavigate]
  );

  if (loading)
    return (
      <div className="loader">
        <div
          className="spinner-border"
          role="status"
          style={{ color: "mediumpurple" }}
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  return (
    <>
      {!totalNumberOfBlockbots && !numberOfBlockbotsLoading && (
        <Alert className="banner-container">
          <img src={C3POImage} alt="C3PO Blockbot" />
          {`You need to have a BlockBot to enter one of our game modes.`}
          <a
            href={APP_SOCIAL_LINKS.OPENSEA}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button onClick={() => {}}>Get Your Blockbot</Button>
          </a>
        </Alert>
      )}
      <div className={styles.selectFightContainer}>
        <div className={styles.selectFightTypeTitle}>
          <h1>Choose a type of fight</h1>
        </div>
        <div className={classnames(styles.fightTypesContainer, "container")}>
          {spenderWalletLoading || numberOfBlockbotsLoading ? (
            <div className={classnames("loader", styles.spinnerContainer)}>
              <div
                className="spinner-border"
                role="status"
                style={{ color: "#311e86" }}
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            BLOCK_BOUT_MATCH_TYPE.map((item, idx) => {
              return (
                <ExpandableCard
                  onClick={() => {
                    onMatchSelect(item.type);
                  }}
                  title={item.title}
                  key={idx}
                  imgSrc={item.image}
                  index={idx + 1}
                  bullets={item.bullets}
                  desc={item.description}
                  isLive={item.isLive}
                  totalNumberOfBlockbots={totalNumberOfBlockbots}
                />
              );
            })
          )}
        </div>
      </div>
      {walletModalVisibility && (
        <PoolModal
          fightType={walletModalFightType}
          isOpen={walletModalVisibility}
          onCancel={() => setWalletModalVisibility(false)}
          onConfirm={() => {
            navigate({
              pathname: BLOCK_BOUTS_WALLET_PAGE,
              // search: createSearchParams({ fightType: type }).toString(),
            });
            // window.open(STAKING_URL, "_blank", "noopener,noreferrer");
            setWalletModalVisibility(false);
          }}
        />
      )}
    </>
  );
};

const ExpandableCard = ({
  imgSrc,
  onClick,
  title,
  bullets,
  desc,
  isLive,
  totalNumberOfBlockbots,
}) => {
  return (
    <div
      onClick={isLive && totalNumberOfBlockbots > 0 ? onClick : () => {}}
      className={classnames(
        "expandable-card-container",
        (!isLive || !(totalNumberOfBlockbots > 0)) && "no-event"
        // "card-sm"
      )}
    >
      {!isLive && (
        <div className="ribbon-wrapper">
          <div className="ribbon">Back Soon</div>
        </div>
      )}
      <div className="card-heading-container">
        <div className="card-heading">{title}</div>
      </div>
      <div className="card-img-container">
        <img src={imgSrc} alt="" />
      </div>
      <div className="card-details-container">
        <div className={classnames("card-pointers", "justify-content-center")}>
          {bullets.map((elm, idx) => {
            return (
              <div
                key={idx}
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  src={elm.img}
                  alt=""
                  className={classnames("card-bullet-image")}
                />
                {elm.title}
              </div>
            );
          })}
        </div>
        <div className="card-desc">{desc}</div>
      </div>
    </div>
  );
};

export default WithWeb3(BlockBouts);
