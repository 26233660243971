/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import React from "react";
import { RECAPTCHA_KEY } from "../constant";

const RecaptchaComponent = (Component) => {
  const Recaptcha = ({ children }) => {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
        {children}
      </GoogleReCaptchaProvider>
    );
  };
  return class Higher extends React.Component {
    render() {
      return (
        <Recaptcha>
          <Component {...this.props} />
        </Recaptcha>
      );
    }
  };
};
export { RecaptchaComponent };
