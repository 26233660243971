import React, { useEffect, useState, useCallback, useMemo } from "react";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import BlockbotHUDStats from "./BlockbotHUDStats";
import CurrentAction from "./CurrentAction";
import CurrentRound from "./CurrentRound";
import SoundConsole from "./SoundConsole";
import apiProvider from "../../providers/api";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { parseGameDetails } from "../../utils";
import { PostMatchStatus } from "./PostMatchStatus";
import { Scene } from "./Scene";
import styles from "./Fight.module.scss";
import { useStore } from "./fightState";

const Fight = () => {
  const [search] = useSearchParams();
  const gameID = search.get("id");
  const [errorMessage, setErrorMessage] = useState("");
  const [fightInfo, setFightInfo] = useState({
    loading: true,
    rounds: [],
    selectedBlockbot: {},
    opponentBlockbot: {},
    winnerBotKey: null,
    selectedBotBasePowers: [],
    opponentBotBasePowers: [],
    participant1EthAddress: null,
    participant2EthAddress: null,
    arena: null,
    winnerEthAddress: null,
    matchType: null,
    player1Name: "",
    player2Name: "",
  });
  const [gamesPlayed, setGamesPlayed] = useState(0);
  const windowDimensions = useWindowDimensions();
  const [matchEnded, setMatchEnded] = useState(false);
  const [matchStarted, setMatchStarted] = useState(false);

  const [botLoadingStatus, setBotLoadingStatus] = useState({
    botOneLoaded: false,
    botTwoLoaded: false,
  });

  const { account } = useWeb3React();

  const { currentRound, inc } = useStore();

  useEffect(() => {
    //TODO Fix for initial load on mobile
    if (windowDimensions.windowWidth < 1200) {
      setErrorMessage(
        "The viewing of this game is currently supported only on higher resolution screens. Please use a desktop browser to experience the game."
      );
    }

    //TODO fix such that this does not override the game details not found error message just because window is desktop
    if (windowDimensions.windowWidth > 1200) {
      setErrorMessage("");
    }
  }, [windowDimensions, windowDimensions.windowWidth, errorMessage]);

  const getPlayerStats = useCallback(async () => {
    if (account) {
      try {
        const { gamesPlayed } = await apiProvider.getPlayerStats(account);
        // console.log(gamesPlayed);
        setGamesPlayed(gamesPlayed);
      } catch (err) {
        console.log(err);
      }
    }
  }, [account]);

  useEffect(() => {
    getPlayerStats();
  }, [getPlayerStats]);

  const fetchGameDetails = useCallback(async () => {
    try {
      // setErrorMessage("");
      const data = await apiProvider.getGameDetails(gameID);
      //TODO Ask backend to provide a better payload to handle non-existent games and integrate on Frontend
      if (data?.message === "Game details not found!") {
        setErrorMessage("Game details not found!");
      }
      const {
        selectedBotTokenId,
        oponentBotTokenId,
        winnerBotKey,
        rounds,
        selectedBotBasePowers,
        opponentBotBasePowers,
        participant1EthAddress,
        participant2EthAddress,
        arena,
        winnerEthAddress,
        matchType,
        playedWithBot,
        player1Name,
        player2Name,
      } = parseGameDetails(data);

      const selectedBlockbot = await apiProvider.getBlockbotDetails(
        selectedBotTokenId
      );
      const opponentBlockbot = await apiProvider.getBlockbotDetails(
        oponentBotTokenId
      );
      setFightInfo((prevState) => ({
        ...prevState,
        selectedBlockbot,
        opponentBlockbot,
        winnerBotKey,
        rounds,
        selectedBotBasePowers,
        opponentBotBasePowers,
        participant1EthAddress,
        participant2EthAddress,
        loading: false,
        arena,
        winnerEthAddress,
        matchType,
        playedWithBot,
        player1Name,
        player2Name,
      }));
    } catch (error) {
      console.log(error, "error");
    }
  }, [gameID]);

  useEffect(() => {
    fetchGameDetails();
    // resetRound(); //to reset round for persisiting state
  }, [fetchGameDetails]);

  const INTERVAL = 3500; // Interval between rounds in milliseconds

  const {
    selectedBlockbot,
    opponentBlockbot,
    rounds,
    // winner,
    // NUMBER_OF_ROUNDS,
    loading,
    selectedBotBasePowers,
    opponentBotBasePowers,
    participant1EthAddress,
    participant2EthAddress,
    arena,
    winnerBotKey,
    winnerEthAddress,
  } = fightInfo;
  const winner = winnerBotKey ? fightInfo[winnerBotKey].name : winnerBotKey;
  const NUMBER_OF_ROUNDS = rounds
    .map((elm) => elm.action1)
    .filter(Boolean).length;

  useEffect(() => {
    const { botOneLoaded, botTwoLoaded } = botLoadingStatus;
    if (loading || !botOneLoaded || !botTwoLoaded) return;
    const interval = setInterval(() => {
      inc();
      setMatchStarted(true);
    }, INTERVAL);

    if (currentRound > NUMBER_OF_ROUNDS) {
      clearInterval(interval);
      setMatchEnded(true);
    }
    return () => clearInterval(interval);
  }, [NUMBER_OF_ROUNDS, botLoadingStatus, currentRound, inc, loading]);

  const { botOneAction, botTwoAction } = useMemo(() => {
    return {
      botOneAction:
        (rounds && rounds.length && rounds[currentRound]?.action1) || null,
      botTwoAction:
        (rounds && rounds.length && rounds[currentRound]?.action2) || null,
    };
  }, [currentRound, rounds]);

  const memoizedHUD = useMemo(() => {
    if (!loading && !matchEnded) {
      const currentAccount = account && account.toLowerCase();
      return (
        <div className={classNames(styles.headsUpDisplayContainer)}>
          <BlockbotHUDStats
            blockbot={selectedBlockbot}
            health={(rounds.length && rounds[currentRound]?.health1) || 0}
            max_health={(rounds.length && rounds[0].health1) || 0}
            basePowers={selectedBotBasePowers}
            isCurrentOwner={currentAccount === participant1EthAddress}
          />
          <BlockbotHUDStats
            blockbot={opponentBlockbot}
            inverted
            health={(rounds.length && rounds[currentRound]?.health2) || 0}
            max_health={(rounds.length && rounds[0].health2) || 0}
            basePowers={opponentBotBasePowers}
            isCurrentOwner={currentAccount === participant2EthAddress}
          />
        </div>
      );
    }
    return null;
  }, [
    account,
    currentRound,
    loading,
    matchEnded,
    opponentBlockbot,
    opponentBotBasePowers,
    participant1EthAddress,
    participant2EthAddress,
    rounds,
    selectedBlockbot,
    selectedBotBasePowers,
  ]);

  const setBotLoadedStatus = useCallback((botKey) => {
    setBotLoadingStatus((prevState) => {
      return {
        ...prevState,
        [botKey]: true,
      };
    });
  }, []);

  const memoizedScene = useMemo(
    () => (
      <Scene
        selectedBlockbot={selectedBlockbot}
        opponentBlockbot={opponentBlockbot}
        arena={arena}
        setLoadingComplete={setBotLoadedStatus}
        rounds={rounds}
        loading={loading}
      />
    ),
    [
      selectedBlockbot,
      opponentBlockbot,
      arena,
      setBotLoadedStatus,
      rounds,
      loading,
    ]
  );

  const memoizedSoundConsole = useMemo(() => {
    const currentAccount = account && account.toLowerCase();
    return (
      <SoundConsole
        selectedBlockbotAction={botOneAction}
        opponentBlockbotAction={botTwoAction}
        matchStarted={matchStarted}
        matchEnded={matchEnded}
        isCurrentUserWinner={currentAccount === winnerEthAddress}
        account={account}
      />
    );
  }, [
    account,
    botOneAction,
    botTwoAction,
    matchEnded,
    matchStarted,
    winnerEthAddress,
  ]);

  if (errorMessage)
    return (
      <div className={classNames(styles.errorContainer)}>
        <img
          src="./images/BB_Head.png"
          alt=""
          className={classNames(styles.errorImage)}
        />
        <p className={classNames(styles.errorTitle)}>
          Oops! Looks like something went wrong!
        </p>
        <p className={classNames(styles.errorMessage)}>{errorMessage}</p>
      </div>
    );

  return (
    <div className={classNames(styles.fightPageContainer)}>
      <div className={classNames(styles.canvasContainer)}>
        {memoizedHUD}
        {rounds && currentRound <= NUMBER_OF_ROUNDS ? (
          <div>
            <CurrentRound currentRound={currentRound} />
            <CurrentAction action={botOneAction} />
            <CurrentAction action={botTwoAction} inverted />
          </div>
        ) : null}
        {memoizedSoundConsole}
        {memoizedScene}
      </div>
      {matchEnded && (
        <PostMatchStatus
          winner={winner}
          rounds={rounds}
          botOne={selectedBlockbot}
          botTwo={opponentBlockbot}
          isOpen={matchEnded}
          account={account}
          participant1EthAddress={participant1EthAddress}
          participant2EthAddress={participant2EthAddress}
          gamesPlayed={gamesPlayed}
          matchType={fightInfo.matchType}
          playedWithBot={fightInfo.playedWithBot}
          player1Name={fightInfo.player1Name}
          player2Name={fightInfo.player2Name}
        />
      )}
    </div>
  );
};

export default Fight;
