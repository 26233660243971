import useContract from "./useContract";
import { powerUps as ABI } from "../abi/powerUps";
import { SPENDING_WALLET_ADDRESS, POWERUPS_ADDRESS } from "../constant";
import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";

export function usePowerupsContract() {
  const contract = useContract(POWERUPS_ADDRESS, ABI.abi);
  const { account } = useWeb3React();

  const setApprovalForAll = useCallback(async () => {
    const trx = await contract.setApprovalForAll(
      SPENDING_WALLET_ADDRESS,
      true,
      {
        gasLimit: "100000",
      }
    );
    await trx.wait();
  }, [contract]);

  const isApprovedForAll = useCallback(async () => {
    const trx = await contract.isApprovedForAll(
      account,
      SPENDING_WALLET_ADDRESS,
      {
        gasLimit: "100000",
      }
    );
    return trx;
  }, [contract, account]);

  return useMemo(() => {
    return {
      setApprovalForAll,
      isApprovedForAll,
    };
  }, [setApprovalForAll, isApprovedForAll]);
}
