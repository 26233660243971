import io from "socket.io-client";
import { useEffect, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../constant";
import { useLocation } from "react-router-dom";
import styles from "./WaitingRoom.module.scss";
import { resetWizard } from "../../actions";
import { useDispatch } from "react-redux";
import { BasicLoader } from "../../components/Loader";
import { useStore } from "../Fight/fightState";

let botTimer;
const WaitingRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [showNotifAi, setShowNotifAi] = useState(false);
  const { participantId, gameType } = location.state;
  const { resetRound } = useStore();
  const sendJoinGameEvent = useCallback(
    (socket) => {
      socket.emit("join-game", { participantId, gameType }, (res) => {
        console.log(res);
      });
    },
    [gameType, participantId]
  );

  const matchWithBotEvent = useCallback(
    (socket) => {
      socket.emit("match-bot", { participantId, gameType }, (res) => {
        console.log("Match bot response: ", res);
      });
    },
    [gameType, participantId]
  );

  useEffect(() => {
    dispatch(resetWizard());
    resetRound();
    const newSocket = io.connect(API_BASE_URL, {
      reconnectionDelayMax: 10000,
      reconnectionAttempts: 5,
      transports: ["websocket"],
    });
    sendJoinGameEvent(newSocket);
    // timer 30sec
    botTimer = setTimeout(() => {
      matchWithBotEvent(newSocket);
      setShowNotifAi(true);
    }, 60000);

    newSocket.on("matched", (message) => {
      clearTimeout(botTimer);
      navigate(`/fight?id=${message.gameId}`, { replace: true });
    });

    newSocket.on("error", (error) => {
      console.log(error);
      clearTimeout(botTimer);
      botTimer = undefined;
    });

    return () => newSocket.close();
  }, [dispatch, matchWithBotEvent, navigate, resetRound, sendJoinGameEvent]);

  return (
    <div className={styles.waitingRoomContainer}>
      <div className={styles.waitingRoomTitle}>Waiting Room</div>
      <div className={styles.waitingRoomSub}>
        {showNotifAi ? (
          <BasicLoader loaderText="You are getting matched with an AI Overlord Bot. Hang tight" />
        ) : (
          <BasicLoader loaderText="Finding you an opponent. Hang tight" />
        )}
      </div>
      <div className={styles.waitingRoomImg}>
        <img
          src="https://blockbots-s3.s3.ap-southeast-1.amazonaws.com/waiting.png"
          alt=""
        />
      </div>
    </div>
  );
};

export default WaitingRoom;
