import useContract from "./useContract";
import { iERC20 as ABI } from "../abi/ierc20";
import {
  IND_TOKEN_ADDRESS,
  USDT_TOKEN_ADDRESS,
  USDC_TOKEN_ADDRESS,
  DAI_TOKEN_ADDRESS,
  SPENDING_WALLET_ADDRESS,
  OLD_SPENDING_WALLET_ADDRESS,
  isPROD,
} from "../constant";
import { useCallback, useMemo } from "react";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import web3 from "web3";
import { convertAmountToLowerDenomBasedOnDecimal } from "../utils";

export function useINDContract() {
  const contract = useContract(IND_TOKEN_ADDRESS, ABI.abi);
  const usdtContract = useContract(USDT_TOKEN_ADDRESS, ABI.abi);
  const usdcContract = useContract(USDC_TOKEN_ADDRESS, ABI.abi);
  const daiContract = useContract(DAI_TOKEN_ADDRESS, ABI.abi);
  const { account } = useWeb3React();

  const getGasFee = async () => {
    try {
      let res = await axios.get(
        isPROD
          ? "https://gasstation-mainnet.matic.network/"
          : "https://gasstation-mainnet.matic.today/v2"
      );
      return isPROD ? res.data.fast : res.data.fast;
    } catch (err) {
      return 100000;
    }
  };

  const getINDBalance = useCallback(async () => {
    const balance = await contract.balanceOf(account);
    return parseFloat(balance.toString()) / 10 ** 18;
  }, [account, contract]);

  const getUSDTBalance = useCallback(async () => {
    const balance = await usdtContract.balanceOf(account);
    return parseFloat(balance.toString()) / 10 ** 6;
  }, [account, usdtContract]);

  const getUSDCBalance = useCallback(async () => {
    const balance = await usdcContract.balanceOf(account);
    return parseFloat(balance.toString()) / 10 ** 6;
  }, [account, usdcContract]);

  const getDAIBalance = useCallback(async () => {
    const balance = await daiContract.balanceOf(account);
    return parseFloat(balance.toString()) / 10 ** 18;
  }, [account, daiContract]);

  const checkAllowance = useCallback(async () => {
    const allowance = await contract.allowance(
      account,
      SPENDING_WALLET_ADDRESS
    );
    return allowance;
  }, [account, contract]);

  const tokenDecimalsValue = useCallback((name) => {
    switch (name) {
      case "IND":
        return 18;
      case "USDT":
        return 6;
      case "USDC":
        return 6;
      case "DAI":
        return 18;
      default:
        return 18;
    }
  }, []);

  const approveOlderTokenContract = useCallback(
    async (approvingAmount) => {
      const data = await getGasFee();
      const amount = convertAmountToLowerDenomBasedOnDecimal(
        approvingAmount,
        18
      );
      const amountToBePooled = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });
      const gasPrice = web3.utils.toWei(data.toFixed(2), "gwei");

      const trx = await contract.approve(
        OLD_SPENDING_WALLET_ADDRESS,
        amountToBePooled,
        {
          gasLimit: "100000",
          gasPrice: gasPrice,
        }
      );

      await trx.wait();
    },
    [contract]
  );

  const getApproval = useCallback(
    async (depositAmount, tokenName = "IND") => {
      const amount = convertAmountToLowerDenomBasedOnDecimal(
        depositAmount,
        tokenDecimalsValue(tokenName)
      );
      const amountToBePooled = Number(amount).toLocaleString("fullwide", {
        useGrouping: false,
      });

      const data = await getGasFee();
      const gasPrice = web3.utils.toWei(data.toFixed(2), "gwei");

      // if (amountToBePooled.gt(balance)) {
      //   toast("You don't have sufficient IND Tokens", {
      //     toastId: "LOW_BALANCE",
      //   });
      //   throw new Error("Low Balance");
      // }

      // const allowed = await checkAllowance();
      // if (amountToBePooled.gt(allowed)) {
      let trx;
      if (tokenName === "IND") {
        trx = await contract.approve(
          SPENDING_WALLET_ADDRESS,
          amountToBePooled,
          {
            gasLimit: "300000",
            gasPrice: gasPrice,
          }
        );

        await trx.wait();
      } else if (tokenName === "USDT") {
        trx = await usdtContract.approve(
          SPENDING_WALLET_ADDRESS,
          amountToBePooled,
          {
            gasLimit: "300000",
          }
        );

        await trx.wait();
      } else if (tokenName === "USDC") {
        trx = await usdcContract.approve(
          SPENDING_WALLET_ADDRESS,
          amountToBePooled,
          {
            gasLimit: "300000",
          }
        );

        await trx.wait();
      } else if (tokenName === "DAI") {
        trx = await daiContract.approve(
          SPENDING_WALLET_ADDRESS,
          amountToBePooled,
          {
            gasLimit: "300000",
          }
        );

        await trx.wait();
      }

      return amountToBePooled;
    },
    [contract, daiContract, tokenDecimalsValue, usdcContract, usdtContract]
  );

  return useMemo(() => {
    return {
      getUserBalance: async () => {
        return await getINDBalance();
      },
      // getUserUSDTBalance: async() => {
      //   return await getUSDTBalance();
      // }
      getApproval,
      getUSDTBalance,
      getDAIBalance,
      getUSDCBalance,
      approveOlderTokenContract,
    };
  }, [
    getApproval,
    getUSDTBalance,
    getDAIBalance,
    getUSDCBalance,
    getINDBalance,
    approveOlderTokenContract,
  ]);
}
