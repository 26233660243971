import React, { useMemo } from "react";
import styles from "./StatCard.module.scss";
import classNames from "classnames";
import {
  IMAGE_MAP_BLOCKBOT_BASE_ATTRIBUTE,
  BLOCKBOT_BASE_ATTRIBUTE,
  APP_LABELS,
  BLOCKBOT_BASE_ATTRIBUTE_SHORT,
} from "../../constant";

import { UncontrolledTooltip } from "reactstrap";

export const PwrUpStatCard = ({
  label,
  value,
  specialEffectClan,
  specialEffect,
}) => {
  return (
    <div className={classNames("pu-stat-card")}>
      <span className="d-flex align-items-center">
        <p className={"stat-card-label"}>
          {APP_LABELS[label]}{" "}
          {specialEffect ? `(${specialEffect} ${specialEffectClan})` : null}
        </p>
      </span>
      <p className={"stat-card-value"}>{value}</p>
    </div>
  );
};

const StatCard = ({
  label,
  value,
  isPowerupStatCard,
  specialEffectClan,
  specialEffect,
}) => {
  const onLabelStyle = () => {
    if (!isPowerupStatCard) {
      return label === BLOCKBOT_BASE_ATTRIBUTE.HEALTH_POINTS
        ? { backgroundColor: "#DBFFE5" }
        : label === BLOCKBOT_BASE_ATTRIBUTE.STRENGTH
        ? { backgroundColor: "#FFCFC5" }
        : label === BLOCKBOT_BASE_ATTRIBUTE.AGILITY
        ? { backgroundColor: "#FFF3C1" }
        : { backgroundColor: "#BDEEFF" };
    }
    return { backgroundColor: "inherit" };
  };
  return (
    <div
      className={classNames(styles.statCardContainer)}
      style={onLabelStyle()}
    >
      <span className="d-flex align-items-center">
        {!isPowerupStatCard && (
          <span className={styles.statCardIconPlaceholder}>
            <img src={IMAGE_MAP_BLOCKBOT_BASE_ATTRIBUTE[label]} alt="" />
          </span>
        )}
        <p className={styles.statCardLabel}>
          {APP_LABELS[label]}{" "}
          {specialEffect ? `(${specialEffect} ${specialEffectClan})` : null}
        </p>
      </span>
      <p className={styles.statCardValue}>{value}</p>
    </div>
  );
};

export default StatCard;

export const StatCardSmall = ({
  label,
  value,
  isPowerupStatCard,
  tooltipId,
}) => {
  const onLabelStyle = () => {
    if (!isPowerupStatCard) {
      return label === BLOCKBOT_BASE_ATTRIBUTE.HEALTH_POINTS
        ? { backgroundColor: "#DBFFE5", color: "#44B865" }
        : label === BLOCKBOT_BASE_ATTRIBUTE.STRENGTH
        ? { backgroundColor: "#FFCFC5", color: "#9a1e04" }
        : label === BLOCKBOT_BASE_ATTRIBUTE.AGILITY
        ? { backgroundColor: "#FFF3C1", color: "#856e0e" }
        : { backgroundColor: "#BDEEFF", color: "#095975" };
    }
    return { backgroundColor: "#BDEEFF" };
  };
  const tooltipIdentifier = `id-${tooltipId}-${BLOCKBOT_BASE_ATTRIBUTE_SHORT[label]}`;

  const tooltipContent = useMemo(() => {
    switch (label) {
      case "STRENGTH":
        return "Strength";
      case "AGILITY":
        return "Agility";
      case "DEFENSE":
        return "Defence";
      case "HEALTH POINTS":
        return "Health";
      default:
        return "";
    }
  }, [label]);

  return (
    <div
      className={classNames(styles.statCardContainerSm)}
      style={onLabelStyle()}
      id={tooltipIdentifier}
    >
      <UncontrolledTooltip target={tooltipIdentifier}>
        {tooltipContent}
      </UncontrolledTooltip>
      {!isPowerupStatCard && (
        <div className={styles.statCardIconPlaceholderSm}>
          <img src={IMAGE_MAP_BLOCKBOT_BASE_ATTRIBUTE[label]} alt="" />
        </div>
      )}
      <p className={styles.statCardValueSm}>{value}</p>
    </div>
  );
};
