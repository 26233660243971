import React, { useState } from "react";
import classNames from "classnames";
import { useSpenderWalletContract } from "../../hooks/useSpenderWalletContract";
import styles from "./Wallet.module.scss";
import downArrow from "../../images/icons/down-arrow.svg";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Stepper from "../../components/Stepper/Stepper";
import WithdrawPowerups from "../../components/Wallet/WithdrawPowerups";
import { toast } from "react-toastify";
import { FaTimes } from "react-icons/fa";

const WithdrawModal = ({
  modalVisible,
  toggle,
  powerups,
  modalValues,
  setFetchUpdatedBalance,
}) => {
  const [withdrawType, setWithdrawType] = useState("IND");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const { withdrawWalletTokens } = useSpenderWalletContract();
  const [selectedPowerups, setSelectedPowerups] = useState(null);
  const { withdrawPowerups } = useSpenderWalletContract();

  const [stepValues, setStepValues] = useState([
    { name: "Withdraw", isLoading: false, done: false },
  ]);
  const [showStepper, setShowStepper] = useState(false);

  const withdrawToken = async () => {
    try {
      setStepValues([{ name: "Withdraw", isLoading: true, done: false }]);
      if (parseFloat(withdrawAmount) > parseFloat(modalValues[0])) {
        throw new Error(
          "Please withdraw an amount less than or equal to the IND you own in your game wallet."
        );
      }
      await withdrawWalletTokens(withdrawAmount, withdrawType);
      setStepValues([{ name: "Withdraw", isLoading: false, done: true }]);
      setDropdownOpen(false);
      setShowStepper(false);
      // setWithdrawType(null);
      setWithdrawAmount("");
      toggle();
      setFetchUpdatedBalance(true);
      toast.success(`${withdrawType} Tokens withdrawn successfully!`);
      setStepValues([{ name: "Withdraw", isLoading: false, done: false }]);
    } catch (err) {
      console.log(err);
      toast.error(err.message || "Error while withdrawing tokens", {
        autoClose: 1900,
        position: "top-right",
        hideProgressBar: true,
      });

      setStepValues([{ name: "Withdraw", isLoading: false, done: false }]);
      setWithdrawAmount("");
      toggle();
      // setWithdrawType(null);
      setShowStepper(false);
    }
  };

  const powerupsWithdrawHandler = async (powerups) => {
    setShowStepper(true);
    try {
      setStepValues([{ name: "Withdraw", isLoading: true, done: false }]);

      const nonZeroSelectedPowerups = powerups.filter(
        (powerup) => powerup.quantity > 0
      );
      const selectedTokenIds = nonZeroSelectedPowerups.map(
        (powerup) => powerup.id
      );
      const selectedQuantities = nonZeroSelectedPowerups.map(
        (powerup) => powerup.quantity
      );
      await withdrawPowerups(selectedTokenIds, selectedQuantities);
      setSelectedPowerups(null);
      setDropdownOpen(false);
      // setWithdrawType(null);
      setShowStepper(false);
      setStepValues([{ name: "Withdraw", isLoading: false, done: true }]);
      toggle();
      setFetchUpdatedBalance(true);
      toast.success("Powerups withdrawn successfully!");
      setStepValues([{ name: "Withdraw", isLoading: false, done: false }]);
    } catch (err) {
      console.log(err);
      toast.error(err.message || "Error while withdrawing powerups...", {
        autoClose: 1900,
        position: "top-right",
        hideProgressBar: true,
      });
      setSelectedPowerups(null);
      setStepValues([{ name: "Withdraw", isLoading: false, done: false }]);
      setDropdownOpen(false);
      // setWithdrawType(null);
      setShowStepper(false);
      toggle();
    }
  };
  return (
    <Modal
      isOpen={modalVisible}
      // toggle={toggle}
      className={classNames(styles.ModalContainer)}
      centered
      size="lg"
    >
      {!showStepper && (
        <div
          className={classNames(styles.closeButtonContainer)}
          onClick={() => toggle()}
        >
          <FaTimes />
        </div>
      )}
      <ModalHeader className={classNames("d-flex justify-content-center")}>
        <p className={classNames(styles.depositModalHeaderText)}>
          Withdraw from Game Wallet
        </p>
      </ModalHeader>
      {showStepper && (
        <div className={classNames(styles.StepperModal)}>
          <Stepper stepValues={stepValues} />
        </div>
      )}
      <ModalBody
        className={classNames(styles.ModalBody)}
        style={{
          display: showStepper ? "none" : "block",
        }}
      >
        <p className={classNames(styles.chooseDescriptionText)}>
          Choose what you want to withdraw
        </p>
        <Dropdown
          isOpen={dropdownOpen}
          toggle={() => setDropdownOpen(!dropdownOpen)}
          className={classNames(styles.depositDropdown)}
        >
          <DropdownToggle className={classNames(styles.depositDropdownToggle)}>
            <p className={classNames(styles.dropdownSelectorText)}>
              {withdrawType === "IND"
                ? "IND Tokens"
                : withdrawType === "USDT"
                ? "USDT Tokens"
                : withdrawType === "USDC"
                ? "USDC Tokens"
                : withdrawType === "DAI"
                ? "DAI Tokens"
                : withdrawType === "POWERUPS"
                ? "Powerups"
                : "Choose Deposit Type"}
            </p>
            <img src={downArrow} alt="down-arrow" />
          </DropdownToggle>
          <DropdownMenu className={classNames(styles.dropDownMenu)}>
            <DropdownItem
              onClick={() => setWithdrawType("IND")}
              disabled={!parseFloat(modalValues[0].IND)}
            >
              IND Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setWithdrawType("USDT")}
              disabled={!parseFloat(modalValues[0].USDT)}
              className={classNames(styles.DropdownItem)}
            >
              USDT Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setWithdrawType("USDC")}
              disabled={!parseFloat(modalValues[0].USDC)}
              className={classNames(styles.DropdownItem)}
            >
              USDC Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setWithdrawType("DAI")}
              disabled={!parseFloat(modalValues[0].DAI)}
              className={classNames(styles.DropdownItem)}
            >
              DAI Tokens
            </DropdownItem>
            <DropdownItem
              onClick={() => setWithdrawType("POWERUPS")}
              disabled={!(modalValues[1] && modalValues[1].length)}
            >
              Powerups
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        {(withdrawType === "IND" || "USDT" || "USDC" || "DAI") && (
          <div className={classNames(styles.inputContainer)}>
            <input
              className={classNames(styles.inputStyles)}
              type="text"
              placeholder="Amount"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
            />
            <button
              className={classNames(styles.customButton)}
              onClick={() => {
                setShowStepper(true);
                withdrawToken();
              }}
            >
              Withdraw
            </button>
          </div>
        )}
        {withdrawType === "POWERUPS" && powerups && (
          <WithdrawPowerups
            powerups={powerups}
            selectedPowerups={selectedPowerups}
            setSelectedPowerups={setSelectedPowerups}
            powerupsWithdrawHandler={powerupsWithdrawHandler}
          />
        )}
      </ModalBody>
    </Modal>
  );
};
export default WithdrawModal;
