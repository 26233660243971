import { InfuraProvider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { useMemo, useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import { addresses, INFURA_ETH_PROJECT_KEY, INFURA_CHAIN } from "../constant";
import { blockBots as ABI } from "../abi/blockBots";

export const useBlockbots = () => {
  const { account } = useWeb3React();

  const contract = useMemo(() => {
    const provider = new InfuraProvider(INFURA_CHAIN, INFURA_ETH_PROJECT_KEY);
    if (!account || !ABI || !provider) {
      return null;
    }

    try {
      const contract = new Contract(addresses.BlockBots, ABI.abi, provider);
      return new Contract(addresses.BlockBots, ABI.abi, provider);
    } catch (err) {
      console.error("Failed To Get Contract", err);

      return null;
    }
  }, [account]);

  const getBalance = useCallback(async () => {
    const trx = await contract.balanceOf(account);
    return trx.toString();
  }, [account, contract]);

  return useMemo(() => {
    return {
      getBalance,
    };
  }, [getBalance]);
};
