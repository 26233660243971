import { Modal, ModalBody, Button } from "reactstrap";
import classnames from "classnames";
import { FIGHT_TYPES } from "../../../constant";
export const ConfirmationModal = ({
  isOpen,
  onCancel,
  onConfirm,
  inProgress,
  fightType,
}) => {
  const CONFIRM_MESSAGE =
    fightType === FIGHT_TYPES.ARENA
      ? "You are entering an Arena Game with game wallet Powerups. The winner will earn a random Powerup that was equipped by the losing player. Lock in your actions and enter the waiting room?"
      : "Lock in your actions and enter the waiting room?";

  return (
    <Modal className="confirm-modal-wrapper" centered isOpen={isOpen}>
      <ModalBody>
        <div className="confirm-modal-image">
          <img src="/images/modal-img.png" alt="" />
        </div>
        <div className="confirm-modal-title">Lock in your actions</div>
        <div className="confirm-modal-desc">
          {CONFIRM_MESSAGE}
          <br />
        </div>
        <div className="confirm-modal-actions">
          <Button
            onClick={onCancel}
            className={classnames("primary-blue-btn", "transparent-btn")}
          >
            Cancel
          </Button>
          <Button
            disabled={inProgress}
            onClick={onConfirm}
            className="primary-blue-btn"
          >
            {inProgress ? "Please Wait" : "Confirm"}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};
